import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {MultiSelect} from 'primereact/multiselect';
import './CustomMyActionsFilter.css';
import {Button} from "primereact/button";

class CustomMyActionsFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedStatuses: [],
            disableSaveButton: true,
            oldStatus: [],
            optionsObjectArr: []
        };

        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    componentDidMount() {
        this.setState({selectedStatuses: this.props.selectedFilterList,
                             oldStatus: this.props.selectedFilterList,
                             optionsObjectArr: this.convertToObjArr(this.props.statusList)});
    }

    convertToObjArr(statusArray) {
        return Array.from(statusArray, status => {
            let statusObject = {};
            statusObject.name = status;
            statusObject.value = status;
            return statusObject;
        })
    }

    handleSaveClick() {
        if(this.state.selectedStatuses.length === 0) {
            this.setState({ selectedStatuses: this.getDefaultSelectedStatus() }, () => {
                this.props.onUpdateFilters(this.state.selectedStatuses);
            });
        }
        this.props.onUpdateFilters(this.state.selectedStatuses);
    }

    handleOptionChange(option) {
        this.setState({ selectedStatuses: option.value });
        let difference = this.state.oldStatus
            .filter(x => !option.value.includes(x))
            .concat(option.value.filter(x => !this.state.oldStatus.includes(x)));

        if(difference.length === 0) {
            this.setState({ disableSaveButton: true});
        } else {
            this.setState({ disableSaveButton: false});
        }
    }

    getDefaultSelectedStatus() {
        let defaultSelectedStatus = [...this.props.statusList];
        delete defaultSelectedStatus[0];
        return defaultSelectedStatus;
    }


    render() {
        let {selectedStatuses, disableSaveButton, optionsObjectArr} = this.state;
        return (
            <Dialog header={"Create Custom Filter for My Actions"}
                    visible={this.props.visible}
                    focusOnShow={false}
                    contentStyle={{overflow: 'visible'}}
                    style={{width: '420px'}} modal={true}
                    onHide={this.props.onHide}>
                <div className="multiselect-demo" style={{marginTop: "10px"}}>
                    <MultiSelect value={selectedStatuses}
                                 options={optionsObjectArr}
                                 optionLabel="name"
                                 //optionValue="value"
                                 //display="chip"
                                 onChange={this.handleOptionChange}
                                 maxSelectedLabels={1}
                                 selectedItemsLabel="{0} Filters selected"
                                 placeholder="Choose Filters"
                                 filter
                                 filterPlaceholder="Search"
                                 className="multiselect-custom"
                                 scrollHeight={'250px'}
                                 style={{width: '350px'}}/>
                </div>
                <div style={{textAlign: 'right', marginTop: '10px'}} className={'col-12'}>
                    <Button icon={'fa fa-save'}
                            className={'p-button-success'}
                            label={'SAVE'}
                            disabled={disableSaveButton}
                            onClick={this.handleSaveClick}/>
                    <Button icon={'fa fa-times'}
                            style={{marginLeft: '10px'}}
                            className={'p-button-secondary'}
                            label={'CANCEL'}
                            onClick={this.props.onHide}/>
                </div>
            </Dialog>
        );
    }
}

export default CustomMyActionsFilter;

import {
    formatNumber,
    serverDateToBrowserFormat,
    serverDateToBrowserShortFormat,
    serverTimestampToBrowserFormat, serverTimestampToBrowserShortFormat
} from "@essdocs/ngfashared/shared/FormatUtils";
import {defaultWorkflow, defaultFilters} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import WorkflowService from "@essdocs/ngfashared/workflow/WorkflowService";
import {getTradeStringEntryFromUuid, getTradeStringIndexFromUuid} from "@essdocs/ngfashared/shared/BargeUtil";

const wf = defaultWorkflow;
const wfService = new WorkflowService(defaultWorkflow);

// const hasRole = (user, roles) => {
//    if (!user.roles || user.roles.length === 0 ) return false;
//
//    for (let idx = 0; idx < roles.length; idx++) {
//        const role = roles[idx];
//        if (user.roles.indexOf(role) >= 0) return true;
//    }
//
//    return false;
// };

const getAppliedFromDetails = (user, tradeString, tradeStringUuid) => {
    const result = {};

    if (tradeString === null) return result;
    const tradeStringIdx = getTradeStringIndexFromUuid(tradeString, tradeStringUuid);

    const ts = tradeString[tradeStringIdx];

    if (tradeStringIdx > 0) {
        result.applyDate = serverTimestampToBrowserFormat(ts.applyDate);
        result.applyDateShort = serverDateToBrowserShortFormat(ts.applyDate);
        result.appliedFrom = tradeString[tradeStringIdx - 1].ownerName;
    }
    if (tradeStringIdx < tradeString.length - 1) {
        result.appliedTo = tradeString[tradeStringIdx + 1].ownerName;
    }
    return result;
};

const getCertificatesUploaded = (user, bargeRecord, reconsignmentNbr) => {
    let result = '';

    if(!bargeRecord.archived && bargeRecord.documents && bargeRecord.documents.length > 0){
        const gradeCerts = bargeRecord.documents.filter(dt => dt.docType === 'grade');
        const weightCerts = bargeRecord.documents.filter(dt => dt.docType === 'weight');

        //if(gradeCerts.find(cert => cert.reconsignment === reconsignmentNbr)) {
        if(gradeCerts.length > 0) {
            result = 'G';
        }

        if(weightCerts.length > 0) {
            if(result.length > 0) {
                result += ' & W';
            } else {
                result += 'W';
            }
        }
    }
    return result;
};

export const createDisplayer = (user, r, actionHandler, tradeStringUuid, searchOptions, disabled) => {

    if (!searchOptions) searchOptions = {};
    const ts = getTradeStringEntryFromUuid(r.tradeString, tradeStringUuid);
    const isCarrier = user.type === 'CARRIER';
    //let statusCode= ts === undefined || isCarrier ? r.statusCode : ts.statusCode;
    let statusCode= ts.statusCode;
    const state = wf[statusCode];
    let status = statusCode;
    let actions = [];
    if (state) {
        status = state.statusDesc;
        if (state.actions) {
            state.actions.forEach(action => {
                if (wfService.canAction(user, r, action.actionId, tradeStringUuid)) {
                    if(action.actionId === 'removeBarge') {
                        actions.push({
                            id: action.actionId,
                            icon: 'pi pi-trash',
                            allowMultiSelect: true,
                            disabled: disabled,
                            className: action.className,
                            handler: actionHandler
                        });
                    } else if(action.actionId === 'editBarge' || action.actionId === 'carrierEditBarge') {
                        actions.push({
                            id: action.actionId,
                            icon: 'fa fa-pencil',
                            allowMultiSelect: false,
                            disabled: disabled,
                            className: 'p-button-secondary',
                            handler: actionHandler
                        });
                    } else {
                        actions.push({
                            id: action.actionId,
                            label: action.label.toUpperCase(),
                            allowMultiSelect: action.allowMultiSelect === undefined || action.allowMultiSelect === true,
                            icon: action.icon ? 'fa ' + action.icon : '',
                            className: action.className,
                            disabled: disabled,
                            handler: actionHandler
                        });
                    }
                }
            });
        }

        if (actions.length === 0 && searchOptions.myActionsOnly) return null;
        const canAttachDocs = r.archived === undefined || r.archived === false;
        if (user.roles && user.roles.length > 0 && canAttachDocs) {
            actions.push({
                id: 'upload',
                label: 'UPLOAD',
                icon: 'fa fa-upload',
                allowMultiSelect: false,
                disabled: disabled,
                className: 'p-button-success',
                handler: actionHandler
            });
         }
        /*if (r.statusCode === 'CREATED' && user.company === r.tradeString[0].owner && user.roles && user.roles.length > 0) {
            actions.push({
                id: 'EDITBARGE',
                icon: 'fa fa-pencil',
                allowMultiSelect: false,
                disabled: disabled,
                className: 'p-button-secondary',
                handler: actionHandler
            });
        }*/

        actions.push({
            id: 'BARGEINFO',
            icon: 'fa fa-info-circle',
            allowMultiSelect: false,
            disabled: disabled,
            className: 'p-button-secondary',
            handler: actionHandler
        });

        let removeBargeIndex = actions.findIndex( action => action.id === 'removeBarge');
        actions.push(actions.splice(removeBargeIndex, 1)[0]);
    }

    const af = getAppliedFromDetails(user, r.tradeString, tradeStringUuid);

    const quantity = r.quantity && r.quantity !== 0 ? formatNumber(r.quantity, 3) : '';
    const quantityUnit = r.quantityUnit ? r.quantityUnit : '';
    let quantityUnitAndName = quantity !== '' ? quantity + ' ' + quantityUnit : quantityUnit;
    let certificateUploaded = getCertificatesUploaded(user, r, ts.reconsignment);
    let reconTimestamp = r.reconsignments[ts.reconsignment].lastUpdated;

    return {
        bargeId: r.bargeTransferred ? r.bargeId + ' (T) '+r.prevBargeId : r.bargeId,
        status: status,
        tradeStringUuid: tradeStringUuid,
        carrierName: r.carrierName,
        shipperName: r.shipperName,
        consignedTo: r.consignedToName ? r.consignedToName : '',
        appliedFrom: isCarrier ? '' : af.appliedFrom ? af.appliedFrom : '',
        appliedTo: isCarrier ? '' : af.appliedTo ? af.appliedTo : '',
        applyDateShort: isCarrier ? '' : af.applyDateShort,
        applyDate: isCarrier ? '' : af.applyDate ? new Date(af.applyDate) : '',
        origin: r.origin,
        commodityName: r.commodity + ' ' + r.commodityName,
        quantity: quantity,
        quantityUnit: quantityUnit,
        quantityUnitAbbr: r.quantityUnitAbbr,
        quantityAndUnit: quantityUnitAndName,
        blDate: serverDateToBrowserFormat(r.blDate),
        blDateShort: serverDateToBrowserShortFormat(r.blDate),
        bolDate: new Date(r.blDate),
        destination: r.reconsignments[ts.reconsignment].destination,
        specialCircumstance: r.specialCircumstance,
        lastUpdated: serverTimestampToBrowserFormat(r.lastUpdated),
        lastUpdatedShort: serverTimestampToBrowserShortFormat(r.lastUpdated),
        lastUpdatedDate: new Date(r.lastUpdated),
        reconLastUpdated: r.reconsignments.length > 1 ? serverTimestampToBrowserFormat(reconTimestamp ? reconTimestamp : r.lastUpdated) : '',
        reconLastUpdatedShort: r.reconsignments.length > 1 ? serverTimestampToBrowserShortFormat(reconTimestamp ? reconTimestamp : r.lastUpdated) : '',
        reconLastUpdatedDate: r.reconsignments.length > 1 ? new Date(reconTimestamp ? reconTimestamp : r.lastUpdated) : '',
        actions: actions,
        uploads: certificateUploaded,
        fee: ts.privateData && ts.privateData.fee ? ts.privateData.fee.value : null,
        tradeString: r.tradeString.filter(t => t.reconsignment === ts.reconsignment).map(t =>  {
            return {
                ...t,
                applyDate: serverTimestampToBrowserFormat(t.applyDate)
            }
        }),
        record: r
    }
};


export const createBargeDisplayers = (user, data, actionHandler, statusFilter, searchOptions, disabled) => {
    if (!data) return [];
    let bustedByShipperStatusList = ['BUSTED', 'BUSTEDRETURNBOL'];
    if (searchOptions && searchOptions.userPreferences && statusFilter) {
        let statusDescs = [...statusFilter];
        statusFilter = [];
        statusDescs.forEach(statusObj => {
            if(["Internal Shipment", "Busted By Shipper"].indexOf(statusObj) < 0) {
                statusFilter.push(...getKeyByValue(statusObj));
            } else if(statusObj === 'Internal Shipment') {
                searchOptions.internalShipmentOnly = true;
            } else if(statusObj === 'Busted By Shipper') {
                searchOptions.bustedByShipper = true;
            }
        });
    }

    const displayers = [];
    const isCarrier = user.type === 'CARRIER';
    data.forEach(r => {
        if (r.tradeString === undefined) r.tradeString = [];
        //The carrier should only see the first record in each reconsignment if there are no filters
        //otherwise one trade string record per status code that matches the filter
        if (isCarrier) {
            for (let idx = 0; idx < r.reconsignments.length; idx++) {
                const matchedStatusCodes = [];
                r.tradeString.forEach(ts => {
                    if (ts.reconsignment === idx) {
                        if (matchedStatusCodes.includes(ts.statusCode)) return;
                        matchedStatusCodes.push(ts.statusCode);
                        if (statusFilter && statusFilter.length > 0 && !statusFilter.includes(ts.statusCode)) return;
                        const disp = createDisplayer(user, r, actionHandler, ts.uuid, searchOptions, disabled);
                        if (disp) displayers.push(disp);
                    }
                });
            }
        } else {
            for (let idx = 0; idx < r.tradeString.length; idx++) {
                const ts = r.tradeString[idx];
                if (ts.owner === user.company) {
                    if(searchOptions && searchOptions.billedBarges) {
                        //User is endreceiver and proposed destination
                        // A -> B (proposed)
                        // B -> C -> B (proposed) (C -> B only displayed)
                        if(r.reconsignments[ts.reconsignment].destinationProposedUuid !== ts.uuid ||
                            !r.reconsignments[ts.reconsignment].destinationProposer ||
                            ts.owner !== r.reconsignments[ts.reconsignment].destinationProposer) continue;
                    }
                    if (searchOptions && searchOptions.userPreferences) {
                        if(r.tradeString.length === 1) {
                            let internalShipFilter = defaultFilters.find(inboxFilter => inboxFilter.title === 'Internal Shipment');
                            if (internalShipFilter.filter.indexOf(ts.statusCode) >= 0 && bustedByShipperStatusList.indexOf(r.statusCode) < 0) {
                                if (!searchOptions.internalShipmentOnly) continue;
                            } else if (bustedByShipperStatusList.indexOf(ts.statusCode) >= 0) {
                                if (!searchOptions.bustedByShipper) continue;
                            } else if (statusFilter && statusFilter.length > 0 && statusFilter.indexOf(ts.statusCode) < 0) {
                                continue;
                            }
                        } else if(r.tradeString.length > 1) {
                            if (statusFilter && statusFilter.length === 0 &&
                                (searchOptions.bustedByShipper || searchOptions.internalShipmentOnly)) continue;
                            if (statusFilter && statusFilter.length > 0 && statusFilter.indexOf(ts.statusCode) < 0) {
                                continue;
                            }
                        }
                    } else if (statusFilter && statusFilter.length > 0 && statusFilter.indexOf(ts.statusCode) < 0) continue;
                    const disp = createDisplayer(user, r, actionHandler, ts.uuid, searchOptions, disabled);
                    if (disp) displayers.push(disp);
                }
            }
        }
    });

    return displayers;
};

const getKeyByValue = (value) => {
    return Object.keys(defaultWorkflow).filter(key => key !== 'DESTACCEPT' && defaultWorkflow[key].statusDesc === value);
};

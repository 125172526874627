import React from 'react';
import {EssDocsComponent, EssDocsPage} from "@essdocs/commonui";
import {FileUpload} from "primereact/fileupload";
import './AdminDataLoad.css';
import { Button } from 'primereact/button';
import {API} from "aws-amplify";
import ServiceFactory from "../../services/ServiceFactory";
import LogDisplayer from "./LogDisplayer";
import Papa from 'papaparse';


class OnboardUsers extends EssDocsComponent {


    constructor(props) {
        super(props);
        this.state = {
            data: null,
            messages: [],
            validated: false,
            shippers: [],
            carriers: [],
            uploading: false,
            dataUrl: null,
            filename: ''
        }

        this.handleUpload = this.handleUpload.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelectFile = this.handleSelectFile.bind(this);
    }

    addMessage(type, msg) {
        this.setState({messages: [...this.state.messages, {type: type, msg: msg}]});
    }

    addMessages(msgs) {
        this.setState({messages: [...this.state.messages, ...msgs]});
    }


    componentDidMount() {
        this.loadRefData();
    }

    async loadRefData() {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        const shippers = await refDataService.readAll('shippers');
        const carriers = await refDataService.readAll('carriers');
        this.setState({shippers: shippers, carriers: carriers});
    }

    getShipperIdFromName(shipperName) {
        const shipper = this.state.shippers.find(s => s.shipperName.toUpperCase() === shipperName.toUpperCase());
        return shipper ? shipper.id : null;
    }

    getCarrierIdFromName(carrierName) {
        const carrier = this.state.carriers.find(s => s.carrierName.toUpperCase() === carrierName.toUpperCase());
        return carrier ? carrier.id : null;
    }

    async handleUpload() {
        const path = `/users/onboard`;

        let myInit = {
            body: this.state.data,
            headers: {}
        };

        try {
            this.setState({uploading: true});
            this.addMessage('I', 'Uploading Data. Please wait....');
            const result = await API.post('ngfausers', path, myInit);
            if (result.isErrorOccured) {
                this.addMessages([
                    {type: 'E', msg: result.errorMessage},
                    {type: 'E', msg: 'All users up until this one have been imported successfully. ' +
                            'Please edit and update the input file and try again'}
                ]);
            } else {
                this.addMessage('I', 'Import of users successful');
            }
            this.generateSqlDownload(result.data)

        } catch (e) {
            console.error('failed to upload data');
            console.error(e);
            this.addMessage('E', 'Error importing data');
        }
        this.setState({uploading: false});
    }


    generateSqlDownload(data) {
        console.log('generating data');

        let sql = '';
        data.forEach( u => {
            sql +=  `insert into employee (employee_identifier,company_identifier, employee_login_id,employee_password,` +
                    `full_name, first_name,family_name,locked_indicator,logins_remaining,active_indicator, uuid,` +
                    `AllowSmsSetUp, AllowGoogleAuthSetUp,EntrustEnabled) values (` +
                    `1234, 90022, '${u.username}','','${u.fullname}','${u.firstName}','${u.lastName}',false, 1, true, 'UUID1',` +
                    `false, false,false);\n`;
        });

        this.setState({dataUrl: sql});
    }

    clearMessages() {
        this.setState({messages: []});
    }

    handleSelectFile(event) {
        const reader = new FileReader();
        const $this = this;
        $this.fileUpload.clear();
       //$this.fileUpload.state.files = [];
        $this.clearMessages()
        reader.onload = async function(){
            const result = reader.result;
            try {
                const data = await $this.validateAndParse(result);
                let validated = false;
                if ($this.state.messages.filter(e => e.type === 'E').length === 0) {
                    $this.addMessage('I', `Validated ${data.length} users. ` +
                        `Click ONBOARD to import the users and send them an onboarding email to login`);
                    validated = true;
                } else {
                    $this.addMessage('E', `You must correct the errors before you can import the users`);
                }
                $this.setState({data: data, validated: validated});
            } catch(e) {
                console.error(e);
                $this.addMessage('E', 'Failed to load file. Make sure you have selected a valid CSV file.');
            }
        };
        this.setState({filename: event.files[0]});
        reader.readAsText(event.files[0]);
    }


    validateEmail(email)
    {
        if (!email || email.length < 3) return false;
        return email.includes('@') && email.includes('.');
    }

    async userExists(username) {
        const userService = ServiceFactory.instance().createUserService();
        try {
            const result = await userService.getUser(username, false);
            return result.username === username;
        } catch(e) {
        }
        return false;
    }

    async validateAndParse(file) {
        let data = [];
        Papa.parse(file, {
            header: false,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: (result) => {
                const records = result.data;
                data = this.parseUsers(records);
            },
            error: (error) => {
                console.error('Error parsing CSV:', error);
            },
        });
        return data;
    }

   async parseUsers(records) {
        const users = [];
        this.addMessage('I', 'Validating users. Please wait....');
        let rowIdx = 0;
        for (let idx = 0; idx < records.length; idx++) {
            const r = records[idx];
            rowIdx++;
            if (rowIdx > 1) {
                if (r[0] && r[2]) {
                    const user = {};
                    user.firstName = r[0].trim();
                    user.lastName = r[1].trim();
                    user.fullName = user.firstName + ' ' + user.lastName;
                    if (!user.firstName && !user.lastName && !user.username) break;
                    user.username = r[2].trim();
                    user.companyName = r[3].trim();
                    user.type = r[4].trim().toUpperCase();
                    if (!this.validateEmail(user.username)) {
                        this.addMessage('E', `Row#${rowIdx}: Invalid email ${user.username}`);
                        continue;
                    }

                    if (!user.firstName) {
                        this.addMessage('E', `Row#${rowIdx}: Missing First Name for user ${user.username}`);
                        continue;
                    }


                    if (!user.lastName) {
                        this.addMessage('E', `Row#${rowIdx}: Missing Last Name for user ${user.username}`);
                        continue;
                    }

                    if (user.type !== 'SHIPPER' && user.type !== 'CARRIER') {
                        this.addMessage('E', `Row#${rowIdx}: Invalid user type ${user.type} for user ${user.username}`);
                        continue;
                    }
                    user.company = user.type === 'SHIPPER' ? this.getShipperIdFromName(user.companyName) :
                        this.getCarrierIdFromName(user.companyName);
                    if (!user.company) {
                        this.addMessage('E', `Row#${rowIdx}: Company Name not found: ${user.companyName}`);
                        continue;
                    }

                    const userExists = await this.userExists(user.username);
                    if (userExists) {
                        this.addMessage('E', `Row#${rowIdx}: User already exists: ${user.username}`);
                        continue;
                    }

                    user.roles = [];
                    if (r[5].toUpperCase().trim() === 'YES') user.roles.push('SHIPPER');
                    if (r[6].toUpperCase().trim() === 'YES') user.roles.push('UNLOADELEVATOR');
                    if (r[7].toUpperCase().trim() === 'YES') user.roles.push('TRADEADMIN');
                    if (r[8].toUpperCase().trim() === 'YES') user.roles.push('TRADER');
                    if (r[9].toUpperCase().trim() === 'YES') user.roles.push('ECARRIER');
                    if (r[10].toUpperCase().trim() === 'YES') user.roles.push('CARRIER');
                    if (r[11] && r[11].toUpperCase().trim() === 'ESSDOCSADMIN') user.roles.push('ESSDOCSADMIN');
                    if (r[11] && r[11].toUpperCase().trim() === 'APIUSER') user.roles.push('APIUSER');

                    user.confirmed = false;
                    users.push(user);
                }
            }
        }
        return users;
    }

    render() {
        return (
            <EssDocsPage title={'Onboard Users'}>
                <div>
                    <FileUpload chooseLabel={'SELECT CSV FILE'}
                                accept={'csv'}
                                ref={el => this.fileUpload=el}
                                mode={'basic'}
                                auto={true}
                                customUpload={true}
                                uploadHandler={this.handleSelectFile} />
                    {/*<span>{this.state.filename}</span>*/}
                </div>
                <div>
                    <Button disabled={!this.state.validated || this.state.uploading} label={'ONBOARD'} onClick={this.handleUpload} style={{marginTop: '15px'}}/>
                </div>
                {/*<div style={{marginTop: '10px'}}>*/}
                {/*    {dataUrl  && <a href={'data:,' + this.state.dataUrl} download={'NgfaUserExport.sql'}>Export Imported Users as SQL</a>}*/}
                {/*</div>*/}
                <LogDisplayer messages={this.state.messages}/>
            </EssDocsPage>
        );
    }
}

export default OnboardUsers;

import React, {Component} from 'react';
import './DocumentList.css';
import {DocumentRow} from "./DocumentRow";

class DocumentList extends Component {
    render() {
        return (
            <div className={'DocumentList ' + this.props.className} >
                {this.props.docs.map((d, idx) => <DocumentRow key={idx}
                                                              doc={d}
                                                              onSelectDoc={this.props.onSelectDoc}
                                                              onDeleteDoc={this.props.onDeleteDoc}
                                                              selected={d.id === this.props.selected.id}/>)}
            </div>
        );
    }
}

export default DocumentList;

import React from 'react';
import {Navigator, EssDocsComponent} from "@essdocs/commonui";
import Breakjs from "breakjs";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import AppRoutes from "./AppRoutes";
import {store} from "./redux/Store";
import {
    collapseSideBar,
    searching,
    setSearchFilter,
    setSearchResults,
    updateBackToInbox,
    userChangeOfCompany
} from "./redux/Actions";
import ServiceFactory from "./services/ServiceFactory";
import {defaultCarrierFilters, defaultFilters, defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import {ReferenceDataType} from "./services/ReferenceDataType";
import {resetTimeout} from "./shared/TimeoutUtil";
import CustomMyActionsFilter from "../src/components/CustomMyActionsFilter/CustomMyActionsFilter";

class AppMain extends EssDocsComponent {

    static defaultProps = {
        navWidth: '250px'
    };

    constructor(props) {
        super(props);
        this.layout = Breakjs({
            mobile: 0,
            phablet: 550,
            tablet: 768,
            desktop: 992
        });
        this.state = {
            navCollapsed: false,
            mobile: this.layout.is('mobile'),
            menuModel: [],
            customFilterDialogVisible: false,
            allStatusList: this.getAllStatuses(),
            selectedStatusFilters: this.getSelectedStatus(),
            companyMenuModel: [],
            selectedCompany: {}
        };

        this.handleNavItemClick = this.handleNavItemClick.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
        this.handleBreakPointChange = this.handleBreakPointChange.bind(this);
        this.handleSectionClick = this.handleSectionClick.bind(this);
        this.handleUpdateFilters = this.handleUpdateFilters.bind(this);
        this.handleUpdateFilterClick = this.handleUpdateFilterClick.bind(this);
        this.updateFilter =this.updateFilter.bind(this);
        this.handleChangeCompany =this.handleChangeCompany.bind(this);
    }

    async preloadReferenceData() {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        await refDataService.readAll(ReferenceDataType.OriginLocations);
        await refDataService.readAll(ReferenceDataType.Commodities);
        await refDataService.readAll(ReferenceDataType.Shippers);
        await refDataService.readAll(ReferenceDataType.Carriers);
    }

    async componentDidMount() {
        this.preloadReferenceData();
        const menuModel = this.createMenuModel(this.props.user);
        const companyMenuModel = this.props.user.companyList || [{id : this.props.user.company, label: this.props.user.companyName}];
        if (menuModel) {
            const myActionsFilter = menuModel[0].items[1];
            this.setState({menuModel: menuModel, allStatusList: this.getAllStatuses(),
                companyMenuModel: companyMenuModel, selectedCompany: {id : this.props.user.company, label: this.props.user.companyName}});
            this.selectFilter(this.props.user.type !== 'CARRIER' ? this.state.selectedStatusFilters : myActionsFilter.filter,
                myActionsFilter.options, myActionsFilter.documentTypes);

            await this.loadInboxData(this.props.user, this.props.user.type !== 'CARRIER' ? this.state.selectedStatusFilters : myActionsFilter.filter,
                '', myActionsFilter.options, myActionsFilter.documentTypes);
            this.layout.addChangeListener(this.handleBreakPointChange);
            this.nav('/inbox');
        }
    }


    async loadInboxData(user, statusArray, searchArg, options, documentTypes) {
        if (user) {
            const inboxService = ServiceFactory.instance().createInboxService();
            store.dispatch(searching(true));
            const result = await inboxService.search(statusArray,1, searchArg, options, documentTypes);
            store.dispatch(setSearchResults(result.data, searchArg, result.loadMoreData));
            resetTimeout();
            this.nav('/inbox');
        }
    }

    getAllStatuses() {
        let statusDescList = new Set();
        let sortedStatus = [];
        let excludedStatus = ['Application Sent', 'BOL Requested', 'BOL Void Requested', 'BOL Void Accepted', 'BOL Correction Reviewed', 'Barge Info Corrected',
                                'Completed by Trader', 'Reconsignment Requested', 'Completed', 'Barge Removed'];
        Object.keys(defaultWorkflow).forEach(function(key) {
            if(!excludedStatus.includes(defaultWorkflow[key].statusDesc)) {
                let desc = defaultWorkflow[key].statusDesc;
                statusDescList.add(desc);
            }
        });

        sortedStatus = [...statusDescList].sort();
        sortedStatus.unshift('Busted By Shipper');
        sortedStatus.unshift('Internal Shipment');
        return sortedStatus;
    }

    getSelectedStatus() {
        let defaultFilters = [...this.getAllStatuses()];
        defaultFilters.shift();
        if(this.props.user.preferences &&
            this.props.user.preferences.customFilters &&
            this.props.user.preferences.customFilters.statusFilters) {
            defaultFilters = this.props.user.preferences.customFilters.statusFilters.statusDescriptions;
            if(this.props.user.preferences.customFilters.shipperBustsFlag) {
                defaultFilters.unshift("Busted By Shipper");
            }
            if(this.props.user.preferences.customFilters.internalShipmentFlag) {
                defaultFilters.unshift("Internal Shipment");
            }
        }
        return defaultFilters;
    }

    componentWillUnmount() {
        this.layout.removeChangeListener(this.handleBreakPointChange);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.timeout) {
            this.props.onSignOut(true)
        }

    }

    handleBreakPointChange(layout) {
        this.setState({mobile: layout === 'mobile', navCollapsed: false});
    }

    handleSignOut() {
        if (this.props.onSignOut) {
            this.props.onSignOut();
        }
    }

    createMenuModel(user) {
        if (!user) return [];
        const inboxFilters = user.type === 'CARRIER' ? defaultCarrierFilters : defaultFilters;

        const menu = [
            {title: 'INBOX', icon: 'fa-inbox', expanded: true, items: inboxFilters, url: '/inbox'}
        ];

        menu[0].items.forEach(mi => mi.selected = false);
        menu[0].items[1].selected = true;
        if(user.type !== 'CARRIER') {
            menu[0].items[1].buttonClass = 'fa fa-cog';
            menu[0].items[1].onButtonClick = this.handleUpdateFilterClick;
        }
        const isEssDocsAdmin = this.props.user.roles && (this.props.user.roles.indexOf('ESSDOCSADMIN') >= 0);
        if (isEssDocsAdmin) {
            menu.push({
                title: 'ADMIN',
                icon: 'fa-cog',
                expanded: false,
                url: '/admindataload',
                items: [
                    {title: 'Load Reference Data', url: '/admindataload'},
                    {title: 'Onboard Users', url: '/onboardusers'},
                    {title: 'User Management', url: '/usermanagement'},
                    {title: 'Company Management', url: '/companymanagement'},
                    {title: 'ReferenceData Management', url: '/refdatamanagement'},
                    {title: 'Dashboard', url: '/admindashboard'},
                ]
            });
        }
        return menu;
    }

    handleUpdateFilterClick() {
        this.setState({customFilterDialogVisible: true});
        resetTimeout();
    }

    async handleNavItemClick(item) {
        let menuModel = [];
        this.state.menuModel.forEach(s => {
            s.items.forEach(i => i.selected = false);
            menuModel.push(s);
        });
        item.selected = true;
        this.setState({menuModel: menuModel});
        if (item.url) {
            this.nav(item.url);
        }
        if(item.title === 'My Filtered Actions') {
            item.filter = this.state.selectedStatusFilters;
        }
        if (item.filter) {
            await this.loadInboxData(this.props.user, item.filter, this.props.searchArg, item.options, item.documentTypes);
            this.selectFilter(item.filter, item.options, item.documentTypes);
        }
        store.dispatch(updateBackToInbox(false));
    }

    handleSectionClick(section) {
        store.dispatch(collapseSideBar(false));
        this.setState({navCollapsed: false});
        let menuModel = [];
        this.state.menuModel.forEach(s => {
            if (s.title === section.title) {
                s.expanded = true;
                if (s.url) {
                    this.nav(s.url);
                }
                s.items.forEach((item) => {
                    if(section.url === item.url) {
                        item.selected = true;
                    }
                });

            } else {
                s.expanded = false;
            }
            menuModel.push(s);
        });
        this.setState({menuModel: menuModel})
        store.dispatch(updateBackToInbox(false));
    }

    handleUpdateFilters(update) {
        let statusCodes = [];
        let statusDescs = new Set();
        let isInternalShipment = false;
        let isShipperBusts = false;
        update.forEach(statusObj => {
            if(["Internal Shipment", "Busted By Shipper"].indexOf(statusObj) < 0) {
                //statuses.push({statusCode: this.getKeyByValue(statusObj.statusDesc), statusDesc: statusObj.statusDesc});
                statusCodes.push(...this.getKeyByValue(statusObj));
                statusDescs.add(statusObj);
            } else if(statusObj === 'Internal Shipment') {
                isInternalShipment = true;
            } else if(statusObj === 'Busted By Shipper') {
                isShipperBusts = true;
            }
        });
        this.setState({ selectedStatusFilters : update});
        this.updateFilter(statusCodes, [...statusDescs], isInternalShipment, isShipperBusts);
    }

    getKeyByValue(value) {
        return Object.keys(defaultWorkflow).filter(key => key !== 'DESTACCEPT' && defaultWorkflow[key].statusDesc === value);
    }

    async handleChangeCompany(companyItem) {
        if(this.props.onCompanyChange && this.props.user.company !== companyItem.id) {
            store.dispatch(setSearchResults([], this.props.searchArg, false));
            store.dispatch(userChangeOfCompany(companyItem));
            this.props.onCompanyChange(companyItem);
            store.dispatch(searching(true));
            this.setState({selectedCompany: companyItem});

            const userService = ServiceFactory.instance().createUserService();
            await userService.updateUserProperties({company: companyItem.id, companyName: companyItem.label});

            let selectedMenuItem = this.state.menuModel[0].items.find(item => item.selected === true);
            await this.loadInboxData(this.props.user, selectedMenuItem.filter, this.props.searchArg, selectedMenuItem.options, selectedMenuItem.documentTypes);
        }
    }

    async updateFilter(statusCodes, statusDescs, isInternalShipment, isShipperBusts) {
        this.setState({customFilterDialogVisible: false});
        const userService = ServiceFactory.instance().createUserService();
        let customFilters = {};
        customFilters.statusFilters = {statusCodes: statusCodes, statusDescriptions: statusDescs};
        customFilters.internalShipmentFlag = isInternalShipment;
        customFilters.shipperBustsFlag = isShipperBusts;

        await userService.updateUserProperties(customFilters);

        let menuModel = [];
        this.state.menuModel.forEach(s => {
            s.items.forEach(i => i.selected = false);
            s.items[1].selected = true;
            menuModel.push(s);
        });
        this.setState({menuModel: menuModel});
        const myActionsFilter = this.state.menuModel[0].items[1];
        myActionsFilter.options.internalShipmentOnly = isInternalShipment;
        myActionsFilter.options.bustedByShipper = isShipperBusts;

        this.selectFilter(statusDescs, myActionsFilter.options, myActionsFilter.documentTypes);
        await this.loadInboxData(this.props.user, statusDescs, this.props.searchArg,
            myActionsFilter.options, myActionsFilter.documentTypes);
        resetTimeout();
    }

    selectFilter(filter, options, documentTypes) {
        store.dispatch(setSearchFilter(filter, options, documentTypes));
    }

    render() {
        if (!this.props.user) return null;
        let companyTitle = this.props.user.companyName;

        let {mobile, navCollapsed, customFilterDialogVisible, companyMenuModel, selectedCompany} = this.state;

        if(companyMenuModel.length > 1) {
            companyTitle = '';
        } else if(companyMenuModel.length === 1) {
            selectedCompany = {};
            companyMenuModel = [];
        }

        const bodyStyle = {
            marginLeft: mobile || navCollapsed || this.props.collapseSidebar ? '30px' : this.props.navWidth
        };

        return (
            <div className='AppMain'>
                {this.state.menuModel && <Navigator className= "NavigatorExpandCollapseButton" usersFullName={this.props.user.fullName}
                                                    showCollapse={true}
                                                    onCollapseClick={() => {
                                                        let collapsed = mobile || navCollapsed || this.props.collapseSidebar;
                                                        store.dispatch(collapseSideBar(!collapsed));
                                                        this.setState({navCollapsed: !collapsed})
                                                    }}
                                                    showUser={false}
                                                    showHome={false}
                                                    width={this.props.navWidth}
                                                    menuModel={this.state.menuModel}
                                                    collapsed={mobile || navCollapsed || this.props.collapseSidebar}
                                                    onItemClick={this.handleNavItemClick}
                                                    onSectionClick={this.handleSectionClick}
                                                    title={companyTitle}
                                                    onSelectCompany = {this.handleChangeCompany}
                                                    selectedCompany={selectedCompany}
                                                    companyMenuModel={companyMenuModel}
                                                    //logoUrl={'/logos/' + this.props.user.company + '.png'}
                                                    onHomeClick={() => {
                                                        store.dispatch(updateBackToInbox(true));
                                                        this.nav('/inbox');
                                                    }}
                                                    onSignOut={this.handleSignOut}/>}
                {customFilterDialogVisible &&
                <CustomMyActionsFilter visible={customFilterDialogVisible}
                                       statusList={this.state.allStatusList}
                                       selectedFilterList={this.state.selectedStatusFilters}
                                       onUpdateFilters={this.handleUpdateFilters}
                                       onHide={() => this.setState({customFilterDialogVisible: false})}/>}
                <AppRoutes style={bodyStyle} className='AppBody'/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user.authenticated,
    timeout: state.user.timeout,
    searchArg: state.inbox.searchArg,
    collapseSidebar: state.inbox.collapseSidebar
});

export default connect(mapStateToProps)(withRouter(AppMain));


import React, {Component} from 'react';
import './BargeDetails.css';
import {formatCurrency, serverTimestampToBrowserFormat} from "@essdocs/ngfashared/shared/FormatUtils";

class BargeDetails extends Component {
    getReconsignmentNumber(barge, tsUuid) {
        const ts = barge.tradeString.find(ts => ts.uuid === tsUuid);
        return ts.reconsignment;
    }

    getConsignedTo(barge, reconNumber) {
        let consignedTo = '';
        if(reconNumber === 0) {
            if(!barge.consignedTo && barge.record.reconsignments && barge.record.reconsignments.length > 0 && barge.record.reconsignments[0].consignedToName) {
                consignedTo = barge.record.reconsignments[0].consignedToName;
            } else if (barge.consignedTo) {
                consignedTo = barge.consignedTo;
            }
        } else {
            //if(barge.record.reconsignments && barge.record.reconsignments.length > 0 ) {
            consignedTo = barge.record.reconsignments[reconNumber].consignedToName;
            //}
        }

        return consignedTo;
    }
    render() {
        const {barge, tradeStringUuid} = this.props;
        const reconsignmentNbr = this.getReconsignmentNumber(barge, tradeStringUuid);
        const consignedToValue = this.getConsignedTo(barge, reconsignmentNbr);
        return (
            <div className={'grid BargeDetails nogutter'}>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Barge ID</div>
                    <div className={'BargeInfoValue'}>{barge.bargeId}</div>
                </div>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div style={{color: 'var(--essdocs-highlight)'}} className={'BargeInfoLabel'}>Status</div>
                    <div style={{color: 'var(--essdocs-highlight)'}} className={'BargeInfoValue'}>{barge.status}</div>
                </div>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Shipper</div>
                    <div className={'BargeInfoValue'}>{barge.shipperName}</div>
                </div>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Commodity</div>
                    <div className={'BargeInfoValue'}>{barge.commodityName}</div>
                </div>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Origin</div>
                    <div className={'BargeInfoValue'}>{barge.origin}</div>
                </div>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Bill of Lading Date</div>
                    <div className={'BargeInfoValue'}>{barge.blDate}</div>
                </div>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Carrier</div>
                    <div className={'BargeInfoValue'}>{barge.carrierName}</div>
                </div>
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Quantity</div>
                    <div className={'BargeInfoValue'}>{barge.quantityAndUnit}</div>
                </div>
                {!barge.record.archived && <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Destination</div>
                    <div className={'BargeInfoValue'}>{barge.destination}</div>
                </div>}
                {!barge.record.archived && <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Care Of</div>
                    <div className={'BargeInfoValue'}>{barge.record.reconsignments[reconsignmentNbr].careOf}</div>
                </div>}
                {!barge.record.archived && <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Consigned To</div>
                    <div className={'BargeInfoValue'}>{consignedToValue}</div>
                </div>}
                {barge.record.reconsignments.length > 1 &&
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Reconsignment Last Updated</div>
                    <div className={'BargeInfoValue'}>{barge.reconLastUpdated}</div>
                </div>}
                <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Barge Last Updated</div>
                    <div className={'BargeInfoValue'}>{barge.lastUpdated}</div>
                </div>
                {barge.record.bustedByOwnerName &&  <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Busted By</div>
                    <div className={'BargeInfoValue'}>{barge.record.bustedByOwnerName}</div>
                </div>}
                {barge.fee &&  <div className={'col-6 BargeDetailsFieldGroup'}>
                    <div className={'BargeInfoLabel'}>Reconsignment Fee</div>
                    <div className={'BargeInfoValue'}>{formatCurrency(barge.fee)} USD</div>
                </div>}
            </div>
        );
    }
}

export default BargeDetails;

import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import FileDragDrop from "../FileDragDrop/FileDragDrop";
import {defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import WorkflowService from "@essdocs/ngfashared/workflow/WorkflowService";
import {DOCUMENT_TYPES} from "@essdocs/ngfashared/shared/ReferenceData/DocumentTypes";
import './UploadDialog.css';
import {ErrorMessages} from "@essdocs/commonui";

class UploadDialog extends Component  {

    constructor(props) {
        super(props);
        this.state = {
            file64: null,
            filename: null,
            errors: []
        };
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.wfService = new WorkflowService(defaultWorkflow);
    }


    handleUploadFile(docType, subType, docName, filename, file64Data) {
//        this.setState({file64: file64Data, filename: filename});
        if(filename === null && typeof file64Data === 'string') {
            this.setState({errors: [file64Data]});
        } else {
            this.props.onUploadFile(docType, subType, docName, filename, file64Data)
        }
    }

    render () {
     //   if (this.state.uploaded) return null;
        let uploadableDocTypes = DOCUMENT_TYPES.filter(
            dt => this.wfService.canAttachDocument(this.props.user, this.props.barge, dt.docType, dt.subType, this.props.tradeStringUuid));

        if(this.props.enableTransferDocument) {
            uploadableDocTypes = uploadableDocTypes.filter(dt => dt.docType === 'transferNotification');
        } else {
            uploadableDocTypes = uploadableDocTypes.filter(dt => dt.docType !== 'transferNotification');
        }
        const gradeCerts = uploadableDocTypes.filter(dt => dt.docType === 'grade');
        const weightCerts = uploadableDocTypes.filter(dt => dt.docType === 'weight');
        const otherDocs = uploadableDocTypes.filter(dt => !['grade', 'weight'].includes(dt.docType));
        return (
            <Dialog header={'UPLOAD DOCUMENT'}
                    visible={this.props.visible}
                    contentStyle={{overflow: 'visible', paddingTop: "0.75rem", paddingBottom: "1rem"}}
                    style={{width: '465px', textAlign: 'center'}} modal={true}
                    onHide={this.props.onHide}>
                <ErrorMessages errors={this.state.errors}/>
                {gradeCerts.length > 0 && <div>
                    <div className={'UploadDialogGroupHeader'}>Grade Certificates</div>
                    <div className={'grid'}>
                        {gradeCerts.map(dt =>
                            (<FileDragDrop key={dt.subType} onUploadFile={this.handleUploadFile} docType={dt.docType}
                                           className={'col-6'}
                                           subType={dt.subType} chooseLabel={dt.label}/>))}
                    </div>
                </div>}
                {weightCerts.length > 0 && <div>
                    <div className={'UploadDialogGroupHeader'}>Weight Certificates</div>
                    <div className={'grid'}>
                        {weightCerts.map(dt =>
                            (<FileDragDrop key={dt.subType} onUploadFile={this.handleUploadFile} docType={dt.docType}
                                           className={'col-6'}
                                           subType={dt.subType} chooseLabel={dt.label}/>))}
                    </div>
                </div>}
                {otherDocs.length > 0 && <div>
                    <div className={'UploadDialogGroupHeader'}>Other Documents</div>
                    <div className={'grid'}>
                    {otherDocs.map(dt =>
                        (<FileDragDrop key={dt.docType} onUploadFile={this.handleUploadFile} docType={dt.docType}
                                       className={'col-6'}
                                       subType={dt.subType} chooseLabel={dt.label}/>))}
                    </div>
                </div>}
                {uploadableDocTypes.length === 0 ? <h3>There are no documents available to upload</h3> : null}
            </Dialog>
        )
    }
}


export default UploadDialog;

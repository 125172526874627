import {
    SIGNON,
    SIGNOFF,
    USER_TIMEOUT,
    USER_COMPANY_CHANGE,
    UPDATE_USER_SEARCH_SETTINGS
} from './Actions';


const initialState = {
    authenticated: null,
    timeout: false,
    serverMode: true,
};


export function userReducer(state = initialState, action) {
    switch(action.type) {
        case SIGNON:
            return {
                ...state,
                authenticated: action.payload.user,
                timeout: false
            };
        case USER_COMPANY_CHANGE:
            return {
                ...state,
                authenticated: { ...state.authenticated,
                    company: action.payload.companyData.id,
                    companyName: action.payload.companyData.label
                },
                timeout: false
            };
        case UPDATE_USER_SEARCH_SETTINGS:
            return {
                ...state,
                authenticated: { ...state.authenticated,
                    preferences: { ...state.authenticated.preferences,
                        commodityFilters: action.payload.userSearchSettings.commodityFilters,
                        bargeIdFilter: action.payload.userSearchSettings.bargeIdFilter,
                        bolDateFilter: action.payload.userSearchSettings.bolDateFilter
                    }
                },
                timeout: false
            };
        case SIGNOFF:
            return {
                ...state,
                authenticated: null
            };
        case USER_TIMEOUT:
            return {
                ...state,
                timeout: action.payload.timeout
            };
        default:
            return state;
    }
}

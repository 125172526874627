/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "adminuser",
            "endpoint": "https://ewgr3j2is8.execute-api.eu-west-1.amazonaws.com/bdtprod",
            "region": "eu-west-1"
        },
        {
            "name": "barges",
            "endpoint": "https://0uvbvhpm84.execute-api.eu-west-1.amazonaws.com/bdtprod",
            "region": "eu-west-1"
        },
        {
            "name": "documents",
            "endpoint": "https://36ouviphtg.execute-api.eu-west-1.amazonaws.com/bdtprod",
            "region": "eu-west-1"
        },
        {
            "name": "ngfausers",
            "endpoint": "https://4bmtt5frl3.execute-api.eu-west-1.amazonaws.com/bdtprod",
            "region": "eu-west-1"
        },
        {
            "name": "oauth2",
            "endpoint": "https://bzlm7sp8p6.execute-api.eu-west-1.amazonaws.com/bdtprod",
            "region": "eu-west-1"
        },
        {
            "name": "publicapi",
            "endpoint": "https://iecg3ig7fe.execute-api.eu-west-1.amazonaws.com/bdtprod",
            "region": "eu-west-1"
        },
        {
            "name": "referencedata",
            "endpoint": "https://ou63l8hra1.execute-api.eu-west-1.amazonaws.com/bdtprod",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:f60cdc13-6c6e-4a5f-98cf-6ea824715be0",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_zhmOTfTLG",
    "aws_user_pools_web_client_id": "7pk99j7edo96vmpecjpdio9ovc",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "billinginfo-bdtprod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "ngfa-documents-bdtprod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;

import React from 'react';
import './BargeInfo.css';

export function BargeDetailsPanel(props) {
    return (
            <div style={props.style} className={props.className}>
                <div className={'BargeDetailsPanel'}>
                    {props.title && <div className={'BargeDetailsPanelTitle'}>{props.title}</div>}
                    {props.children}
                </div>
            </div>
        );
}

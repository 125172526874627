import {SAVE_MESSAGE, SET_MESSAGE_TESTDATA} from './Actions';


const initialState = {
    messages: {}
};


export function messagesReducer(state = initialState, action) {
    switch(action.type) {
        case SAVE_MESSAGE:
            const msg = action.payload.message;
            const msgsCopy = {...state.messages};
            let bargeMessages = msgsCopy[action.payload.bargeId];
            if (!bargeMessages) bargeMessages = [];
            bargeMessages = [msg, ...bargeMessages];
            msgsCopy[action.payload.bargeId] = bargeMessages;
            return {
                ...state,
                messages: msgsCopy
            };
        case SET_MESSAGE_TESTDATA:
            const messages = action.payload.messages;
            return {
                ...state,
                messages: messages
            };

        default:
            return state;

    }
}

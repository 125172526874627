import React, {Fragment} from 'react';

export function DocumentRow(props) {
    const isBarge = props.doc.id === 'BARGE';
    let rowClass = 'p-component DocumentRow grid no-gutter ';
    rowClass += isBarge ? ' DocumentRowBargeDetails ' : ' DocumentRowDocument ';
    if (props.selected) {
        if (isBarge) {
            rowClass += ' DocumentBargeRowSelected ';
        } else {
            rowClass += ' DocumentRowSelected ';
        }
    }
    if (props.doc.className) rowClass += props.doc.className;

    function handleDeleteDoc(evt) {
        evt.stopPropagation();
        if (props.onDeleteDoc) {
            props.onDeleteDoc(props.doc);
        }
    }

    return (
        <Fragment>
            <div onClick={() => props.onSelectDoc(props.doc)}
                 style={props.doc.style}
                 className={rowClass}>
                <div className={'DocumentIcon col-fixed ' + props.doc.icon}/>
                <div className={'DocumentListName col'}>{props.doc.title}</div>
                {props.doc.allowDelete && <div onClick={handleDeleteDoc} className={'DocumentListButton fa fa-trash col-fixed'}/>}
                {props.doc.allowReplace && <div onClick={handleDeleteDoc} className={'DocumentListButton fa fa-upload col-fixed'}/>}
            </div>
        </Fragment>
    );
}

import React, {Component} from 'react';
import 'font-awesome/css/font-awesome.min.css';
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import 'primeflex/primeflex.css';
import "./assets/themes/mytheme/theme.scss";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';
import {Provider} from "react-redux";
import {store} from "./redux/Store";
import { BrowserRouter as Router } from "react-router-dom";
import AppMain from "./AppMain";
import {Amplify} from 'aws-amplify';
import aws_exports from './aws-exports';
import TestLogin from "./components/TestLogin/TestLogin";
import {
    setMessageTestData,
    setSearchFilter,
    setSearchResults,
    setTestData,
    signOff,
    signOn, updateSortFields,
    userTimeout
} from "./redux/Actions";
import axios from "axios";
import {Auth, Hub} from 'aws-amplify';
import ServiceFactory from "./services/ServiceFactory";
import {resetTimeout} from "./shared/TimeoutUtil";
import {
    Heading,
    useAuthenticator,
    useTheme,
    View,
    withAuthenticator
} from "@aws-amplify/ui-react";
import '@aws-amplify/ui/dist/styles.css';
import {Button} from "primereact/button";
Amplify.configure(aws_exports);

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            showTimeoutMessage: false
        };

        Hub.listen('auth', (data) => {this.onAuthEvent(data.payload)});

        this.userService = ServiceFactory.instance().createUserService();
        this.handleSelectTestUser = this.handleSelectTestUser.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
        this.handlePostAuthentication = this.handlePostAuthentication.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
    }

    onAuthEvent(data) {
        switch (data.event) {
            case 'signIn':
                store.dispatch(userTimeout(false));
                this.setState({showTimeoutMessage: false})
                this.handlePostAuthentication();
                break;
            case 'signOut':
                break;
            default:
                console.log('unhandled auth event: ' + data.event);
        }

    }

    async handleSignOut(showTimeoutMessage = false) {
        await this.userService.updateUserProperties({userLoggedIn: false});
        this.setState({user: null, showTimeoutMessage: showTimeoutMessage});
        store.dispatch(signOff());
        store.dispatch(setSearchResults(null));
        store.dispatch(updateSortFields([]));
        if (store.getState().user.serverMode) {
            Auth.signOut();
        }
    }

    componentDidMount() {
        const serverMode = store.getState().user.serverMode;
        if (serverMode) {
            this.handleInitServerMode();
        } else {
            this.initTestUserMode();
        }
    }

    async handleInitServerMode() {

        try {
            //If user was already authenticated load the startup data
            await Auth.currentAuthenticatedUser();
            this.handlePostAuthentication();
        } catch (e) {
            //Exception occurs if user not authenticated so can ignore
        }
    }

    async handlePostAuthentication() {
        this.setState({showTimeoutMessage: false});
        await this.loadUserDetails();
        this.cacheReferenceData();
        this.gainsightIdentification();
    }

    handleCompanyChange(selectedCompany) {
        if (selectedCompany) {
            this.setState({showTimeoutMessage: false});
            this.cacheReferenceData();
            this.gainsightIdentification();
            resetTimeout();
        }
    }

    gainsightIdentification() {
        const user = this.state.user;

        const userFields = {
            id: user?.username,
            email: user?.username,
            firstName: user?.firstName,
            lastName: user?.lastName
        };

        const accountFields = {
            id: user?.company,
            name: user?.companyName
        };

        //passing user and account objects:
        window.aptrinsic("identify", userFields, accountFields);
    }

    async loadUserDetails() {
        let user = await this.userService.getCurrentlyAuthenticatedUser();
        await this.userService.updateUserProperties({userLoggedIn: true});
        if (user) {
            if (user.roles && user.roles.includes('APIUSER')) {
                alert('API users are not allowed to access the web application');
                let user = {};
                this.handleSignOut();
            }
            store.dispatch(signOn(user));
            store.dispatch(setSearchFilter(''));
            this.setState({user: user});
            resetTimeout();
        } else {
            console.error('No authenticated user found');
        }
    }

    async initTestUserMode() {
        await this.loadTestData();
        this.cacheReferenceData();
    }

    cacheReferenceData() {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        refDataService.cacheReferenceData();
    }

    handleSelectTestUser(user) {
        store.dispatch(signOn(user));
        store.dispatch(setSearchFilter(''));
        this.setState({user: user});
    }

    async loadTestData() {
        const result = await axios.get('/data/inbox.json');
        store.dispatch(setTestData(result.data));

        const messages = await axios.get('/data/messages.json');
        store.dispatch(setMessageTestData(messages.data));
    }

    renderTestUser() {
        const user = this.state.user;
        if (user) {
            return (<Provider store={store}>
                        <Router>
                            <AppMain onSignOut={this.handleSignOut}/>
                        </Router>
                    </Provider>)
        } else {
            return (<TestLogin onSelectUser={this.handleSelectTestUser}/>);
        }
    }

    formFields = {
        signIn: {
            username: {
                placeholder: 'Enter Your Email Here',
                isRequired: true,
                label: 'Email:'
            },
        },
    }
    components = {
        SignIn: {
            Header() {
                const {tokens} = useTheme();

                return (
                    <Heading ariaLabel={"BDT"}
                        padding={`${this.tokens.space.xl} 0 0 ${this.tokens.space.xl}`}
                        level={3}

                    >
                        Sign in to your BDT account
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Reset Password
                        </Button>
                    </View>
                );
            },
        },
        }

    renderCognitoUser() {
        return (
            <div style={{textAlign: 'center'}}>
                {this.state.showTimeoutMessage && <h3>You were signed out due to 60 mins of inactivity</h3>}
                        {this.state.user && <Provider store={store}>
                            <Router>
                                <AppMain onSignOut={this.handleSignOut} onCompanyChange={this.handleCompanyChange}/>
                            </Router>
                        </Provider>}
                {/*
                <withAuthenticator>
                    <div>
                            {this.state.user && <Provider store={store}>
                                <Router>
                                    <AppMain onSignOut={this.handleSignOut} onCompanyChange={this.handleCompanyChange}/>
                                </Router>
                            </Provider>}
                        </div>
                </withAuthenticator>*/}
            </div>
        );
    }

    render() {
        const serverMode = store.getState().user.serverMode;
        return serverMode ? this.renderCognitoUser() : this.renderTestUser();
    }
}

export default withAuthenticator(App, {
    formFields: {
        signIn: {
            username: {
                placeholder: 'Enter your Email',
                isRequired: true,
                label: 'Email'
            },
        },
        resetPassword: {
            username: {
                placeholder: 'Enter your Email',
                isRequired: true,
                label: 'Email'
            },
        },
    },
    components: {
       Header: () => {

            const {tokens} = useTheme();
            return (
                <View textAlign="center" padding={tokens.space.medium}>
                    {store.getState().user.timeout && <h3>You were signed out due to 60 mins of inactivity</h3>}
                </View>
            );
},
        SignIn: {
            Header() {
                const {tokens} = useTheme();

                return (
                    <Heading ariaLabel={"BDT"}
                             padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                             level={5}

                    >
                        Sign in to the BDT solution
                    </Heading>
                );
            }
        },
}});

import axios from "axios";
import {store} from "../../redux/Store";
import {cacheRefData} from "../../redux/Actions";
import ReferenceDataCommon from "../ReferenceDataCommon";

export default class LocalReferenceDataService extends ReferenceDataCommon{
    /**
     * for local refererce data service cache all data
     */
    async cacheReferenceData() {
        const refData = await axios.get('/data/NgfaRefData.json');
        refData.data.forEach(obj => {
            store.dispatch(cacheRefData(obj.groupId, obj.records));
        });
    }

    async readAll(groupId) {
        return store.getState().referenceData[groupId];
    }

    async readRecordById(groupId, recordId) {
        const recs = store.getState().referenceData[groupId];
        const result = recs.find(r => r.id === recordId);
        return result ? result : {};
    }

    async search(groupId, searchArg) {
        const recs = store.getState().referenceData[groupId];
        const sa = searchArg.toUpperCase();
        return recs.filter(r => r.search.indexOf(sa) >= 0);
    }
}

import {SAVE_DOCUMENT} from './Actions';


const initialState = {
    documents: {}
};


export function documentReducer(state = initialState, action) {
    switch(action.type) {
        case SAVE_DOCUMENT:
            const docsCopy = {...state.documents};
            docsCopy[action.payload.uuid] = action.payload.documentb64;
            return {
                ...state,
                documents: docsCopy
            };
        default:
            return state;

    }
}

import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {ErrorMessages, FieldLabel} from "@essdocs/commonui";
import {Button} from "primereact/button";
import ServiceFactory from "../../services/ServiceFactory";
import {InputText} from "primereact/inputtext";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {AutoComplete} from "primereact/autocomplete";
import {
    dateToServerFormat, formatNumber,
    serverDateToBrowserFormat
} from "@essdocs/ngfashared/shared/FormatUtils";
import { ProgressSpinner } from "primereact/progressspinner";

class TransferProductDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: 0,
            errors: [],
            bargeId: '',
            bargeIdSuggestions: [],
            disableSave: true,
            carriers: [],
            showSpinner: false
        };

        this.refDataService = ServiceFactory.instance().createReferenceDataService();

        this.handleTransferProduct = this.handleTransferProduct.bind(this);
        this.handleUpdateQuantity = this.handleUpdateQuantity.bind(this);
        this.suggestBargeIds = this.suggestBargeIds.bind(this);
        this.handleUpdateBargeId = this.handleUpdateBargeId.bind(this);
    }

    async suggestBargeIds(e) {
        const results =  await this.refDataService.search(ReferenceDataType.Barges, e.query);
        this.setState({bargeIdSuggestions: results});
    }

    async componentDidMount() {
        const {barge} = this.props;
        const carriers = await this.refDataService.readAll(ReferenceDataType.Carriers);

        this.setState({
            quantity: barge.quantity,
            bargeId: barge.bargeId,
            carriers: carriers
        });

    }

    async handleUpdateBargeId(e) {
        this.setState({errors : [], disableSave: false});
        let bargeId = e.target.value.id || e.target.value;

        if (this.props.barge.bargeId === bargeId
            && parseFloat(this.state.quantity.replace(',', '')) === parseFloat(this.props.barge.quantity.replace(',', ''))) {
            this.setState({disableSave: true, bargeId: bargeId});
            return;
        }
        if (e.target.value.id) {
            const carrierCode = e.target.value.carrierCode;
            const carrier = this.state.carriers.find(c => c.id.trim() === carrierCode.trim());

            if (carrier) {
                if (this.props.loggedInUser
                    && this.props.loggedInUser.type === 'CARRIER'
                    && this.props.loggedInUser.company !== carrierCode) {
                    this.setState({errors: [`Barge ID '${bargeId}' does not belong to Carrier '${this.props.loggedInUser.companyName}'`], bargeId: bargeId});
                    return;
                }
                this.setState({bargeId: bargeId});
            }
        } else {
            this.setState({bargeId: bargeId});
        }
    }

    handleUpdateQuantity(event) {
        this.setState({errors : [], disableSave: false});
        let quantity = event.target.value;
        if (this.props.barge.bargeId === this.state.bargeId
            && parseFloat(quantity.split(',').join('')) === parseFloat(this.props.barge.quantity.split(',').join(''))) {
            this.setState({disableSave: true, quantity: quantity});
            return;
        }
        this.setState({quantity: quantity});
    }

    handleTransferProduct() {
        this.setState({disableSave :true, showSpinner: true});
        /*const quantityUnformatted = this.state.quantity.split(',').join('');
        let quantity = parseFloat(quantityUnformatted);

        if (isNaN(quantity)) {
            quantity = 0;
        }*/

        this.validate();
    }

    async validate() {
        const result =  await this.refDataService.readRecordById(ReferenceDataType.Barges, this.state.bargeId);
        if (!result.id) {
            this.setState({errors: ['Invalid Barge ID'], showSpinner: false, disableSave: true});
            return;
        }
        if (parseFloat(this.state.quantity.split(',').join('')) <= 0) {
            this.setState({errors: ['Quantity must be greater than 0'], showSpinner: false, disableSave: true});
            return;
        }

        const carrierCode = result.carrierCode;
        const carrier = this.state.carriers.find(c => c.id.trim() === carrierCode.trim());

        if (carrier) {
            if (this.props.loggedInUser
                && this.props.loggedInUser.type === 'CARRIER'
                && this.props.loggedInUser.company !== carrierCode) {
                this.setState({errors: [`Barge ID '${this.state.bargeId}' does not belong to Carrier '${this.props.loggedInUser.companyName}'`],
                    showSpinner: false, disableSave: true});
                return;
            }
        }

        let blDate = dateToServerFormat(new Date(this.props.barge.blDate));
        const inboxService = ServiceFactory.instance().createInboxService();
        let existingBarges = await inboxService.findBargeByBargeId(result.id, blDate);
        if(existingBarges && existingBarges.length > 0) {
            for(let barge of existingBarges) {
                if(barge.uuid !== this.props.barge.uuid) {
                    this.setState({errors: [`Please note that there is a transaction with this Barge ID and BOL Date in the solution already. Please create a transaction with different Barge ID and/or BOL Date`],
                        showSpinner: false, disableSave: true});
                    return;
                }
            }
        }

        this.props.onUpdateData({bargeId: result.id, quantity: this.state.quantity});
        this.setState({errors: [], showSpinner: false});
        this.props.onTransferProduct();
    }

    render() {
        const {barge} = this.props;
        const {errors, showSpinner, disableSave, quantity} = this.state;
        const disableTransfer = disableSave || errors.length !== 0;

        return (
            <Dialog header={`TRANSFER PRODUCT`}
                    visible={this.props.visible}
                    contentStyle={{overflow: 'auto'}}
                    style={{width: '500px'}} modal={true}
                    onHide={this.props.onHide}>

                <div style={{padding: '5px'}} className={'grid'}>
                    <ErrorMessages errors={this.state.errors}/>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Barge ID"/>
                        <AutoComplete value={this.state.bargeId}
                                      style={{display: 'block', width: '100%'}}
                                      inputStyle={{width: '100%'}}
                                      autoFocus={true}
                                      minLength={2}
                                      field={'id'}
                                      onChange={this.handleUpdateBargeId}
                                      suggestions={this.state.bargeIdSuggestions}
                                      completeMethod={this.suggestBargeIds} />
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Quantity"/>
                        <InputText value={quantity}
                                   style={{textAlign: 'right', width: '100%'}}
                                   keyfilter={'money'}
                                   onChange={this.handleUpdateQuantity}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Shipper"/>
                        <div className={'DialogValue'}>{barge.shipperName}</div>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Bill of Lading Date"/>
                        <div className={'DialogValue'}>{serverDateToBrowserFormat(barge.blDate)}</div>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Commodity"/>
                        <div className={'DialogValue'}>{barge.commodity + ' ' + barge.commodityName}</div>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Quantity Unit"/>
                        <div className={'DialogValue'}>{barge.quantityUnit}</div>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Origin"/>
                        <div className={'DialogValue'}>{barge.origin}</div>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Carrier"/>
                        <div className={'DialogValue'}>{barge.carrierName}</div>
                    </div>
                    <div style={{textAlign: 'right', marginTop: '10px'}} className={'col-12'}>
                        {showSpinner && <ProgressSpinner style={{width: '25px', height: '20px'}}
                                                         strokeWidth="8"
                                                         fill="#EEEEEE"
                                                         animationDuration=".8s"/>}
                        <Button disabled={disableTransfer}
                                icon={'fa fa-check'}
                                className={'p-button-success'}
                                label={'SAVE'}
                                onClick={this.handleTransferProduct}/>
                        <Button icon={'fa fa-times'}
                                style={{marginLeft: '10px'}}
                                className={'p-button-secondary'}
                                label={'CANCEL'}
                                onClick={this.props.onHide}/>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default TransferProductDialog;
import {store} from "../../redux/Store";
import {addTestDataRecord, archiveBarge, updateTestDataRecord, updateTestDataRecords} from "../../redux/Actions";
import {createDatabaseTimestamp} from "@essdocs/ngfashared/shared/FormatUtils";
import ServiceFactory from "../ServiceFactory";
import {defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import WorkflowService from "@essdocs/ngfashared/workflow/WorkflowService";
import {deepCopy} from "@essdocs/ngfashared/shared/CopyUtil";
import { v4 as uuidv4 } from 'uuid';

export default class LocalInboxService {

    constructor() {
        this.wfService = new WorkflowService(defaultWorkflow);
    }

    async search(statusArray, pageNumber, searchArg, options, documentTypes) {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(300);

        if (!options) options = {};

        const user = store.getState().user.authenticated;
        if (user.type === 'CARRIER') {
            return this.carrierSearch(user, pageNumber, statusArray, searchArg, documentTypes)
        }

        let data =  store.getState().inbox.testData;
        if (options.isArchive === true) {
            data =  store.getState().inbox.archiveTestData;
        }

        const company = user.company;
        const search = searchArg.toUpperCase();
        let statusList = statusArray;
        let filtered = [];

        data.forEach(r => {
            if (options.curOwnerOnly === true && !r.curOwners.some(o => o === user.company)) return false;
            if (options.curOwnerOnly === false && r.curOwners.some(o => o === user.company)) return false;
            if (options.companyLoaded === true && company !== r.createdByCompany) return;
            if (options.billedBarges === true && !this.endReceiverAndDestPropStatus(company, r)) return;

            if (!this.companyAndStatusExistsInTradeString(company, statusList, r.tradeString)) {
                    return;
            }

            if (options.documentsExist === true && (!r.documents || r.documents.length === 0)) return;


            if (documentTypes) {
                if (!this.findDocumentTypes(r.documents, documentTypes)) return;
            }

            if (searchArg && searchArg.length > 0) {
                if (search.indexOf(search) < 0) return false;
            }

            return filtered.push(deepCopy(r));
        });
        this.truncateTradeStrings(filtered, user);

        this.sort(filtered, 'lastUpdated', false);
        return  {data: filtered};
    }

    findDocumentTypes(documents, documentTypes) {
        if (!documentTypes || documentTypes.length === 0 || !documents) return;

        for (let idx = 0; idx < documentTypes.length; idx++) {
            const dt = documentTypes[idx];
            if (documents.find(d => d.docType === dt)) return true;
        }
        return false;
    }

    async carrierSearch(user, pageNumber, statusArray, searchArg, documentTypes) {
        const data =  store.getState().inbox.testData;
        const search = searchArg.toUpperCase();
        const filtered = data.filter(r => {
            if (user.type === 'CARRIER' && user.company === r.carrier) {
                if (search && search.length > 0) {
                    if (search.indexOf(search) < 0) return false;
                }

                if (documentTypes) {
                    if (!this.findDocumentTypes(r.documents, documentTypes)) return;
                }

                if (statusArray.length === 0) return true;

                for (let idx = 0; idx < r.tradeString.length; idx++) {
                    const ts = r.tradeString[idx];
                    if (statusArray && statusArray.length > 0) {
                        if (statusArray.indexOf(ts.statusCode) >= 0 ) {
                            return true;
                        }
                    }
                }
                return false;
            }
            return false;
        });

        this.sort(filtered, 'lastUpdated', false);
        return  {data: filtered};
    }

    /**
     * Truncate the trade string for certain states codes. In some cases only the next entry in the trade string
     * and prior one should be visible to the current user. This is determined by a flag in the workflow config
     * @param records a list of records to truncate the trade strings
     * @param user the current user
     */
    truncateTradeStrings(records, user) {
        records.forEach(barge => {
            this.wfService.truncateTradeString(barge, user)
        });
    }

    companyAndStatusExistsInTradeString(company, statusList, tradeString) {
        if (!tradeString || tradeString.length === 0) return false;
        const result = tradeString.find(t => {
            if (t.owner !== company) return false;
            if (!statusList || statusList.length === 0) return true;
            return statusList.indexOf(t.statusCode) >= 0;
        });

        return result !== undefined;
    }

    endReceiverAndDestPropStatus(company, barge) {
        if (!barge.endReceiverOwner) return false;
        if (barge.endReceiverOwner === company && ["DESTPROP", "RECONDESTPROP"].indexOf(barge.statusCode) >= 0 ) {
            return true;
        }
        return false;
    }

    async createBarge(fields) {
        const user = store.getState().user.authenticated;
        let barge = {};
        const result =  this.wfService.executeCreateBarge(user, barge, fields);

        const tradeStringUuid = barge.tradeString[0].uuid;
        store.dispatch(addTestDataRecord(barge));
        const messageService = ServiceFactory.instance().createMessageService();
        messageService.addActivity(barge.uuid, tradeStringUuid, result.notificationMsg, user);
    }

    async updateBarge(bargeUuid, fields) {
        const user = store.getState().user.authenticated;
        const findResult = await this.findBarge(bargeUuid, false, user);
        const barge = findResult.barge;
        const result =  this.wfService.executeEditBarge(user, barge, fields);
        const messageService = ServiceFactory.instance().createMessageService();
        messageService.addActivity(barge.uuid, barge.tradeString[0].uuid, result.notificationMsg, user);
        store.dispatch(updateTestDataRecord(barge));
    }

    async executeActionAndUpdateBarges(bargeIdList, workflowAction, actionParams) {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(300);
        const user = store.getState().user.authenticated;

        const recsToUpdate = [];
        const messageService = ServiceFactory.instance().createMessageService();
        for (let idx = 0; idx < bargeIdList.length; idx++) {
            const b = bargeIdList[idx];
            const findBarge = await this.findBarge(b.uuid);
            const barge = findBarge.barge;
            const action = this.wfService.executeWorkflowAction(user, barge, b.tradeStringUuid, workflowAction, actionParams);
            recsToUpdate.push(barge);

            if (action.notificationTradeStringArray) {
                for (let idx = 0; idx < action.notificationTradeStringArray.length; idx++) {
                    await messageService.addActivity(barge.uuid, action.notificationTradeStringArray[idx], action.notificationMsg, user);
                }
            } else {
                await messageService.addActivity(barge.uuid, b.tradeStringUuid, action.notificationMsg, user);
            }
            if (action.bustedBarge) {
                store.dispatch(archiveBarge(action.bustedBarge));
            }

            //Add a uuid for the BOL doc
            if (action.actionId === 'bolReqAccept') {
                const bolDoc = barge.documents.find(d => d.docType === 'BOL');
                if (bolDoc) {
                    bolDoc.uuid = uuidv4();
                }
            }
        }
        store.dispatch(updateTestDataRecords(recsToUpdate));
    }
    async findBarge(uuid, truncateTradeString, user) {
        let bargeRec = store.getState().inbox.testData.find(r => r.uuid === uuid);
        if (!bargeRec) {
            bargeRec = store.getState().inbox.archiveTestData.find(r => r.uuid === uuid);
        }
        if (!bargeRec) return null;
        const copy = deepCopy(bargeRec);
        const result = {};
        result.barge = copy;
        if (truncateTradeString === true) {
            this.wfService.truncateTradeString(copy, user);
        }
        result.messages = await this.getMessages(copy);
        return result;
    }


    async getMessages(barge) {
        const messageService = ServiceFactory.instance().createMessageService();
        const messages = await messageService.getMessages(barge.uuid);
        const filteredMessages = [];
        messages.forEach(m => {
            const ts = barge.tradeString.find(ts => ts.uuid === m.tradeStringUuid);
            if (ts) {
                delete m.username;
                filteredMessages.push(m);
            }
        });
        return filteredMessages;
    }

    sort(data, sortBy, ascending) {

        if (sortBy) {
            data.sort(function (a, b) {
                return ascending ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
            });
        }
    }

    async addDocument(barge, filename, docType, docName, uuid) {
        const docRec = {
            uuid: uuid,
            docType: docType,
            docName: docName,
            filename: filename,
            created: createDatabaseTimestamp()
        };

        if (!barge.documents) barge.documents = [];
        barge.documents.push(docRec);
        this.updateBarge(barge);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

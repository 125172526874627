module.exports.REF_DATA_LIST = [
    {name: 'Barge Ids', code: 'barges', query: [
            {"$group" : { "_id": "$id", carrierCode: { $addToSet: "$carrierCode" }, "count": { "$sum": 1 } } },
            {"$match": {"_id" :{ "$ne" : null } , "count" : {"$gt": 1} } },
            {"$sort": {"count" : -1} },
            {"$project": {"id" : "$_id", "_id" : 0, count: 1, carrierCode: 1}}
        ]},
    {name: 'Carriers', code: 'carriers', query: []},
    {name: 'Commodities', code: 'commodities', query: []},
    {name: 'Destinations', code: 'destinations', query: []},
    {name: 'Origins', code: 'origins', query: []},
    {name: 'Shippers', code: 'shippers', query: []}
];

class ReferenceDataCommon  {
    deriveUnitsOfMeasure(commodities) {
        const obj = {};
        commodities.forEach(c => obj[c.uomAbbreviation] = c.uom);
        const uoms = [];
        Object.keys(obj).forEach(id => uoms.push({id: id, name: obj[id]}));
        return uoms;
    }
}

export default ReferenceDataCommon;

import React from 'react';
import {EssDocsComponent, EssDocsPage} from "@essdocs/commonui";
import {FileUpload} from "primereact/fileupload";
import './AdminDataLoad.css';
import {Dropdown} from "primereact/dropdown";
import { Button } from 'primereact/button';
import {API} from "aws-amplify";
import ServiceFactory from "../../services/ServiceFactory";
import LogDisplayer from "./LogDisplayer";
import {Checkbox} from "primereact/checkbox";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";

const CHUNK_SIZE = 500;
const DOCHUB_COMPANY_RANGE_START = 88000;

const fileTypes = [
    {name: 'Barges', code: 'barges'},
    {name: 'Carriers', code: 'carriers'},
    {name: 'Commodities', code: 'commodities'},
    {name: 'Destinations', code: 'destinations'},
    {name: 'Origins', code: 'origins'},
    {name: 'Shippers', code: 'shippers'},
    {name: 'Users', code: 'users'},
    {name: 'Inbox', code: 'inbox'}
];

class AdminDataLoad extends EssDocsComponent {


    constructor(props) {
        super(props);
        this.handleUpload = this.handleUpload.bind(this);
        this.state = {
            data: null,
            messages: [],
            fileType: null,
            validated: false,
            shippers: [],
            uploading: false,
            replace: false,
            confirmationPopupVisible: false
        }

        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.handlePopupConfirmation = this.handlePopupConfirmation.bind(this);
    }

    addMessage(type, msg) {
        this.setState({messages: [...this.state.messages, {type: type, msg: msg}]});
    }

    componentDidMount() {
        this.loadRefData();
    }

    async loadRefData () {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        const shippers = await refDataService.readAll('shippers');
        const carriers = await refDataService.readAll('carriers');
        this.setState({shippers: shippers, carriers: carriers});
    }

    validateShipper(shipperId) {
        const shipper = this.state.shippers.find(s => s.id === shipperId);
        return shipper !== undefined;
    }


    handlePopupConfirmation() {
        this.setState({confirmationPopupVisible: false});
        this.handleUpload();
    }

    getNextCompanyId() {
        let lastUsedCompanyId = DOCHUB_COMPANY_RANGE_START;
        this.state.carriers[2].dochubCompanyId = 88123;
        this.state.shippers.forEach(s => {
            if (s.dochubCompanyId && s.dochubCompanyId > lastUsedCompanyId) {
                lastUsedCompanyId = s.dochubCompanyId;
            }
        });
        this.state.carriers.forEach(c => {
            if (c.dochubCompanyId && c.dochubCompanyId > lastUsedCompanyId) {
                lastUsedCompanyId = c.dochubCompanyId;
            }
        });
        return lastUsedCompanyId + 1;
    }
    setDocHubCompanyId(data) {
        let nextCompanyId = this.getNextCompanyId();
        data.forEach(d => {
            d.dochubCompanyId = nextCompanyId++;
        });
    }

    async handleUpload() {
        const path = `/referencedata/upload`;

        const fileType = this.state.fileType.code;
        let indexes;
        if (fileType === 'onboardusers' || fileType === 'updateusers' || fileType === 'users') {
            indexes = ['username']
        } else if (fileType === 'inbox') {
            indexes = ['uuid', 'carrier', 'search', 'tradeString.searchKey'];
        } else {
            indexes = [];
        }

        let data = this.state.data;

        // if (fileType === 'shippers' || fileType === 'carriers') {
        //    this.setDocHubCompanyId(data)
        // }

        let myInit = {
            body: {
                collection: fileType,
                data: data,
                indexes: indexes,
                replace: this.state.replace
            },
            headers: {}
        };



        try {
            this.setState({uploading: true});
            this.addMessage('I', 'Uploading Data. Please wait....');
            if (myInit.body.data.length > CHUNK_SIZE) {
                this.chunkUpload(myInit, path);
            } else {
                const result = await API.post('referencedata', path, myInit);
                this.addMessage('I', result.data);
            }

        } catch (e) {
            console.error('failed to upload data');
            console.error(e);
            this.addMessage('E', 'Error importing data');
        }
        this.setState({uploading: false});
    }

    async chunkUpload(init, path) {
        const records = init.body.data;
        init.body.replace = true;
        let chunk = [];
        try {
            for (let idx = 0; idx < records.length; idx++) {
                chunk.push(records[idx]);
                if (chunk.length === CHUNK_SIZE) {
                    this.addMessage('I', `Uploading Chunk ${idx - CHUNK_SIZE}-${idx + 1}`);
                    init.body.data = chunk;
                    const result = await API.post('referencedata', path, init);
                    this.addMessage('I', result.data);
                    chunk = [];
                    init.body.replace = false;
                }
            }
            if (chunk.length > 0) {
                this.addMessage('I', 'Uploading Last Chunk');
                init.body.data = chunk;
                const result = await API.post('referencedata', path, init);
                this.addMessage('I', result.data);
            }
        } catch(e) {
            console.error(e);
            this.addMessage('E', 'Error loading chunk');
        }
    }

    clearMessages() {
        this.setState({messages: []});
    }

    handleSelectFile(event) {
        const reader = new FileReader();
        const $this = this;
        $this.fileUpload.clear();
        $this.fileUpload.files = [];
        $this.clearMessages()
        reader.onload = function(){
            const json = reader.result;
            try {
                const data = JSON.parse(json);
                $this.setState({data: data});
                $this.dataLoaded(data);
            } catch(e) {
                console.error(e);
                $this.addMessage('E', 'Failed to load file');
            }
        };
        reader.readAsText(event.files[0]);
    }

    dataLoaded(data) {
        const type = this.state.fileType.code;
        if (type === 'users') {
            this.validateUsers(data);
            return;
        }

        if (type === 'inbox') {
            this.validateInbox(data);
            return;
        }

        this.addMessage('I', 'Reference data read: ' + data.length + ' records');
        this.setState({validated: true});
    }

    validateUsers(data) {
        if (data.length === 0) {
            this.addMessage('E', 'No user data to upload');
            return;
        }
        this.addMessage('I', 'Users: ' + data.length + ' records');
        const errors = [];
        data.forEach((u, idx) => {
            const recIdx = idx + 1;
            if (!u.username) errors.push('Missing username: record#' + recIdx);
            if (!u.firstName) errors.push('Missing firstName: record#' + recIdx);
            if (!u.lastName) errors.push('Missing firstName: record#' + recIdx);
            if (!u.fullName) errors.push('Missing fullName: record#' + recIdx);
            if (!u.company) errors.push('Missing company: record#' + recIdx);
            if (!u.companyName) errors.push('Missing companyName: record#' + recIdx);
            if (!u.roles) errors.push('Missing roles: record#' + recIdx);
            if (!u.type) errors.push('Missing type: record#' + recIdx);

            if (u.type === 'SHIPPER' && !this.validateShipper(u.company)) {
                errors.push('User ' + u.username + ' is assigned to invalid shipping company: ' + u.company);
            }
        });

        if (errors.length > 0) {
            const errorMessages = errors.map(e => { return {type: 'E', msg: e}});
            this.setState({messages: [
                    ...this.state.messages,
                    ...errorMessages,
                    {type: 'E', msg: 'Fix errors in file before uploading'}
                ]});
            return;
        }
        this.setState({validated: true});
    }

    validateInbox(data) {
        const errors = [];
        data.forEach((ib, idx) => {
            const recIdx = idx + 1;
            if (!this.validateShipper(ib.shipper)) {
                errors.push('Invalid shipper ID: record#' + recIdx + ' Shipper: ' + ib.shipper);
            }
            ib.tradeString.forEach(ts => {
                if (!this.validateShipper(ts.owner)) {
                    errors.push('Invalid trade string owner: record#' + recIdx + ' Owner: ' + ts.owner);
                }
            });
        });
        if (errors.length > 0) {
            const errorMessages = errors.map(e => { return {type: 'E', msg: e}});
            this.setState({messages: [
                    ...this.state.messages,
                    ...errorMessages,
                    {type: 'E', msg: 'Fix errors in file before uploading'}
                ]});
            return;
        }
        this.setState({validated: true});
        this.addMessage('I', 'Inbox Data Loaded: ' + data.length + ' records');

    }

    render() {
        const collection = this.state.fileType ? this.state.fileType.code : '';

        const replaceMsg = (
            <span>Are you sure you want to <span style={{fontWeight: 'bold', color: 'red'}}>DELETE</span><span> all data in collection </span>
                <span style={{fontWeight: 'bold'}}>{collection}</span>
                <span> and replace it with this file?</span>
            </span>);

        const appendMsg = (
            <span>Are you sure you want to append this data to collection <span style={{fontWeight: 'bold'}}>{collection}</span>?</span>
        );
        const msg = this.state.replace ? replaceMsg : appendMsg;

        return (
            <EssDocsPage title={'Load Reference Data'}>
                {this.state.confirmationPopupVisible && <ConfirmationPopup message={msg}
                                                                           onAccept={this.handlePopupConfirmation}
                                                                           onClose={() => this.setState({confirmationPopupVisible: false})}
                                                                           visible={this.state.confirmationPopupVisible}/>}
                <table>
                    <tbody>
                    <tr>
                        <td style={{width: '140px'}}>
                            <Dropdown optionLabel="name" value={this.state.fileType} options={fileTypes}
                                      style={{width: '180px'}}
                                      onChange={(e) => {this.setState({fileType: e.value})}} placeholder="Select a file Type"/>

                        </td>
                        <td style={{paddingTop: '2px', paddingLeft: "10px"}}>
                            <FileUpload chooseLabel={'Select JSON File'}
                                        disabled={!this.state.fileType}
                                        accept={'application/json'}
                                        ref={el => this.fileUpload=el}
                                        mode={'basic'}
                                        auto={true}
                                        customUpload={true}
                                        uploadHandler={this.handleSelectFile} />
                        </td>
                        <td style={{paddingLeft: "10px"}}>
                            <Checkbox inputId="replace" value="New York"
                                      onChange={e => this.setState({replace: e.checked})}
                                      checked={this.state.replace}></Checkbox>
                            <label style={{padding: "5px", fontSize: "15px"}} htmlFor="replace" className="checkbox-label">Replace</label>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{padding: "10px 0 0 3px"}}>
                    <Button disabled={!this.state.validated || this.state.uploading}
                            label={'UPLOAD'}
                            onClick={() => this.setState({confirmationPopupVisible: true})}/>
                </div>
                <LogDisplayer messages={this.state.messages}/>
            </EssDocsPage>
        );
    }
}

export default AdminDataLoad;

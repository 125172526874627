import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

class ConfirmationPopup extends Component {
    render() {

        const YesNoFooter = (
            <div style={{textAlign: 'right', marginRight: '2px'}}>
                <Button label="YES" icon="pi pi-check" onClick={this.props.onAccept} className="p-button-success" />
                <Button label="NO" icon="pi pi-times" onClick={this.props.onClose} className="p-button-secondary" />
            </div>
        );

        const ContinueCancelFooter = (
            <div style={{textAlign: 'right', marginRight: '2px'}}>
                <Button label="Continue" icon="pi pi-check" onClick={this.props.onAccept} className="p-button-success" />
                <Button label="Cancel" icon="pi pi-times" onClick={this.props.onClose} className="p-button-secondary" />
            </div>
        );

        const OkFooter = (
            <div style={{textAlign: 'right', marginRight: '2px'}}>
                <Button label="OK" icon="pi pi-check" onClick={this.props.onClose} className="p-button-success" />
            </div>
        );

        const footer = this.props.OkConfirm ? OkFooter : this.props.continueCancelFooter ? ContinueCancelFooter : YesNoFooter;

        return (
            <Dialog header={this.props.OkConfirm ? 'WARNING' : this.props.continueCancelFooter ? 'INFORMATION' : 'CONFIRMATION'}
                    visible={this.props.visible}
                    style={{width: '25vw'}}
                    footer={footer}
                    contentStyle={{overflow: 'visible', padding: "15px"}}
                    modal={true} onHide={this.props.onClose}>
                <span>{this.props.message}</span>
            </Dialog>
        );
    }
}

export default ConfirmationPopup;

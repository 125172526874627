import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Button} from "primereact/button";
import {EssDocsPage, InboxSearch} from "@essdocs/commonui";
import {createBargeDisplayers} from "../../displayers/BargeDisplayer";
import ServiceFactory from "../../services/ServiceFactory";
import {store} from "../../redux/Store";
import {
    appendSearchResults, collapseSideBar,
    invalidateSearchResults,
    searching,
    setSearchResults,
    updateBackToInbox,
    updateInboxBargeSelection,
    updateSearchArg,
    updateSortFields
} from "../../redux/Actions";
import InboxDataTable from "./InboxDataTable";
import './Inbox.css';
import CreateBargeDialog from "../../components/CreateBarge/CreateBargeDialog";
import {
    dateToServerFormat, formatNumber, serverDateToBrowserShortFormat, serverDateToDate
} from "@essdocs/ngfashared/shared/FormatUtils";
import {EssDocsComponent} from "@essdocs/commonui";
import OwnerSelectDialog from "../../components/OwnerSelectorDialog/OwnerSelectDialog";
import WorkflowService from "@essdocs/ngfashared/workflow/WorkflowService";
import {defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import ProposeDestinationDialog from "../../components/ProposeDestinationDialog/ProposeDestinationDialog";
import UploadDialog from "../../components/UploadDialog/UploadDialog";
import AddBolDialog from "../../components/AddBolDialog/AddBolDialog";
import {deepCopy} from "@essdocs/ngfashared/shared/CopyUtil";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {createCompleteTripMessage} from "../../shared/MessageUtil";
import {ProposeFeeDialog} from "../../components/ProposeFeeDialog/ProposeFeeDialog";
import {BolCorrectionDialog} from "../../components/BolCorrectionDialog/BolCorrectionDialog";
import {resetTimeout} from "../../shared/TimeoutUtil";
import {MultiSelect} from "primereact/multiselect";
import SearchOptionsDialog from "../../components/SearchOptionsDialog/SearchOptionsDialog";
import {getTradeStringEntryFromUuid} from "@essdocs/ngfashared/shared/BargeUtil";
import {withRouter} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";


class Inbox extends EssDocsComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            model: [],
            showAdvancedSearch: false,
            showHideShowPopup: false,
            showCreateBargeDialog: false,
            showSearchOptionsDialog: false,
            editableBargeRecord: {},
            bargeEditMode: false,
            selectedCounterparty: '',
            showSelectCounterPartyDialog: false,
            confirmationPopupVisible: false,
            confirmationAcceptAction: null,
            confirmationMessage: '',
            showProposeDestinationDialog: false,
            showUploadDialog: false,
            showAddBolDialog: false,
            showSelectFeeDialogVisible: false,
            okayMessageVisible: false,
            okayMessage: '',
            busy: this.props.searching,
            selectedAction: '',
            showBolCorrectionDialog: false,
            bolDateToFilter: '',
            appliedDateToFilter: '',
            commodityFilters: [],
            bargeIdFilter: '',
            selectedFieldsToShow: [],
            documentDetails: {},
            selectedTradeStringUuid: '',
            sortFields: [],
            searchOptionFilters: {},
            //selection: props.selectedBarge ? [props.selectedBarge] : null,
            //expandedRows: props.selectedBarge ? {[props.selectedBarge?.tradeStringUuid]: true} : {}

        };
        this.dataTableRef = React.createRef();
        this.wfService = new WorkflowService(defaultWorkflow);
        this.inboxService = ServiceFactory.instance().createInboxService();
        this.userService = ServiceFactory.instance().createUserService();
        this.refDataService = ServiceFactory.instance().createReferenceDataService();

        this.handleColumnResize = this.handleColumnResize.bind(this);
        this.handleColumnReorder = this.handleColumnReorder.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleHideShow = this.handleHideShow.bind(this);
        this.handleSaveHideShow = this.handleSaveHideShow.bind(this);
        this.handleAdvancedSearch = this.handleAdvancedSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRenderHeaderButtons = this.handleRenderHeaderButtons.bind(this);
        this.handleCreateBarge = this.handleCreateBarge.bind(this);
        this.handleSearchOptions = this.handleSearchOptions.bind(this);
        this.onUpdateSearchoptions = this.onUpdateSearchoptions.bind(this);
        this.handleUpdateNewBargeData = this.handleUpdateNewBargeData.bind(this);
        this.createNewBarge = this.createNewBarge.bind(this);
        this.actionHandler = this.actionHandler.bind(this);
        this.handleRowDoubleClick = this.handleRowDoubleClick.bind(this);
        this.handleSelectCounterparty = this.handleSelectCounterparty.bind(this);
        this.handleApplyOutAccept = this.handleApplyOutAccept.bind(this);
        this.handleApplyOutReject = this.handleApplyOutReject.bind(this);
        this.handleSelectDestination = this.handleSelectDestination.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleUploadDocument = this.handleUploadDocument.bind(this);
        this.handleAddBol = this.handleAddBol.bind(this);
        this.handleBolReqAccept = this.handleBolReqAccept.bind(this);
        this.handleBolReqReject = this.handleBolReqReject.bind(this);
        this.handleIssueSignBol = this.handleIssueSignBol.bind(this);
        this.handleEndorseAndTransfer = this.handleEndorseAndTransfer.bind(this);
        this.handleAcceptTransfer = this.handleAcceptTransfer.bind(this);
        this.handleCompleteTrip = this.handleCompleteTrip.bind(this);
        this.handleCarrierCompleteTrip = this.handleCarrierCompleteTrip.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleUpdateSearchValue = this.handleUpdateSearchValue.bind(this);
        this.reconsignmentTransfer = this.reconsignmentTransfer.bind(this);
        this.handleReconsignmentTransfer = this.handleReconsignmentTransfer.bind(this);
        this.requestRecon = this.requestRecon.bind(this);
        this.handleRequestRecon = this.handleRequestRecon.bind(this);
        this.reconReqAccept = this.reconReqAccept.bind(this);
        this.handleReconReqAccept = this.handleReconReqAccept.bind(this);
        this.reconReqReject = this.reconReqReject.bind(this);
        this.handleReconReqReject = this.handleReconReqReject.bind(this);
        this.reconTransferAccept = this.reconTransferAccept.bind(this);
        this.handleReconTransferAccept = this.handleReconTransferAccept.bind(this);
        this.proposeFee = this.proposeFee.bind(this);
        this.handleProposeFee = this.handleProposeFee.bind(this);
        this.handleAcceptFee = this.handleAcceptFee.bind(this);
        this.acceptFee = this.acceptFee.bind(this);
        this.handleRejectFee = this.handleRejectFee.bind(this);
        this.rejectFee = this.rejectFee.bind(this);
        this.handleEnterCorrection = this.handleEnterCorrection.bind(this);
        this.handleCorrectionReviewed = this.handleCorrectionReviewed.bind(this);
        this.handleBolReturn = this.handleBolReturn.bind(this);
        this.handleBolCorrectionAccept = this.handleBolCorrectionAccept.bind(this);
        this.handleBolCorrectionReject = this.handleBolCorrectionReject.bind(this);
        this.handleBolVoidRequest = this.handleBolVoidRequest.bind(this);
        this.handleBolVoidAccept = this.handleBolVoidAccept.bind(this);
        this.handleRemoveBarge = this.handleRemoveBarge.bind(this);
        this.onCommoditySearchChange = this.onCommoditySearchChange.bind(this);
        this.onBolDateSearchChange = this.onBolDateSearchChange.bind(this);
        this.onAppliedDateSearchChange = this.onAppliedDateSearchChange.bind(this);
        this.editBargeAction = this.editBargeAction.bind(this);
        this.handleSelectBarge = this.handleSelectBarge.bind(this);
    }

    async componentDidMount() {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        const currentUser = await this.userService.getCurrentlyAuthenticatedUser();/*
        //let commodityFilter = await this.getCommodityFilter();
        const bolDateFilter1 = <Calendar showOnFocus={true}
                                     readOnlyInput
                                     showButtonBar
                                     appendTo={document.body}
                                     value={this.state.bolDateToFilter}
                                     onChange={this.onBolDateSearchChange}
                                     dateFormat="mm/dd/y"
                                     monthNavigator
                                     yearNavigator
                                     yearRange="2020:2040"
                                     className="p-column-filter"
                                     placeholder={'MM/DD/YY'}/>;

        const appliedDateFilter = <Calendar
                                     readOnlyInput
                                     showButtonBar
                                     appendTo={document.body}
                                     value={this.state.appliedDateToFilter}
                                     onChange={this.onAppliedDateSearchChange}
                                     dateFormat="mm/dd/y"
                                     monthNavigator
                                     yearNavigator
                                     yearRange="2020:2040"
                                     className="p-column-filter"
                                     placeholder={'MM/DD/YY'}/>;*/

        let userPreferences = currentUser.preferences;
        let fieldPreferences = userPreferences && userPreferences.fieldsToShow;
        const model = [{
            field: 'bargeId',
            header: 'Barge ID',
            visible: (fieldPreferences && !fieldPreferences.includes('bargeId')) ? false : true,
            style: {width: '70px'},
            //otherProperties: {frozen: true}
        }, {
            field: 'shipperName',
            header: 'Shipper',
            visible: (fieldPreferences && !fieldPreferences.includes('shipperName')) ? false : true,
            style: {width: '75px'}
        }, {
            field: 'commodityName',
            header: 'Commodity',
            visible: (fieldPreferences && !fieldPreferences.includes('commodityName')) ? false : true,
            style: {width: '90px'},
            sortField: 'record.commodityName'
        }, {
            field: 'appliedFrom',
            header: 'Applied From',
            visible: (fieldPreferences && !fieldPreferences.includes('appliedFrom')) ? false : true,
            style: {width: '80px'}
        }, {
            field: 'applyDateShort',
            header: 'Applied Date',
            visible: (fieldPreferences && !fieldPreferences.includes('applyDateShort')) ? false : true,
            style: {width: '70px'},
            sortField: 'applyDate'
        }, {
            field: 'appliedTo',
            header: 'Applied To',
            visible: (fieldPreferences && !fieldPreferences.includes('appliedTo')) ? false : true,
            style: {width: '80px'}
        }, {
            field: 'blDateShort',
            header: 'BOL Date',
            visible: (fieldPreferences && !fieldPreferences.includes('blDateShort')) ? false : true,
            style: {width: '70px'},
            sortField: 'bolDate'
        }, {
            field: 'destination',
            header: 'Destination',
            visible: (fieldPreferences && !fieldPreferences.includes('destination')) ? false : true,
            style: {width: '90px'}
        }, {
            field: 'origin',
            header: 'Origin',
            visible: (fieldPreferences && !fieldPreferences.includes('origin')) ? false : true,
            style: {width: '70px'}
        }, {
            field: 'quantityAndUnit',
            header: 'Quantity',
            visible: (fieldPreferences && !fieldPreferences.includes('quantityAndUnit')) ? false : true,
            style: {width: '75px'},
            sortField: 'record.quantity'
        }, {
            field: 'carrierName',
            header: 'Carrier',
            visible: (fieldPreferences && !fieldPreferences.includes('carrierName')) ? false : true,
            style: {width: '75px'}
        }, {
            field: 'uploads',
            header: 'Uploads',
            visible: (fieldPreferences && !fieldPreferences.includes('uploads')) ? false : true,
            style: {width: '75px', textAlign: 'center'}
        }, {
            field: 'reconLastUpdatedShort',
            header: 'Recon Action (CST)',
            visible: (fieldPreferences && !fieldPreferences.includes('reconLastUpdatedShort')) ? false : true,
            style: {width: '70px'},
            sortField: 'reconLastUpdatedDate'
        }, {
            field: 'lastUpdatedShort',
            header: 'Barge Action (CST)',
            visible: (fieldPreferences && !fieldPreferences.includes('lastUpdatedShort')) ? false : true,
            style: {width: '70px'},
            sortField: 'lastUpdatedDate'
        }, {
            field: 'status',
            header: 'Status',
            visible: (fieldPreferences && !fieldPreferences.includes('status')) ? false : true,
            style: {width: '80px', color: 'coral'}
        }];


        const selectedCommodities = userPreferences && userPreferences.customFilters && userPreferences.customFilters.commodityFilters;

        const bargeIdFilter = userPreferences && userPreferences.customFilters && userPreferences.customFilters.bargeIdFilter;

        const bolDateFilter = userPreferences && userPreferences.customFilters && userPreferences.customFilters.bolDateFilter;

        let selectedRow = null;
        let data = createBargeDisplayers(this.props.user, this.props.searchResults, this.actionHandler, this.props.searchFilter, this.props.searchOptions, this.state.busy);
        if (this.props.selectedBarge) {
            let selectedBargeUuid = this.props.selectedBarge?.tradeStringUuid
            selectedRow = data.filter((item) => item?.tradeStringUuid === selectedBargeUuid);
        }

        this.setState({
            model: model,
            editableBargeRecord: this.createEmptyBarge(),
            searchOptionFilters: {
                commodityFilters: selectedCommodities ? selectedCommodities : [],
                bargeIdFilter: bargeIdFilter ? bargeIdFilter : '',
                bolDateFilter: bolDateFilter ? bolDateFilter : {}
            },
            selection: selectedRow,
            expandedRows: selectedRow ? {[selectedRow[0]?.tradeStringUuid]: true} : {},
            selectedFieldsToShow: fieldPreferences ? fieldPreferences : []
        });

        if (this.props.searchResultsInvalidated) {
            this.handleSearch();
        }

        this.destinations = await refDataService.readAll(ReferenceDataType.Destinations);
        resetTimeout();
    }

    componentDidUpdate(prevProps, prevState, curProps) {
        const tableBody = document.body.querySelector(".p-datatable-wrapper");
        let data = createBargeDisplayers(this.props.user, this.props.searchResults, this.actionHandler, this.props.searchFilter, this.props.searchOptions, this.state.busy);

        let selectedBargeUuid = this.props.selectedBarge?.tradeStringUuid
        const selectedRowIndex = data.findIndex((item) => item?.tradeStringUuid === selectedBargeUuid);
        if (selectedBargeUuid && selectedRowIndex !== -1) {
            if (tableBody) {
                const rowToScroll = tableBody.querySelectorAll('tr')[selectedRowIndex];
                if (rowToScroll) {
                    const elementRect = rowToScroll.getBoundingClientRect();
                    // Check if the element is not fully visible in the viewport
                    if (
                        elementRect.top < 0 || elementRect.bottom > window.innerHeight ||
                        elementRect.left < 0 || elementRect.right > window.innerWidth
                    ) {
                        rowToScroll.scrollIntoView();
                    }
                }
            }
        } else {
            if (tableBody) {
                const firstRow = tableBody.querySelectorAll('tr')[0];
                if (firstRow) firstRow.scrollTo({
                    top: 0,
                    behavior: 'smooth' // You can also use 'auto' for instant scrolling
                });
            }
        }
    }

    onCommoditySearchChange(event) {
        this.dataTableRef.handleFilter(event.value, 'commodityName', 'in');
        this.setState({commodityNames: event.value});
    }

    onBolDateSearchChange(event) {
        if (event.value === null) {
            this.dataTableRef.handleFilter('', 'blDateShort', 'in');
            this.setState({bolDateToFilter: ''});
        } else {
            let formattedBolDate = serverDateToBrowserShortFormat(event.value);
            this.dataTableRef.handleFilter(formattedBolDate, 'blDateShort', 'equals');
            this.setState({bolDateToFilter: formattedBolDate});
        }
    }

    onAppliedDateSearchChange(event) {
        if (event.value === null) {
            this.dataTableRef.handleFilter('', 'applyDateShort', 'in');
            this.setState({appliedDateToFilter: ''});
        } else {
            let formattedAppliedDate = serverDateToBrowserShortFormat(event.value);
            this.dataTableRef.handleFilter(formattedAppliedDate, 'applyDateShort', 'equals');
            this.setState({appliedDateToFilter: formattedAppliedDate});
        }
    }

    async getCommodityFilter() {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        let commodityNames = await refDataService.readSpecificFields(ReferenceDataType.Commodities, 'commodity,id');
        let filterObj = <MultiSelect style={{width: '100%'}}
                                     value={this.state.commodityNames}
                                     options={commodityNames}
                                     onChange={this.onCommoditySearchChange}
                                     selectedItemsLabel="{0} Filters selected"
                                     maxSelectedLabels={1}
                                     placeholder="Choose"
                                     filter
            //scrollHeight={'100px'}
                                     appendTo={document.body}
                                     className="ui-column-filter"/>;

        return filterObj;

    }

    handleSort(evt) {
        store.dispatch(updateSortFields(evt.multiSortMeta));
        this.setState({sortFields: evt.multiSortMeta});
    }

    handleCreateBarge() {
        this.setState({
            showCreateBargeDialog: true, bargeEditMode: false, editableBargeRecord: this.createEmptyBarge()
        });
        resetTimeout();
    }

    async handleSearchOptions() {
        let commodityNames = await this.refDataService.readSpecificFields(ReferenceDataType.Commodities, 'commodity,id');
        this.setState({showSearchOptionsDialog: true, commodityFilterOptions: commodityNames});
        resetTimeout();
    }

    async onUpdateSearchoptions(searchOptionFilters) {
        this.setState({showSearchOptionsDialog: false, searchOptionFilters: searchOptionFilters});
        await this.refreshInbox();
        resetTimeout();
    }

    async createNewBarge() {
        store.dispatch(searching(true));
        let barge = this.state.editableBargeRecord;
        const quantityUnformatted = barge.quantity ? barge.quantity.split(',').join('') : '0';
        let quantity = parseFloat(quantityUnformatted);

        if (isNaN(quantity)) {
            quantity = 0;
        }
        const bargeFields = {
            blDate: dateToServerFormat(barge.blDate),
            bargeId: barge.bargeId,
            shipper: barge.shipper,
            shipperName: barge.shipperName,
            carrier: barge.carrier,
            carrierName: barge.carrierName,
            commodity: barge.commodity,
            commodityName: barge.commodityName,
            origin: barge.origin
        };
        bargeFields.quantity = quantity;
        bargeFields.quantityUnit = barge.quantityUnit;
        bargeFields.quantityUnitAbbr = barge.quantityUnitAbbr;

        this.setState({showCreateBargeDialog: false});

        const inboxService = ServiceFactory.instance().createInboxService();
        if (this.state.bargeEditMode) {
            await inboxService.updateBarge(this.state.selectedAction, barge.uuid, this.state.selectedTradeStringUuid, bargeFields);
        } else {
            await inboxService.createBarge(bargeFields);
        }
        const result = await inboxService.search(this.props.searchFilter, 1, this.props.searchArg,
            this.props.searchOptions, this.props.searchDocumentTypes);

        store.dispatch(setSearchResults(result.data, this.props.searchArg, result.loadMoreData));
        resetTimeout();
    }

    createEmptyBarge() {
        return {
            bargeId: '',
            statusCode: 'CREATED',
            status: defaultWorkflow['CREATED'].statusDesc,
            commodity: null,
            blDate: new Date(),
            shipper: this.props.user.company,
            curOwners: [this.props.user.company],
            shipperName: this.props.user.companyName,
            origin: '',
            carrier: '',
            quantity: '',
            quantityUnit: '',
            reconsignment: {destination: '', curOwner: this.props.user.company},
            tradeString: [{
                owner: this.props.user.company,
                ownerName: this.props.user.companyName,
                applyDate: dateToServerFormat(new Date())
            }]
        };
    }

    handleUpdateSearchValue(searchValue) {
        store.dispatch(updateSearchArg(searchValue));
    }

    handleUpdateNewBargeData(update) {
        this.setState({editableBargeRecord: {...this.state.editableBargeRecord, ...update}});
    }

    handleRenderHeaderButtons() {
        const canCreateBarge = this.wfService.canCreateBarge(this.props.user);
        return (<Fragment>
            <Button label={'Search Options'}
                    onClick={this.handleSearchOptions}
                    className='p-button-primary'
                    icon={'fa fa-sliders'}/>
            {canCreateBarge && <Button label={'CREATE BARGE'}
                                       onClick={this.handleCreateBarge}
                                       className='p-button-success p-button-raised'
                                       icon={'fa fa-plus'}/>}
        </Fragment>);
    }

    async handleSearch() {
        const inboxService = ServiceFactory.instance().createInboxService();
        store.dispatch(searching(true));
        const result = await inboxService.search(this.props.searchFilter, 1, this.props.searchArg,
            this.props.searchOptions, this.props.searchDocumentTypes);
        store.dispatch(setSearchResults(result.data, this.props.searchArg, result.loadMoreData));
        this.updateDisplayerDetails(result);
    }

    async refreshInbox() {
        if (this.props.searching) return;
        const inboxService = ServiceFactory.instance().createInboxService();
        store.dispatch(searching(true));
        const result = await inboxService.search(this.props.searchFilter, 1, this.props.searchArg,
            this.props.searchOptions, this.props.searchDocumentTypes);

        //To retain the user where they were before
        this.updateDisplayerDetails(result);
    }

    updateDisplayerDetails(result) {
        let data = createBargeDisplayers(this.props.user, result.data, this.actionHandler, this.props.searchFilter, this.props.searchOptions, false);

        store.dispatch(setSearchResults(result.data, this.props.searchArg, result.loadMoreData));
        let selectedRow = null;
        if (this.props.selectedBarge) {
            let selectedBargeUuid = this.props.selectedBarge?.tradeStringUuid
            selectedRow = data.filter((item) => item.tradeStringUuid === selectedBargeUuid);
        }
        this.setState({
            busy: false, selection: selectedRow,
            expandedRows: selectedRow ? {[selectedRow[0]?.tradeStringUuid]: true} : {}
        });
    }

    handleAdvancedSearch() {
        this.setState({showAdvancedSearch: !this.state.showAdvancedSearch});
    }

    handleHideShow(col, visible) {
        const newModel = this.state.model.map(c => {
            if (c === col) {
                c.visible = visible;
            }
            return c;
        });
        this.setState({model: newModel});
    }

    handleSaveHideShow(evt) {
        const hideShowProperties = this.state.model.filter(res => res.visible).map(ele => ele.field);
        let difference = hideShowProperties
            .filter(x => !this.state.selectedFieldsToShow.includes(x))
            .concat(this.state.selectedFieldsToShow.filter(x => !hideShowProperties.includes(x)));

        if (difference.length > 0) {
            this.userService.updateUserProperties({hideShowProperties: hideShowProperties});
        }

        this.setState({showHideShowPopup: false, selectedFieldsToShow: hideShowProperties});
    }

    handleColumnResize(field, offsetWidth) {
        let newModel = [];

        newModel = this.state.model.map(m => {
            if (m.field === field) {
                return {
                    ...m, style: {
                        ...m.style,
                        width: `${offsetWidth}px`,
                    }
                };
            }
            return m;
        });
        this.setState({model: newModel});
    }

    handleColumnReorder(dragIdx, dropIndex) {
        const newModel = [...this.state.model];
        const row = newModel[dragIdx];
        newModel.splice(dragIdx, 1);
        newModel.splice(dropIndex, 0, row);
        this.setState({model: newModel});
    }

    actionHandler(id, selection) {
        switch (id) {
            case 'editBarge':
            case 'carrierEditBarge':
                this.editBargeAction(selection, id);
                break;
            case 'removeBarge':
                this.removeBarge(selection);
                break;
            case 'requestBol':
                this.requestBolAction(selection);
                break;
            case 'BARGEINFO':
                this.bargeInfo(selection);
                break;
            case 'applyOut':
            case 'bustedApplyOut':
            case 'updatedApplyOut':
                this.applyOutAction(selection, id);
                break;
            case 'appliedOutAccept':
                this.applyOutAccept(selection);
                break;
            case 'appliedOutReject':
                this.applyOutReject(selection);
                break;
            case 'proposeDest':
            case 'bustedProposeDest':
                this.proposeDestination(selection, id);
                break;
            case 'upload':
                this.upload(selection);
                break;
            case 'bolReqAccept':
                this.bolReqAccept(selection);
                break;
            case 'bolReqReject':
                this.bolReqReject(selection);
                break;
            case 'issueSignBol':
                this.issueSignBol(selection);
                break;
            case 'endorseTransferBol':
                this.endorseAndTransfer(selection);
                break;
            case 'acceptTransfer':
                this.acceptTransfer(selection);
                break;
            case 'completeTrip':
                this.completeTrip(selection);
                break;
            case 'carrierCompleteTrip':
                this.carrierCompleteTrip(selection);
                break;
            case 'reconTransfer':
                this.reconsignmentTransfer(selection);
                break;
            case 'requestRecon':
                this.requestRecon(selection);
                break;
            case 'reconReqAccept':
                this.reconReqAccept(selection);
                break;
            case 'reconReqReject':
                this.reconReqReject(selection);
                break;
            case 'reconTransferAccept':
                this.reconTransferAccept(selection);
                break;
            case 'proposeFee':
                this.proposeFee(selection);
                break;
            case 'acceptFee':
                this.acceptFee(selection);
                break;
            case 'rejectFee':
                this.rejectFee(selection);
                break;
            case 'bolCorrection':
                this.bolCorrectionRequest(selection);
                break;
            case 'bolCorrectionReviewed':
                this.bolCorrectionReviewed(selection);
                break;
            case 'bustedReturnBol':
                this.bolReturn(selection);
                break;
            case 'bolCorrectionAccept':
                this.bolCorrectionAccept(selection);
                break;
            case 'bolCorrectionReject':
                this.bolCorrectionReject(selection);
                break;
            case 'bolVoidRequest':
                this.bolVoidRequest(selection);
                break;
            case 'bolVoidAccept':
                this.bolVoidAccept(selection);
                break;
            default:
                console.log('Action Handler:' + id);
                console.log(selection);
        }
    }

    bolCorrectionReviewed(selection) {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleCorrectionReviewed,
            confirmationMessage: 'Are you sure you want to mark this BOL correction request as reviewed?'
        });
    }

    bolReturn(selection) {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleBolReturn,
            confirmationMessage: 'Are you sure you want to return this BOL to the previous party in the trade string?'
        });
    }

    bolCorrectionAccept(selection) {

        const selectedBarge = selection[0];
        const canAttach = this.wfService.canAttachDocument(this.props.user, selectedBarge.record, 'bolcorrection', 'bolcorrection', selectedBarge.tradeStringUuid);
        if (canAttach) {
            this.setState({
                okayMessageVisible: true,
                okayMessage: 'A BOL correction document must be uploaded before the BOL correction request can be accepted. Please note to upload BOL Correction after Busting please click \'Upload\' and pick \'BOL Correction Requested\' type'
            });
            return;
        }

        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleBolCorrectionAccept,
            confirmationMessage: 'Are you sure you want to accept this BOL correction?'
        });
    }

    bolCorrectionReject(selection) {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleBolCorrectionReject,
            confirmationMessage: 'Are you sure you want to reject this BOL correction?'
        });
    }

    bolVoidRequest(selection) {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleBolVoidRequest,
            confirmationMessage: 'Are you sure you want to submit a request to void this BOL?'
        });
    }

    bolVoidAccept(selection) {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleBolVoidAccept,
            confirmationMessage: 'Are you sure you want to accept this BOL void request?'
        });
    }

    handleCorrectionReviewed() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolCorrectionReviewed', {});
    }

    handleBolReturn() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bustedReturnBol', {});
    }

    handleBolCorrectionAccept() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolCorrectionAccept', {});
    }

    handleBolCorrectionReject() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolCorrectionReject', {});
    }

    handleBolVoidRequest() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolVoidRequest', {});
    }

    handleBolVoidAccept() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolVoidAccept', {});
    }

    bolCorrectionRequest(selection) {
        this.setState({showBolCorrectionDialog: true, selection: selection});
    }

    handleEnterCorrection(correctionText) {
        this.setState({showBolCorrectionDialog: false});
        this.executeAction('bolCorrection', {correction: correctionText});
    }

    reconsignmentTransfer(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleReconsignmentTransfer,
            confirmationMessage: 'Are you sure you want to transfer this reconsignment?'
        });
    }

    handleReconsignmentTransfer() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconTransfer', {});
    }

    requestRecon(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleRequestRecon,
            confirmationMessage: 'Are you sure you want to submit a reconsignment request?'
        });
    }

    handleRequestRecon() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('requestRecon', {});
    }

    reconTransferAccept(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleReconTransferAccept,
            confirmationMessage: 'Are you sure you want to accept the transfer of this reconsignment?'
        });
    }


    handleReconTransferAccept() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconTransferAccept', {});
    }

    proposeFee(selection) {
        this.setState({
            showSelectFeeDialogVisible: true, selection: selection
        });
    }

    handleProposeFee(fee) {
        this.setState({showSelectFeeDialogVisible: false});
        this.executeAction('proposeFee', {fee: fee});
    }

    acceptFee(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleAcceptFee,
            confirmationMessage: 'Are you sure you want to accept the reconsignment fee?'
        });
    }

    handleAcceptFee() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('acceptFee', {});
    }

    rejectFee(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleRejectFee,
            confirmationMessage: 'Are you sure you want to reject the reconsignment fee?'
        });
    }

    handleRejectFee() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('rejectFee', {});
    }

    reconReqAccept(selection) {
        if (selection.length !== 1) return;
        const selectedBarge = selection[0];
        /*const canAttach = this.wfService.canAttachDocument(this.props.user, selectedBarge.record,
            'reconsignment', 'reconignment', selectedBarge.tradeStringUuid);*/
        const ts = getTradeStringEntryFromUuid(selectedBarge.tradeString, selectedBarge.tradeStringUuid);
        const existingDocument = selectedBarge.record.documents && selectedBarge.record.documents.find(d => d.docType === 'reconsignment' && d.reconsignment === ts.reconsignment);
        if (!existingDocument) {
            this.setState({
                okayMessageVisible: true,
                okayMessage: 'A reconsignment document must be uploaded before the destination can be accepted'
            });
            return;
        }

        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleReconReqAccept,
            confirmationMessage: 'Are you sure you want to accept the destination for this reconsignment?'
        });
    }

    handleReconReqAccept() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconReqAccept', {});
    }

    reconReqReject(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleReconReqReject,
            confirmationMessage: 'Are you sure you want to reject the destination for this reconsignment?'
        });
    }

    handleReconReqReject() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconReqReject', {});
    }

    applyOutAction(selection, actionId) {
        this.setState({showSelectCounterPartyDialog: true, selection: selection, selectedAction: actionId});
    }

    upload(selection) {
        this.setState({showUploadDialog: true, selection: selection});
    }

    removeBarge(selection) {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleRemoveBarge,
            confirmationMessage: (<span>Are you sure you want to <strong style={{color: 'red'}}>DISCARD </strong>
                {selection.length} barge{selection.length > 1 ? 's' : ''}?</span>)
        });
    }

    handleRemoveBarge() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('removeBarge', {});
    }

    async handleUploadDocument(docType, subType, docName, filename, fileb64) {
        this.setState({confirmationPopupVisible: false});
        const barge = this.state.selection[0];
        const documentService = ServiceFactory.instance().createDocumentService();
        const startTime = new Date().getTime();
        store.dispatch(searching(true, 'Uploading Document'));
        if (!docType || !subType || !fileb64) {
            docType = this.state.documentDetails.docType;
            subType = this.state.documentDetails.subType;
            fileb64 = this.state.documentDetails.fileb64;
        }
        const docUuid = await documentService.uploadDocument(barge.record.uuid, barge.tradeStringUuid, docType, subType, fileb64, barge.reconsignment);
        const interval = new Date().getTime() - startTime;
        console.log('Document Upload Time: ' + interval + 'ms');
        store.dispatch(searching(false));
        //alert('Document Upload Time:' + interval + 'ms');

        store.dispatch(invalidateSearchResults());
        store.dispatch(updateInboxBargeSelection(barge));
        store.dispatch(updateBackToInbox(false));
        store.dispatch(collapseSideBar(true));
        this.nav('/bargeinfo/' + barge.record.uuid + '/' + barge.tradeStringUuid + '?docid=' + docUuid);
    }

    async handleUploadFile(docType, subType, docName, filename, fileb64) {
        this.setState({showUploadDialog: false});
        const barge = this.state.selection[0];
        let ts = barge.tradeString.find(ts => ts.uuid === barge.tradeStringUuid);
        if (docType === 'reconsignment' && barge.record.documents && barge.record.documents.some(d => d.docType === 'reconsignment' && d.reconsignment === ts.reconsignment)) {
            this.setState({
                documentDetails: {
                    docType: docType, subType: subType, docName: docName, filename: filename, fileb64: fileb64
                },
                confirmationPopupVisible: true,
                confirmationAcceptAction: this.handleUploadDocument,
                confirmationMessage: 'Are you sure you want to overwrite existing Reconsignment document?'
            });
        } else {
            await this.handleUploadDocument(docType, subType, docName, filename, fileb64);
        }
    }

    proposeDestination(selection, actionId) {
        this.setState({showProposeDestinationDialog: true, selection: selection, selectedAction: actionId});
    }

    bolReqAccept(selection) {
        if (selection.length === 1) {
            const barge = selection[0];
            this.setState({
                confirmationPopupVisible: true,
                selection: selection,
                confirmationAcceptAction: this.handleBolReqAccept,
                confirmationMessage: (<span>Are you sure you want to <strong style={{color: 'green'}}>Accept
                </strong> the proposed destination of <strong>{barge.destination}</strong>?</span>)
            });
        }
    }

    issueSignBol(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleIssueSignBol,
            confirmationMessage: 'Are you sure you want to issue and sign the BOL?'
        });
    }

    endorseAndTransfer(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleEndorseAndTransfer,
            confirmationMessage: 'Are you sure you want to endorse and transfer this BOL?'
        });
    }

    acceptTransfer(selection) {
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleAcceptTransfer,
            confirmationMessage: 'Are you sure you want to accept the transfer of this BOL?'
        });
    }

    completeTrip(selection) {
        const msg = createCompleteTripMessage(selection[0].record);
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleCompleteTrip,
            confirmationMessage: msg
        });
    }

    carrierCompleteTrip(selection) {
        const msg = createCompleteTripMessage(selection[0].record);
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleCarrierCompleteTrip,
            confirmationMessage: msg
        });
    }

    bolReqReject(selection) {
        if (selection.length === 1) {
            const barge = selection[0];
            this.setState({
                confirmationPopupVisible: true,
                selection: selection,
                confirmationAcceptAction: this.handleBolReqReject,
                confirmationMessage: (<span>Are you sure you want to <strong style={{color: 'red'}}>Reject
                    </strong> the proposed destination of <strong>{barge.destination}</strong>?</span>)
            });
        }
    }

    applyOutAccept(selection) {
        let msg = `Are you sure you want to Accept ${selection.length} barge`;
        if (selection.length > 1) msg += 's';
        msg += '?';

        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleApplyOutAccept,
            confirmationMessage: msg
        });
    }

    applyOutReject(selection) {
        let msg = `Are you sure you want to Reject ${selection.length} barge`;
        if (selection.length > 1) msg += 's';
        msg += '?';

        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleApplyOutReject,
            confirmationMessage: msg
        });
    }

    getSelectedBargeRecords() {
        return this.state.selection.map(s => {
            return {...s.record}
        });
    }


    async executeAction(action, actionParams = {}, runSearch = true) {
        if (this.state.busy) return;
        this.setState({busy: true});
        const bargeIdList = this.state.selection.map(s => {
            return {uuid: s.record.uuid, tradeStringUuid: s.tradeStringUuid}
        });
        await this.inboxService.executeActionAndUpdateBarges(bargeIdList, action, actionParams);
        resetTimeout();
        if (runSearch) this.refreshInbox();
    }

    handleApplyOutAccept() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('appliedOutAccept', {});
    }

    handleApplyOutReject() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('appliedOutReject', {});
    }

    handleSelectCounterparty(shipper) {
        this.setState({showSelectCounterPartyDialog: false});
        this.executeAction(this.state.selectedAction, {shipperCode: shipper.id, shipperName: shipper.shipperName});
    }


    handleSelectDestination(actionParams) {
        this.setState({showProposeDestinationDialog: false});
        this.executeAction(this.state.selectedAction, actionParams);
    }

    async handleBolReqAccept() {
        this.setState({confirmationPopupVisible: false});
        const selection = this.state.selection[0];
        await this.executeAction('bolReqAccept', {}, false);
        const {user} = this.props;
        const findResponse = await this.inboxService.findBarge(selection.record.uuid, true, user);
        const barge = findResponse.barge;
        const bolDoc = barge.documents.find(d => d.docType === 'BOL');
        if (bolDoc) {
            store.dispatch(updateInboxBargeSelection(selection));
            store.dispatch(updateBackToInbox(false));
            store.dispatch(collapseSideBar(true));
            this.nav('/bargeinfo/' + selection.record.uuid + '/' + selection.tradeStringUuid + '?docid=' + bolDoc.uuid);
        }
        store.dispatch(invalidateSearchResults());
    }

    handleBolReqReject() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolReqReject', {});
    }

    handleIssueSignBol() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('issueSignBol', {});
    }

    async handleEndorseAndTransfer() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('endorseTransferBol', {});
    }

    handleAddBol(formFields) {
        this.setState({showAddBolDialog: false});
//        const bargeRecs = [this.state.editableBargeRecord.record];
        this.executeAction('requestBol', formFields);
    }

    handleAcceptTransfer() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('acceptTransfer', {});
    }

    handleCompleteTrip() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('completeTrip', {});
    }

    handleCarrierCompleteTrip() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('carrierCompleteTrip', {});
    }

    editBargeAction(selection, actionId) {
        if (selection.length === 1) {
            this.handleSearch();
            this.setState({selectedAction: actionId});
            this.doEditBarge(selection[0]);
        }
    }

    handleSelectBarge(evt) {
        store.dispatch(updateBackToInbox(false));
        this.setState({selection: evt.selection, expandedRows: evt.expandedRows});
        store.dispatch(updateInboxBargeSelection(evt.selection[0]));
    }

    async doEditBarge(selectedBarge) {
        this.setState({busy: true});
        const response = await this.inboxService.findBarge(selectedBarge.record.uuid, selectedBarge.tradeStringUuid, this.props.user);
        const barge = response.barge;
        const rec = deepCopy(barge);
        rec.blDate = serverDateToDate(rec.blDate);
        rec.quantity = rec.quantity && rec.quantity !== 0 ? formatNumber(rec.quantity, 3) : '';
        this.setState({
            busy: false,
            selectedTradeStringUuid: selectedBarge.tradeStringUuid,
            editableBargeRecord: rec,
            bargeEditMode: true,
            showCreateBargeDialog: true
        });
    }

    async requestBolAction(selection) {
        const barge = selection[0];
        this.setState({editableBargeRecord: barge, selection: selection, showAddBolDialog: true});
        await this.refreshInbox();
    }

    bargeInfo(selection) {
        if (selection.length === 1) {
            store.dispatch(updateInboxBargeSelection(selection[0]));
            store.dispatch(updateBackToInbox(false));
            store.dispatch(collapseSideBar(true));
            this.nav(`/bargeinfo/${selection[0].record.uuid}/${selection[0].tradeStringUuid}`);
        }
    }

    handleRowDoubleClick(evt) {
        let {index, data} = evt;
        if (['CREATED', 'BARGEINFOUPDATED'].includes(data.record.statusCode) && data.record.tradeString && data.record.tradeString.length === 1) {
            this.doEditBarge(data);
        } else {
            store.dispatch(updateInboxBargeSelection(data));
            store.dispatch(updateBackToInbox(false));
            store.dispatch(collapseSideBar(true));
            this.nav(`/bargeinfo/${data.record.uuid}/${data.tradeStringUuid}`);
        }
    }

    async handleLoadMore() {
        if (this.props.searching) return;
        const nextPageNbr = this.props.pageNumber + 1;
        store.dispatch(searching(true));
        const result = await this.inboxService.search(this.props.searchFilter, nextPageNbr, this.props.searchArg, this.props.searchOptions, this.props.searchDocumentTypes);
        store.dispatch(appendSearchResults(result.data, result.loadMoreData, nextPageNbr));
    }

    render() {
        const {
            model,
            bargeEditMode,
            showSelectCounterPartyDialog,
            showCreateBargeDialog,
            showSearchOptionsDialog,
            selectedCounterparty,
            showProposeDestinationDialog,
            showUploadDialog,
            showSelectFeeDialogVisible,
            selection,
            showAddBolDialog,
            confirmationPopupVisible,
            okayMessageVisible,
            okayMessage,
            busy,
            showBolCorrectionDialog,
            searchOptionFilters,
            expandedRows
        } = this.state;

        let data = createBargeDisplayers(this.props.user, this.props.searchResults, this.actionHandler, this.props.searchFilter, this.props.searchOptions, busy);
        const selectedBarge = selection && selection.length === 1 ? data.find(barge => barge.tradeStringUuid === selection[0].tradeStringUuid) : null;
        const updatedBargeDetails = data.filter(obj1 => {
            return selection?.some(obj2 => obj2.tradeStringUuid === obj1.tradeStringUuid);
        });

        //const selectedCommodityFilters = searchOptionFilters && searchOptionFilters.commodityFilters ? (searchOptionFilters.commodityFilters.length > 5 ? searchOptionFilters.commodityFilters.length : searchOptionFilters.commodityFilters.join()) : [];

        const filtersApplied = searchOptionFilters.bargeIdFilter || (searchOptionFilters.commodityFilters && searchOptionFilters.commodityFilters.length > 0) || searchOptionFilters.bolDateFilter && searchOptionFilters.bolDateFilter.fromBolDate;

        return (<EssDocsPage title={'BDT Solution'} classname={'EssDocsPageTitleContainer'}>
            <div style={{position: 'absolute', top: '50px', left: '0', right: '0', bottom: '0'}}>
                {showCreateBargeDialog && <CreateBargeDialog visible={showCreateBargeDialog}
                                                             data={this.state.editableBargeRecord}
                                                             onUpdateData={this.handleUpdateNewBargeData}
                                                             editMode={bargeEditMode}
                                                             actionId={this.state.selectedAction}
                                                             onCreateBarge={this.createNewBarge}
                                                             loggedInUser={this.props.user}
                                                             onHide={() => this.setState({
                                                                 busy: false,
                                                                 showCreateBargeDialog: false
                                                             })}/>}
                {showSearchOptionsDialog && <SearchOptionsDialog visible={showSearchOptionsDialog}
                                                                 searchOptionFilters={searchOptionFilters}
                    //selectedCommodities={this.state.commodityFilters}
                                                                 commodityFilterOptions={this.state.commodityFilterOptions}
                                                                 user={this.props.user}
                                                                 onUpdateSearchoptions={this.onUpdateSearchoptions}
                                                                 onHide={() => this.setState({showSearchOptionsDialog: false})}/>}
                {showAddBolDialog && <AddBolDialog visible={showAddBolDialog}
                                                   barge={selectedBarge}
                                                   onAddBol={this.handleAddBol}
                                                   onHide={() => this.setState({showAddBolDialog: false})}/>}
                {okayMessageVisible && <ConfirmationPopup message={okayMessage}
                                                          OkConfirm={true}
                                                          onClose={() => this.setState({okayMessageVisible: false})}
                                                          visible={okayMessageVisible}/>}
                {confirmationPopupVisible && <ConfirmationPopup message={this.state.confirmationMessage}
                                                                onAccept={this.state.confirmationAcceptAction}
                                                                onClose={() => this.setState({confirmationPopupVisible: false})}
                                                                visible={confirmationPopupVisible}/>}
                {showSelectCounterPartyDialog && <OwnerSelectDialog visible={showSelectCounterPartyDialog}
                                                                    counterparty={selectedCounterparty}
                                                                    selectionCount={selection.length}
                                                                    onSelectCounterparty={this.handleSelectCounterparty}
                                                                    onHide={() => this.setState({showSelectCounterPartyDialog: false})}
                                                                    onChange={(val) => this.setState({selectedCounterparty: val})}/>}

                {showProposeDestinationDialog && <ProposeDestinationDialog visible={showProposeDestinationDialog}
                                                                           barge={selectedBarge ? selectedBarge.record : ''}
                                                                           user={this.props.user}
                                                                           tradeStringUuid={selectedBarge ? selectedBarge.tradeStringUuid : ''}
                                                                           selectionCount={selection.length}
                                                                           destinations={this.destinations}
                                                                           onSelectDestination={this.handleSelectDestination}
                                                                           onHide={() => this.setState({showProposeDestinationDialog: false})}/>}
                {showSelectFeeDialogVisible && <ProposeFeeDialog visible={showSelectFeeDialogVisible}
                                                                 onSelectFee={this.handleProposeFee}
                                                                 onHide={() => this.setState({showSelectFeeDialogVisible: false})}/>}
                {showBolCorrectionDialog && <BolCorrectionDialog
                    visible={showBolCorrectionDialog}
                    onHide={() => this.setState({showBolCorrectionDialog: false})}
                    onEnterCorrection={this.handleEnterCorrection}/>}
                {showUploadDialog && selectedBarge && <UploadDialog visible={showUploadDialog}
                                                                    canUploadWeightCert={this.wfService.canUploadWeightCertificate(this.props.user, selectedBarge.record)}
                                                                    user={this.props.user}
                                                                    barge={selectedBarge.record}
                                                                    tradeStringUuid={selectedBarge.tradeStringUuid}
                                                                    onUploadFile={this.handleUploadFile}
                                                                    onHide={() => this.setState({showUploadDialog: false})}/>}

                <InboxSearch advancedSearchVisible={this.state.showAdvancedSearch}
                             onRenderHeaderButtons={this.handleRenderHeaderButtons}
                             onSearch={this.handleSearch}
                             searchValue={this.props.searchArg ? this.props.searchArg : ''}
                             onSearchValueChange={this.handleUpdateSearchValue}
                             showSearchSpinner={this.props.searching || this.state.busy}
                             searchSpinnerMessage={this.props.searchMessage}
                             onShowHideShowPopup={() => this.setState({showHideShowPopup: true})}
                             onShowAdvancedSearch={this.handleAdvancedSearch}/>
                <div className={'InboxBodyContainer'}>
                    {filtersApplied !== undefined && filtersApplied && <div>
                        <i style={{marginRight: '3px', color: '#444'}} className={'fa fa-filter'}/>
                        <span style={{fontSize: '12px', fontWeight: 'bold'}}>
                                Filters Applied</span>
                    </div>}
                    {/*{ selectedCommodityFilters &&
                        <div>
                            <span style={{fontSize: '12px', fontWeight: 'bold', fontFamily: 'Open Sans'}}>
                                Commodities Filtered : {selectedCommodityFilters}</span>
                        </div>}
                        {searchOptionFilters && searchOptionFilters.bargeIdFilter &&
                        <div>
                            <span style={{fontSize: '12px', fontWeight: 'bold', fontFamily: 'Open Sans'}}>
                                Barge Id Filtered : {searchOptionFilters.bargeIdFilter}</span>
                        </div>}
                        {searchOptionFilters && searchOptionFilters.bolDateFilter &&
                        <div>
                            <span style={{fontSize: '12px', fontWeight: 'bold', fontFamily: 'Open Sans'}}>
                                BLDate Range Filtered : From - {serverDateToBrowserShortFormat(searchOptionFilters.bolDateFilter.fromBolDate)} , ToDate - {serverDateToBrowserShortFormat(searchOptionFilters.bolDateFilter.toBolDate)}</span>
                        </div>}*/}

                    <InboxDataTable
                        dataTableRef={this.dataTableRef}
                        data={data}
                        dataKey={"tradeStringUuid"}
                        model={model}
                        selection={updatedBargeDetails}
                        expandedRows={expandedRows}
                        sortFields={this.props.sortFields ? this.props.sortFields : this.state.sortFields}
                        hasMore={this.props.loadMoreData}
                        onLoadMore={this.handleLoadMore}
                        onSort={this.handleSort}
                        onHideShow={this.handleHideShow}
                        onCloseHideShowPopup={this.handleSaveHideShow}
                        onColumnResize={this.handleColumnResize}
                        onSelectionChange={this.handleSelectBarge}
                        onRowDoubleClick={this.handleRowDoubleClick}
                        showHideShowPopup={this.state.showHideShowPopup}
                        onColumnReorder={this.handleColumnReorder}/>
                </div>
            </div>
        </EssDocsPage>);
    }
}

const mapStateToProps = state => {
    return {
        searchResults: state.inbox.searchResults,
        loadMoreData: state.inbox.loadMoreData,
        pageNumber: state.inbox.pageNumber,
        searching: state.inbox.searching,
        searchMessage: state.inbox.searchMessage,
        searchResultsInvalidated: state.inbox.searchResultsInvalidated,
        searchArg: state.inbox.searchArg,
        sortFields: state.inbox.sortFields,
        searchFilter: state.inbox.filter,
        searchOptions: state.inbox.options,
        searchDocumentTypes: state.inbox.documentTypes,
        selectedBarge: state.inbox.selectedBarge,
        backToInbox: state.inbox.backToInbox,
        user: state.user.authenticated
    }
};

export default connect(mapStateToProps)(withRouter(Inbox));

import {store} from "../redux/Store";
import LocalInboxService from "./local/LocalInboxService";
import LocalDocumentService from "./local/LocalDocumentService";
import LocalMessageService from "./local/LocalMessagesService";
import LocalReferenceDataService from "./local/LocalReferenceDataService";
import UserService from "./UserService";
import MessageService from "./MessageService";
import DocumentService from "./DocumentService";
import InboxService from "./InboxService";
import ReferenceDataService from "./ReferenceDataService";
import LocalUserService from "./local/LocalUserService";

class LocalServiceFactory {
    createInboxService() { return new LocalInboxService(); }
    createDocumentService() { return new LocalDocumentService(); }
    createMessageService() { return new LocalMessageService(); }
    createReferenceDataService() { return new LocalReferenceDataService(); }
    createUserService() { return new LocalUserService(); }
}

class ServerServiceFactory {
    createUserService() { return new UserService(); }
    createInboxService() { return new InboxService(); }
    createMessageService() { return new MessageService(); }
    createReferenceDataService() { return new ReferenceDataService(); }
    createDocumentService() { return new DocumentService(); }
}

const localServiceFactory = new LocalServiceFactory();
const serverServiceFactory = new ServerServiceFactory();

export default class ServiceFactory {
    static instance() {
        return store.getState().user.serverMode ? serverServiceFactory : localServiceFactory;
    }
}


import React from 'react';
import {EssDocsComponent, EssDocsPage, FieldLabel, ErrorMessages, Form} from "@essdocs/commonui";
import {Messages} from "primereact/messages";
import {Fieldset} from "primereact/fieldset";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import ServiceFactory from "../../services/ServiceFactory";
import {Checkbox} from "primereact/checkbox";

import './UserManagement.css';
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import {SelectButton} from "primereact/selectbutton";
import {Card} from "primereact/card";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {API} from "aws-amplify";
import {MultiSelect} from "primereact/multiselect";
import {ReferenceDataType} from "../../services/ReferenceDataType";

const userService = ServiceFactory.instance().createUserService();
class UserManagement extends EssDocsComponent {
    constructor(props) {
           super(props);
           this.state = {
               busy: false,
               email: '',
               errors: [],
               user: null,
               traderRole: false,
               tradeAdminRole: false,
               carrierRole: false,
               eCarrierRole: false,
               shipperRole: false,
               essDocsAdminRole: false,
               apiUserRole: false,
               unloadElevatorRole: false,
               confirmationPopupVisible: false,
               userSettingsMsg: 'Are you sure you want to update user settings',
               selectedOption: '',
               userConfirmed: false,
               panelCollapsed: true,
               deleteUser: false,
               updateUserStatus: true,
               listOfUnConfirmedUsers: null,
               resetPassword: false,
               selectedCompanies: []
           };

        this.options = [
            {label: 'Active', value: 'Active'},
            {label: 'Inactive', value: 'Inactive'}
        ];

        this.handleFindUser = this.handleFindUser.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleUserStatusUpdate = this.handleUserStatusUpdate.bind(this);
        this.handleUpdateUser = this.handleUpdateUser.bind(this);
        this.handleUpdateUserStatus = this.handleUpdateUserStatus.bind(this);
        this.resetTempPwd = this.resetTempPwd.bind(this);
        this.renderUserDetails = this.renderUserDetails.bind(this);
        this.handleCompanyUpdate = this.handleCompanyUpdate.bind(this);
    }

    async componentDidMount() {
        let unConfirmedUsers = await userService.readUsersWithStatus();
        this.setState({listOfUnConfirmedUsers: unConfirmedUsers.data});
    }

    async handleFindUser() {
        this.setState({busy: true});
        try {
            const user = await userService.getUser(this.state.email.trim().toLowerCase(), true);
            const refDataService = ServiceFactory.instance().createReferenceDataService();
            let companyRefData = await refDataService.readAll(user.type === 'SHIPPER' ? ReferenceDataType.Shippers
                : ReferenceDataType.Carriers );
            this.companyList = this.convertToObjArr(companyRefData, user.type);
            let selectedCompanies;
            if(user.companyList) {
                selectedCompanies = user.companyList.map(company => company.id);
            }
            this.setState({user: user, busy: false, errors: [],
                selectedCompanies: selectedCompanies ? selectedCompanies : [user.company], ...this.getUserProperties(user)})
        } catch (e) {
            console.log(e);
            this.setState({errors: ['User not found'], busy: false, user: null});
        }
    }

    validate() {

        if ((this.state.carrierRole || this.state.eCarrierRole) && (
            this.state.traderRole || this.state.tradeAdminRole || this.state.shipperRole || this.state.unloadElevatorRole)) {
            this.setState({errors: ['A Carrier (or Executor for Carrier) can not be a Trader, Trade Admin, Shipper or Unload Elevator']});
            return false;
        }


        if ((this.state.user.type === 'CARRIER') && (
            this.state.traderRole || this.state.tradeAdminRole || this.state.shipperRole || this.state.unloadElevatorRole)) {
            this.setState({errors: ['A Carrier user cannot be a Trader, Trade Admin, Shipper or Unload Elevator']});
            return false;
        }

        if ((this.state.user.type === 'SHIPPER') && (
            this.state.carrierRole || this.state.eCarrierRole)) {
            this.setState({errors: ['A Shipper user cannot have the Carrier or Executor for Carrier roles']});
            return false;
        }

        this.setState({errors: []});
        return true;
    }

    populateRoles() {
        const roles = [];

        if (this.state.traderRole) roles.push('TRADER');
        if (this.state.tradeAdminRole) roles.push('TRADEADMIN');
        if (this.state.shipperRole) roles.push('SHIPPER');
        if (this.state.carrierRole) roles.push('CARRIER');
        if (this.state.eCarrierRole) roles.push('ECARRIER');
        if (this.state.unloadElevatorRole) roles.push('UNLOADELEVATOR');
        if (this.state.apiUserRole) roles.push('APIUSER');
        if (this.state.essDocsAdminRole) roles.push('ESSDOCSADMIN');

        return roles;
    }

    async handleSave() {
        let user = await userService.getUser(this.state.email.trim().toLowerCase(), false);
        this.setState({confirmationPopupVisible: false})
        const {selectedCompanies} = this.state;
        if(selectedCompanies.length === 0) {
            this.setState({errors: ['User must be assigned to at least 1 company'], busy: false});
            return;
        }

        if(this.state.deleteUser === true) {
            this.setState({busy: true});
            try {
                await userService.deleteUser(user.username);
                this.messages.show({severity: 'success', summary: 'Success', detail: `User '${user.username}' Deleted From BDT!`});
                this.setState({user: null});
            } catch(e) {
                console.error(e);
                this.setState({errors: ['Failed to Delete User']});
            }
            finally {
                this.setState({busy: false,
                    deleteUser: false,
                    userSettingsMsg: 'Are you sure you want to update user settings'});
            }
        } else {
            if (!this.validate()) return;
            this.setState({busy: true});

            const roles = this.populateRoles();
            user.roles = roles;
            user.deactivated = this.state.selectedOption === 'Inactive';
            if(selectedCompanies && selectedCompanies.length > 0 ){
                if(user.roles && user.roles.includes('APIUSER')) {
                    if (!selectedCompanies.includes(user.company) || selectedCompanies.length > 1) {
                        this.setState({errors: [`Company Details can not be updated for API User.`], busy: false});
                        return;
                    }
                }


                if(user.userLoggedIn && user.companyList &&
                    (user.companyList.length !== selectedCompanies.length ||
                        user.companyList.filter(company => !selectedCompanies.includes(company.id)).length > 0)) {
                    this.setState({errors: [`User '${user.username}' currently logged into BDT system. Please advise user to logout before updating Company details.`], busy: false});
                    return;
                }

                user.companyList = this.fetchCompanyArray();
                if(!selectedCompanies.includes(user.company) && !user.userLoggedIn) {
                    user.company = user.companyList[0].id;
                    user.companyName = user.companyList[0].label;
                }
            }

            this.setState({user: user});
            try {
                await userService.updateUser(user);
                this.messages.show({severity: 'success', summary: 'Success', detail: 'User has been updated'});
            } catch (e) {
                console.error(e);
                this.setState({errors: ['Failed to Update User']});
            } finally {
                this.setState({
                    busy: false,
                    userSettingsMsg: 'Are you sure you want to update user settings'
                });
            }
        }
    }

    fetchCompanyArray(){
        var result = this.companyList.filter( company => this.state.selectedCompanies.includes(company.id));
        return result;
    }

    async handleUserStatusUpdate(e) {
        if(e.value) {
            let selectedOption = e.value.toUpperCase();
            this.setState({
                selectedOption: e.value
            })
        }
    }
    async handleUpdateUser() {
        let msg = 'Are you sure you want to update user settings';
        if ((!this.state.user.deactivated && this.state.selectedOption === 'Inactive') ||
            (this.state.user.deactivated === true && this.state.selectedOption === 'Active')) {
            msg = (<span>Are you sure you want to <span
                style={this.state.selectedOption === 'Inactive' ? {fontWeight: 'bold', color: 'red'} : {
                    fontWeight: 'bold',
                    color: 'green'
                }}>
                {this.state.selectedOption} </span>user: {this.state.user.username}</span>);

        }
        this.setState({
            userSettingsMsg: msg,
            confirmationPopupVisible: true
        })
    }

    getUserProperties(user) {
        const props = {};
        const roles = user.roles ? user.roles : [];
        props.traderRole = roles.includes('TRADER');
        props.tradeAdminRole = roles.includes('TRADEADMIN');
        props.carrierRole = roles.includes('CARRIER');
        props.eCarrierRole = roles.includes('ECARRIER');
        props.shipperRole = roles.includes('SHIPPER');
        props.essDocsAdminRole = roles.includes('ESSDOCSADMIN');
        props.apiUserRole = roles.includes('APIUSER');
        props.unloadElevatorRole = roles.includes('UNLOADELEVATOR');
        props.selectedOption = user.deactivated !== undefined && user.deactivated ? 'Inactive' : 'Active'
        props.userConfirmed = user.confirmed;
        return props;
    }

    renderUserDetails(user) {
        return (
            <Fieldset legend="User Details">
                <div className={'grid'}>
                    <div className={'col-4 UserRolesLabel'}>Email</div>
                    <div className={'col-8 UserRolesLabel'}>{user.username}</div>
                    <div className={'col-4 UserRolesLabel'}>Full Name</div>
                    <div className={'col-8'}>{user.fullName}</div>
                    <div className={'col-4 UserRolesLabel'}>User Type</div>
                    <div className={'col-8'}>{user.type}</div>
                    <div className={'col-4 UserRolesLabel'}>Company Details</div>
                    <div className={'col-8'}>{user.companyList ?  user.companyList.map(a => a.label).join(' | ') : user.companyName}</div>
                    <div className={'col-4 UserRolesLabel'}>Cognito User Status</div>
                    <div className={'col-4'}>{user.confirmed ? 'CONFIRMED' : 'FORCE_CHANGE_PASSWORD'} </div>
                    <div className={'col-4'}>
                    {user.confirmed === false &&
                            <Button style={{marginLeft: '-15px', marginTop: '-20px'}}
                                    disabled={this.state.resetPassword || user.deactivated === true}
                                    label={'Reset Password'}
                                    onClick={this.resetTempPwd}
                                    icon={'fa fa-key'}
                                    iconPos={'right'}/>
                    }
                    </div>
                </div>
            </Fieldset>
        )
    }

    async resetTempPwd() {
        this.setState({busy: true, resetPassword: true});
        let myInit = {
            body: [this.state.user.username],
            headers: {}
        };
        try {
            const result = await API.put('adminuser', `/admin/resetpwd`, myInit);
            if(result.data.failedUsers.length > 0) {
                this.setState({errors: [`Password Reset failed for user '${this.state.user.username}'`], resetPassword: false});
            } else if (result.data.succeedUsers.length > 0 ){
                this.setState({busy: false, errors: [], resetPassword: false})
                this.messages.show({severity: 'success', summary: 'Success', detail: `Password reset successful for user '${this.state.user.username}'`});
            }
        } catch (e) {
            this.setState({errors: [`Password Reset failed for user '${this.state.user.username}'`], busy: false, resetPassword: false});
        }
    }

    renderCognitoUserDetails(users) {
        return (
                <DataTable value={users}>
                    <Column field="username" header="UnConfirmed Users" />
                </DataTable>
        );
    }

    async handleCompanyUpdate(e) {
        this.setState({errors: [], selectedCompanies: e.value});
    }

    convertToObjArr(companyArray, companyType) {
        return Array.from(companyArray, companyDetails => {
            let companyObj = {};
            companyObj.id = companyDetails.id;
            companyObj.label = companyType === 'SHIPPER' ? companyDetails.shipperName : companyDetails.carrierName;
            return companyObj;
        })
    }

    renderUserForm(user) {
        let selectedCompanies = this.state.selectedCompanies || user.companyList;
        return (
            <Fieldset legend="User Settings" toggleable={true} collapsed={this.state.panelCollapsed} onToggle={(e) => this.setState({panelCollapsed: e.value})}>
                <div className="grid">
                        <div className="col-4">
                                <h4>User Roles</h4>
                                <div className="EssDocsFormField UserRolesCheckbox">
                                    <Checkbox inputId="traderRole"
                                              onChange={(e) => this.setState({traderRole: e.checked}) }
                                              checked={this.state.traderRole}
                                              disabled={this.state.user.deactivated}></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">Trader</label>
                                </div>
                                <div className="EssDocsFormField UserRolesCheckbox">
                                    <Checkbox inputId="tradeAdminRole"
                                              onChange={(e) => this.setState({tradeAdminRole: e.checked}) }
                                              checked={this.state.tradeAdminRole}
                                              disabled={this.state.user.deactivated}></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">Trade Admin</label>
                                </div>
                                <div className="EssDocsFormField UserRolesCheckbox">
                                    <Checkbox inputId="shipperRole"
                                              onChange={(e) => this.setState({shipperRole: e.checked}) }
                                              checked={this.state.shipperRole}
                                              disabled={this.state.user.deactivated}></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">Shipper</label>
                                </div>
                                <div className="EssDocsFormField UserRolesCheckbox">
                                    <Checkbox inputId="carrierRole"
                                              onChange={(e) => this.setState({carrierRole: e.checked}) }
                                              checked={this.state.carrierRole}
                                              disabled={this.state.user.deactivated}></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">Carrier</label>
                                </div>
                                <div className="EssDocsFormField UserRolesCheckbox">
                                    <Checkbox inputId="eCarrierRole"
                                              onChange={(e) => this.setState({eCarrierRole: e.checked}) }
                                              checked={this.state.eCarrierRole}
                                              disabled={this.state.user.deactivated}></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">Executor for Carrier</label>
                                </div>
                                <div className="EssDocsFormField UserRolesCheckbox">
                                    <Checkbox inputId="unloadElevatorRole"
                                              onChange={(e) => this.setState({unloadElevatorRole: e.checked}) }
                                              checked={this.state.unloadElevatorRole}
                                              disabled={this.state.user.deactivated}></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">Unload Elevator</label>
                                </div>
                                <div className="EssDocsFormField UserRolesCheckbox">
                                    <Checkbox inputId="apiUserRole"
                                              onChange={(e) => this.setState({apiUserRole: e.checked}) }
                                              checked={this.state.apiUserRole}
                                              disabled={this.state.user.deactivated}></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">API User</label>
                                </div>
                            </div>
                        <div className="col-4">
                                <h4>User Companies</h4>
                                <MultiSelect value={selectedCompanies}
                                             options={this.companyList}
                                             optionValue={'id'}
                                             optionLabel={'label'}
                                             onChange={this.handleCompanyUpdate}
                                             maxSelectedLabels={1}
                                             selectedItemsLabel="{0} Companies assigned"
                                             placeholder="Choose Companies"
                                             filter
                                             filterPlaceholder="Search"
                                             className="multiselect-custom"
                                             scrollHeight={'250px'}
                                             style={{width: '285px'}}/>
                            </div>
                </div>
            </Fieldset>
        )
    }

    equals = (array1, array2) =>
        array1.length === array2.length &&
        array1.every((v) => array2.includes(v));

    async handleUpdateUserStatus() {
        this.setState({busy: true});
        try {
            const users = await userService.updateUserStatus();
            this.messages.show({severity: 'success', summary: 'Success', detail: `Users updated successfully!`});
            this.setState({busy: false, errors: [], updateUserStatus: false, listOfUnConfirmedUsers: []})
        } catch (e) {
            this.setState({errors: ['User confirmation status update failed'], busy: false, user: null});
        }
    }

    render() {
        const {user, errors, email, busy, userConfirmed, selectedOption, updateUserStatus, listOfUnConfirmedUsers, selectedCompanies} = this.state;
        let disableSaveButton = (user && this.equals(user.roles, this.populateRoles())) && user.companyList &&
                                !(user.deactivated !== undefined ? user.deactivated !== (selectedOption === 'Inactive')
                                    : selectedOption === 'Inactive');

        let difference = user && user.companyList &&
            (user.companyList.length !== selectedCompanies.length ||
                user.companyList.filter(company => !selectedCompanies.includes(company.id)).length > 0)

        if(difference) {
            disableSaveButton = false;
        } else if(disableSaveButton && user && !user.companyList && selectedCompanies.length === 0){
            disableSaveButton = true;
        }

        const footer = <span>
                <Button label="UpdateStatus" onClick={this.handleUpdateUserStatus} icon="pi pi-check" style={{marginRight: '.25em'}}/>
             </span>;
        return (
            <EssDocsPage title={'User Management'}>
                <ErrorMessages errors={errors}/>
                <Messages className='col-12' ref={(el) => this.messages = el}/>
                <div style={{width: '700px'}}>

                    {//Below code is to update DB for existing unconfirmed users with 'confirmed' flag
                    }
                    <div style={{marginBottom: '20px'}}>
                        {listOfUnConfirmedUsers && listOfUnConfirmedUsers.length > 0 &&
                            <Form>
                                <Fieldset legend="Unconfirmed Users">
                                    {updateUserStatus === true &&
                                    <Card footer={footer}>
                                        Update user confirmation status for all Unconfirmed users in DB
                                    </Card>
                                    }
                                    {listOfUnConfirmedUsers && listOfUnConfirmedUsers.length > 0 && this.renderCognitoUserDetails(listOfUnConfirmedUsers)}
                                </Fieldset>
                            </Form>
                            }
                    </div>
                    <div>
                        <Form>
                            <Fieldset legend="Find User">
                                <div className="EssDocsFormField">
                                    <FieldLabel required={true} value="User Email"/>
                                    <InputText disabled={false}
                                               id="email"
                                               size={40}
                                               value={email}
                                               onChange={(e) => this.setState({email: e.target.value})}/>
                                    <Button disabled={!email || busy}
                                            onClick={this.handleFindUser}
                                            style={{marginLeft: '5px'}}
                                            label={'Load User Details'}/>
                                </div>
                            </Fieldset>

                        {user && this.renderUserDetails(user)}
                        {user && this.renderUserForm(user)}
                        {user &&
                            <Fieldset legend="User Activation">
                                <div className="EssDocsFormField">
                                    <SelectButton value={selectedOption} options={this.options} onChange={this.handleUserStatusUpdate} />
                                </div>
                            </Fieldset>
                        }
                        {user && this.state.confirmationPopupVisible && <ConfirmationPopup message={this.state.userSettingsMsg}
                                                                        onAccept={this.handleSave}
                                                                        onClose={() => this.setState({confirmationPopupVisible: false,
                                                                            userSettingsMsg: 'Are you sure you want to update user settings',
                                                                            deleteUser: false})}
                                                                        visible={this.state.confirmationPopupVisible}/>}
                        <div style={{textAlign: 'right', marginRight: '2px'}}>
                            {user && <Button disabled={disableSaveButton}
                                             style={{marginRight: '10px'}}
                                             className={selectedOption === 'Inactive' ? "p-button-warning" : "p-button-raised p-button-success"}
                                             onClick={this.handleUpdateUser}
                                             label={'Update User'}
                                             icon="pi pi-user-edit"
                                             iconPos="right" />}
                            {user && !userConfirmed && <Button
                                             className="p-button-danger"
                                             onClick={() => this.setState({confirmationPopupVisible: true, deleteUser: true,
                                                 userSettingsMsg: <span>Are you sure you want to <span style={{fontWeight: 'bold', color: 'red'}}>DELETE</span> user '{this.state.user.username}'.
                                              <span style={{fontWeight: 'bold'}}> This action can not be reverted.</span></span>})}
                                             label={'Delete User'}
                                             style={{alignSelf: 'left'}}
                                             icon="pi pi-user-minus"
                                             tooltip="Click to DELETE User"
                                             iconPos="right" />}
                        </div>
                    </Form>
                    </div>
                </div>
            </EssDocsPage>
        );
    }
}

export default UserManagement;

import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {FieldLabel, ErrorMessages} from "@essdocs/commonui";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import ServiceFactory from "../../services/ServiceFactory";
import {InputText} from "primereact/inputtext";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {AutoComplete} from "primereact/autocomplete";
import {
    BROWSER_DATE_FORMAT,
    dateToServerFormat,
    formatNumber
} from "@essdocs/ngfashared/shared/FormatUtils";
import {Calendar} from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";

class AddBolDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carrier: null,
            carrierName: null,
            quantityUnit: '',
            quantityUnitAbbr: '',
            quantity: 0,
            consignedTo: 0,
            errors: [],
            notifyLine:'',
            bargeId: '',
            bolDate: new Date(this.props.barge.blDate),
            bargeIdSuggestions: [],
            canSave: true
        };

        this.refDataService = ServiceFactory.instance().createReferenceDataService();
        this.inboxService = ServiceFactory.instance().createInboxService();

        this.handleCarrierUpdate = this.handleCarrierUpdate.bind(this);
        this.handleAddBol = this.handleAddBol.bind(this);
        this.handleUpdateQuantity = this.handleUpdateQuantity.bind(this);
        this.handleUpdateConsignedTo = this.handleUpdateConsignedTo.bind(this);
        this.handleQuantityUnitUpdate = this.handleQuantityUnitUpdate.bind(this);
        this.suggestBargeIds = this.suggestBargeIds.bind(this);
        this.handleUpdateBargeId = this.handleUpdateBargeId.bind(this);
        this.handleBlDateUpdate = this.handleBlDateUpdate.bind(this);

        const ts = this.props.barge.record.tradeString;
        const shipper = ts[0];
        this.consignedToValues = [{value: shipper.owner, label: shipper.ownerName}];
        if (ts.length > 1 && ts[1].reconsignment === 0) {
            this.consignedToValues.push({value: ts[1].owner, label: ts[1].ownerName});
        }
    }

    async suggestBargeIds(e) {
        const results =  await this.refDataService.search(ReferenceDataType.Barges, e.query);
        this.setState({bargeIdSuggestions: results});
    }

    async componentDidMount() {
        const {barge} = this.props;
        const firstConsignee = this.consignedToValues[0];
        const refData = await this.loadReferenceData();

        let response = await this.inboxService.findBarge(this.props.barge.record.uuid);
        let updatedBarge = response.barge;
         if(updatedBarge) {
             updatedBarge.quantity = updatedBarge.quantity && updatedBarge.quantity !== 0 ? formatNumber(updatedBarge.quantity, 3) : '';
         }

        this.setState({
            carrier: barge.record.carrier,
            carrierName: barge.record.carrierName,
            quantity: updatedBarge ? updatedBarge.quantity : barge.quantity,
            quantityUnitAbbr: barge.quantityUnitAbbr,
            quantityUnit: barge.quantityUnit,
            origin: barge.record.origin,
            consignedTo: firstConsignee.value,
            bargeId: updatedBarge? updatedBarge.bargeId : barge.record.bargeId,
            bolDate: new Date(barge.blDate),
            notifyLine: this.getNotifyString(firstConsignee.value),
            ...refData
        });

    }

    async loadReferenceData() {
        const carriers = await this.refDataService.readAll(ReferenceDataType.Carriers);
        const commodities = await this.refDataService.readAll(ReferenceDataType.Commodities);
        const originLocations = await this.refDataService.readAll(ReferenceDataType.OriginLocations);
        const uoms = this.refDataService.deriveUnitsOfMeasure(commodities);
        return {
            carriers: carriers,
            quantityUnits: uoms,
            originLocations: originLocations,
        };
    }

    handleQuantityUnitUpdate(e) {
        if (e.target.value) {
            const id = e.target.value;
            const unit = this.state.quantityUnits.find(c => c.id === id);
            if (unit) {
                this.setState({quantityUnitAbbr: id, quantityUnit: unit.name});
            }
        }
    }

    async handleUpdateBargeId(e) {
        this.setState({errors : [], canSave: true});
        if (e.target.value.id) {
            const carrierCode = e.target.value.carrierCode;
            const carrierRec = this.state.carriers.find(c => c.id.trim() === carrierCode.trim());

            let {carrier,carrierName} = this.state;

            if (carrierRec) {
                carrier = carrierRec.id;
                carrierName = carrierRec.carrierName;
            } else {
                console.error('carrier not found:' + carrierCode);
            }
            this.setState({bargeId: e.target.value.id, carrier: carrier, carrierName: carrierName});
        } else {
            this.setState({bargeId: e.target.value})
        }
    }

    handleBlDateUpdate(e) {
        this.setState({errors : [], canSave: true, bolDate: e.target.value});
    }

    handleUpdateConsignedTo(evt) {
        this.setState({consignedTo: evt.target.value, notifyLine: this.getNotifyString(evt.target.value),});
        this.getNotifyString(evt.target.value);
    }

    handleAddBol() {
        this.setState({canSave :false});
        const quantityUnformatted = this.state.quantity.split(',').join('');
        let quantity = parseFloat(quantityUnformatted);

        if (isNaN(quantity)) {
            quantity = 0;
        }
        this.validate(quantity);
    }

    async validate(quantity) {
        const result =  await this.refDataService.readRecordById(ReferenceDataType.Barges, this.state.bargeId);
        if (!result.id) {
            this.setState({errors: ['Invalid Barge ID'], canSave : true});
            return;
        }

        if (parseFloat(this.state.quantity.split(',').join('')) <= 0) {
            this.setState({errors: ['Quantity must be greater than 0'], canSave : true});
            return;
        }

        const blDate = dateToServerFormat(this.state.bolDate);
        let existingBarges = await this.inboxService.findBargeByBargeId(this.state.bargeId, blDate);
        for(let barge of existingBarges) {
            if(barge.uuid !== this.props.barge.record.uuid) {
                this.setState({errors: [`Please note that there is a transaction with this Barge ID and BOL Date in the solution already. Please create a transaction with different Barge ID and/or BOL Date`],
                    canSave : true});
                return;
            }
        }

        const conTo = this.state.consignedTo;
        const consignedToName = this.consignedToValues[0].value === conTo ? this.consignedToValues[0].label :
            this.consignedToValues[1].label;

        this.setState({errors: [], canSave : true});
        this.props.onAddBol({
            bolType: 'Digital',
            carrier: this.state.carrier,
            carrierName: this.state.carrierName,
            quantity: quantity,
            quantityUnit: this.state.quantityUnit,
            origin: this.state.origin,
            consignedTo: this.state.consignedTo,
            consignedToName: consignedToName,
            bargeId: this.state.bargeId,
            blDate: blDate
        });
    }

    handleUpdateQuantity(quantity) {
        this.setState({quantity: quantity});
        /*if (!parseFloat(quantity.split(',').join('')) > 0) {
            this.setState({errors: ['Quantity must be greater than 0']});
            return;
        }*/
        this.setState({errors: []});
    }


    handleCarrierUpdate(e) {
        if (e.target.value) {
            const id = e.target.value;
            const carrier = this.state.carriers.find(c => c.id === id);
            if (carrier) {
                this.setState({carrier: id, carrierName: carrier.carrierName});
            }
        }
    }

    getNotifyString(consignedTo) {
        let ns = '';
        let indexToIncludeShipper = 1;
        const ts = this.props.barge.tradeString;
        if(consignedTo === 0 || consignedTo === this.props.barge.tradeString[0].owner) {
            indexToIncludeShipper = 0;
        }
        for (let idx = ts.length - 1; idx >= indexToIncludeShipper; idx--) {
            let careOf = this.props.barge.record.reconsignments[0].careOf;
            if(careOf && idx === ts.length - 1) {
                ns += ts[idx].ownerName + ' C/O '+ careOf +' / ';
                continue;
            }
            ns += ts[idx].ownerName + ' / '
        }
        return  ns.substr(0, ns.length - 2);
    }

    render() {
        const {barge} = this.props;
        const {carrier, quantity, quantityUnit, origin, bolDate, bargeId, errors} = this.state;
        const canSave = carrier && quantity && parseFloat(this.state.quantity.split(',').join('')) > 0 && quantityUnit
                            && origin && bargeId && bolDate && bolDate instanceof Date && errors.length === 0;

        return (
            <Dialog header={`REQUEST BILL OF LADING: ${bargeId}`}
                    visible={this.props.visible}
                    contentStyle={{overflow: 'auto'}}
                    style={{width: '620px'}} modal={true}
                    //style={{width: '35vw', height: '50vw'}} modal={true}
                    onHide={this.props.onHide}>

                <div style={{padding: '5px'}} className={'grid'}>
                    <ErrorMessages errors={this.state.errors}/>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Barge ID"/>
                        <AutoComplete value={this.state.bargeId}
                                      style={{display: 'block', width: '100%'}}
                                      inputStyle={{width: '100%'}}
                                      autoFocus={true}
                                      minLength={2}
                                      field={'id'}
                                      onChange={this.handleUpdateBargeId}
                                      suggestions={this.state.bargeIdSuggestions}
                                      completeMethod={this.suggestBargeIds} />
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Bill of Lading Date"/>
                        <Calendar showIcon={true}
                                  style={{width: '100%'}}
                                  inputStyle={{width: 'calc(100% - 28px)'}}
                                  showOnFocus={false}
                                  dateFormat="mm/dd/yy"
                                  placeholder={BROWSER_DATE_FORMAT}
                                  value={this.state.bolDate}
                                  showButtonBar
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2020:2040"
                                  onChange={this.handleBlDateUpdate}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Carrier"/>
                        <Dropdown value={this.state.carrier}
                                  style={{width: '100%'}}
                                  scrollHeight={'100px'}
                                  options={this.state.carriers}
                                  optionValue={'id'}
                                  optionLabel={'carrierName'}
                                  tabIndex={8}
                                  filter={true}
                                  onChange={this.handleCarrierUpdate}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Quantity"/>
                        <InputText value={this.state.quantity ? this.state.quantity : ''}
                                   style={{textAlign: 'right', width: '100%'}}
                                   keyfilter={'money'}
                                   onChange={(e) => this.handleUpdateQuantity(e.target.value)}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Quantity Unit"/>
                        <Dropdown value={this.state.quantityUnitAbbr}
                                  options={this.state.quantityUnits}
                                  style={{width: '100%'}}
                                  optionValue={'id'}
                                  optionLabel={'name'}
                                  filter={true}
                                  onChange={this.handleQuantityUnitUpdate}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Origin"/>
                        <Dropdown value={this.state.origin}
                                  style={{width: '100%'}}
                                  scrollHeight={'100px'}
                                  options={this.state.originLocations}
                                  optionLabel={'id'}
                                  optionValue={'id'}
                                  filter={true}
                                  tabIndex={7}
                                  onChange={(e) => this.setState({origin: e.target.value})}/>

                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Consigned To"/>
                        <Dropdown value={this.state.consignedTo}
                                  options={this.consignedToValues}
                                  style={{width: '100%'}}
                                  onChange={this.handleUpdateConsignedTo}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Commodity"/>
                        <div className={'DialogValue'}>{barge.commodityName}</div>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Shipper"/>
                        <div className={'DialogValue'}>{barge.shipperName}</div>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel value="Destination"/>
                        <div className={'DialogValue'}>{barge.record.reconsignments[0].destination}</div>
                    </div>
                    {barge.record.reconsignments[0].careOf &&
                    <div className={'col-6'}>
                        <FieldLabel value="Care Of"/>
                        <div className={'DialogValue'}>{barge.record.reconsignments[0].careOf}</div>
                    </div>}

                    <div className={'col-12'}>
                        <FieldLabel value="Notify"/>
                        <div className={'DialogValue'}>{this.state.notifyLine || this.getNotifyString(this.state.consignedTo)}</div>
                    </div>
                    <div style={{textAlign: 'right', marginTop: '10px'}} className={'col-12'}>
                        {!this.state.canSave && <ProgressSpinner style={{width: '25px', height: '20px'}}
                                                                 strokeWidth="8"
                                                                 fill="#EEEEEE"
                                                                 animationDuration=".8s"/>}
                        <Button disabled={!canSave || !this.state.canSave}
                                icon={'fa fa-check'}
                                className={'p-button-success'}
                                label={'REQUEST'}
                                onClick={this.handleAddBol}/>
                        <Button icon={'fa fa-times'}
                                style={{marginLeft: '10px'}}
                                className={'p-button-secondary'}
                                label={'CANCEL'}
                                onClick={this.props.onHide}/>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default AddBolDialog;

import React, {Component, CSSProperties} from 'react';
import {DataTable, DataTableColumnResizeEndEvent, DataTableRowClickEvent} from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { EssDocsPage } from "../../es";
// import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import "./Inbox.css";
interface Props {
  className?: string;
  style?: CSSProperties;
  hasMore?: boolean;
  sortable?: boolean;
  loadMoreButtonEnabled?: boolean;
  showHideShowPopup: boolean;
  model: any[];
  data: any;
  onLoadMore: () => void;
  onSort: () => void;
  sortFields: any;
  onHideShow: (c: any, cVisible: boolean) => void;
  onCloseHideShowPopup: (value: boolean) => void;
  onColumnResize: (field: any, delta: any) => void;
  onRowDoubleClick: (evt: DataTableRowClickEvent) => void;
  onColumnReorder: (dragIndex: number, dropIndex: number) => void;
  onSelectionChange: (data: any) => void;
  selection: any[];
  expandedRows: any;
  dataKey: string;
  dataTableRef: React.Ref<any>;
  tableWidth: any;
}
interface State {
  selection: any[];
  expandedRows: any;
}
class InboxDataTable extends Component<Props, State> {
  static defaultProps = {
    hasMore: false,
    sortable: true,
    loadMoreButtonEnabled: false,
    stripedRows: true,
    dataTableProperties: {},
    dataKey: ''
  };

  constructor(props: Props) {
    super(props);
    this.handleColResize = this.handleColResize.bind(this);
    this.handleColumnReorder = this.handleColumnReorder.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.handleRowUnselect = this.handleRowUnselect.bind(this);
    this.handleRowDoubleClick = this.handleRowDoubleClick.bind(this);
    this.state = {
      selection: this.props.selection ? this.props.selection : [],
      expandedRows: this.props.expandedRows ? this.props.expandedRows : {}
    };
  }

  async componentDidMount() {
    if(this.state.selection.length && this.props.dataKey) {
      let lastKey = this.state.selection[this.state.selection.length - 1];
      let dataSelected = lastKey[this.props.dataKey];
      if(dataSelected) {
        this.setState({expandedRows: {[dataSelected]: true}});
      }
    }
  }

  handleColResize(e: DataTableColumnResizeEndEvent) {
    const {
      column,
      element: { offsetWidth },
    } = e;
    if (this.props.onColumnResize) {
      this.props.onColumnResize(column!.props?.field, offsetWidth);
    }
  }

  handleRowDoubleClick(evt: DataTableRowClickEvent) {
    if (this.props.onRowDoubleClick) {
      this.props.onRowDoubleClick(evt);
    }
  }

  handleSelectionChange(evt: any) {
    const {expandedRows} = this.state;
    let value = evt.value;
    const newState = {selection: value, expandedRows: {}};
    let exists = false;
    if (value && value.length && expandedRows && Object.keys(expandedRows).length > 0) {
      const rowId = Object.keys(expandedRows)[expandedRows.length-1];
      value.forEach((s: any) => {
        if (s[this.props.dataKey] === rowId) {
          exists = true;
        }
      });
    }
    if (!exists && value && value.length) {
      let lastKey = value[value.length - 1];
      let dataSelected = lastKey["tradeStringUuid"];
      //newState.expandedRows = [(evt.value as any)[0]];
      newState.expandedRows = {[dataSelected]: true};
    }
    this.setState(newState);
    if(this.props.onSelectionChange) {
      this.props.onSelectionChange(newState);
    }
  }

  rowExpansionTemplate() {
    const actions = this.getCommonActions();
    if (!actions || actions.length === 0) return null;
    return (
      <div
        style={{
          position: "absolute",
          overflow: "visible",
          height: "0px",
          right: "0",
          marginTop: "-2px",
        }}
      >
        <div className={"InboxDataTableExpandedActionBar"}>
          {actions.map((a: any) => (
            <Button
              icon={a.icon}
              key={a.id}
              label={a.label}
              disabled={a.disabled !== undefined ? a.disabled : false}
              className={a.className}
              onClick={() => a.handler(a.id, this.state.selection)}
              style={{ marginRight: "10px", ...a.style }}
            />
          ))}
        </div>
      </div>
    );
  }

  getCommonActions() {
    const { selection } = this.props;
    if (selection.length === 1) return selection[0].actions;

    const actionCounts = {} as Record<string, any>;

    selection.forEach((s) => {
      if (s.actions) {
        s.actions.forEach((a: any) => {
          const ac = actionCounts[a.id];
          if (ac) {
            ac.count++;
          } else {
            a.count = a.allowMultiSelect === false ? 0 : 1;
            actionCounts[a.id] = a;
          }
        });
      }
    });

    return Object.values(actionCounts).filter(
      (a) => a.count === selection.length
    );
  }

  handleRowSelect(evt: any) {
    if(this.props.dataKey) {
      let dataSelected = evt.data[this.props.dataKey];
      let expandedRows = {[dataSelected]: true};
      this.setState({expandedRows: expandedRows});
    } else {
      this.setState({expandedRows: [evt.data]});
    }
  }

  handleRowUnselect() {}

  handleColumnReorder(col: any) {
    if (this.props.onColumnReorder) {
      this.props.onColumnReorder(col.dragIndex, col.dropIndex);
    }
  }

  createColumnSelectOverlay() {
    return (
      // @ts-ignore remove once prime react has upgraded
      <Dialog
        header={"Hide/Show Columns"}
        visible={this.props.showHideShowPopup}
        style={{ width: "20vw" }}
        modal={true}
        onHide={() => this.props.onCloseHideShowPopup(false)}
      >
        <div className="InboxDataTableFieldToggleOverlay">
          {" "}
          {this.props.model.map((c, idx) => (
            <div key={idx}>
              <div style={{ marginBottom: 0 }} className={"p-field-checkbox"}>
                <Checkbox
                  inputId={"hideshow" + idx}
                  value={c.field}
                  checked={c.visible}
                  onChange={() => this.props.onHideShow(c, !c.visible)}
                />
                <label htmlFor={"hideshow" + idx}>{c.header}</label>
              </div>
            </div>
          ))}
        </div>
      </Dialog>
    );
  }

  render() {
    const { hasMore } = this.props;
    let selectedBarges = this.state.selection.length ?  this.state.selection : this.props.selection;

    return (
      <div
        style={this.props.style}
        className={this.props.className + " inboxDataTable"}
      >
        {this.props.showHideShowPopup ? this.createColumnSelectOverlay() : null}
        <DataTable
            ref={this.props.dataTableRef}
            tableStyle={{
              tableLayout: "fixed",
              border: "1px solid rgb(233, 236, 239)"
            }}
            dataKey={this.props.dataKey}
            scrollable={true}
            scrollHeight={"720px"}
            value={this.props.data}
            id={"inboxDataTable"}
            //key={JSON.stringify(data)}
            resizableColumns={true}
            columnResizeMode="expand"
            selectionMode="multiple"
            sortMode={"multiple"}
            onSort={this.props.onSort}
            removableSort={true}
            multiSortMeta={this.props.sortFields}
            onSelectionChange={this.handleSelectionChange}
            onRowDoubleClick={this.handleRowDoubleClick}
            selection={selectedBarges}
            reorderableColumns={true}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={this.rowExpansionTemplate}
            onColReorder={this.handleColumnReorder}
            //onRowSelect={this.handleRowSelect}
            onColumnResizeEnd={this.handleColResize}
            stripedRows={true}
            showGridlines={true}
        >
          {this.props.model.map((col, idx) => {
            let properties = col.otherProperties ? col.otherProperties : {};
            return col.visible && (<Column
                key={idx}
                field={col.field}
                header={col.header}
                style={col.style}
                body={col.bodyRenderer}
                sortable={true}
                sortField={col.sortField}
                resizeable={true}
                {...properties}
            />)
          })}
        </DataTable>
        {hasMore && (
          <div className="InboxDataTableFooter">
            <Button
              disabled={this.props.loadMoreButtonEnabled}
              label="Load More"
              className="p-button-secondary"
              onClick={this.props.onLoadMore}
            />
          </div>
        )}
      </div>
    );
  }
}

export default InboxDataTable;

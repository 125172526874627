import {store} from "../redux/Store";
import {saveMessage} from "../redux/Actions";
import {createDatabaseTimestamp} from "@essdocs/ngfashared/shared/FormatUtils";

export default class MessageService {
    async addMessage(bargeId, message, company) {
        store.dispatch(saveMessage(bargeId, {
            created: createDatabaseTimestamp(),
            type: 'NOTE',
            company: company,
            content: message}));
    }

    async addActivity(bargeId, tradeStringUuid, activityName, user) {
        store.dispatch(saveMessage(bargeId, {
            created: createDatabaseTimestamp(),
            type: 'SYSTEM',
            tradeStringUuid: tradeStringUuid,
            company: user.company,
            companyName: user.companyName,
            content: activityName}));
    }
    async addReject(bargeId, activityName, user) {
        store.dispatch(saveMessage(bargeId, {
            created: createDatabaseTimestamp(),
            type: 'SYSTEM',
            company: user.company,
            companyName: user.companyName,
            content: activityName}));
    }


    async getMessages(bargeId) {
        const data =  store.getState().messages.messages;
        const result = data[bargeId];
        return  result ? result : [];
    }

}

import React, {Component} from 'react';
import axios from 'axios';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

class TestLogin extends Component {

    constructor(props) {
        super(props);
        this.state  = {
            users: [],
            selectedUser: null
        };
        this.handleSelectUser = this.handleSelectUser.bind(this);
    }

    componentDidMount() {
        this.loadTestUsers();
    }

    handleSelectUser(e) {
        this.setState({selectedUser: e.value});
        const selUser = this.state.users.filter(u => u.username === e.value.username);
        let user = selUser[0];
        this.props.onSelectUser(user);
    }

    async loadTestUsers() {
        const users =  await axios.get('/data/users.json');
        this.setState({users: users.data});
    }

    render() {
        const data = this.state.users.map(u => {
            return {
                name: u.fullName,
                username: u.username,
                company: u.company,
                companyName: u.companyName,
                type: u.type,
                roles: u.roles.toString()
            };

        });

        return (
            <div style={{width: '90%', height: '100%'}} className={'grid align-center'}>
                <div style={{fontSize: '12px', textAlign: "center",minWidth: '1100px'}} className={'col-6 p-offset-3'}>
                    <h3>Select Test User</h3>
                    <DataTable value={data} selectionMode="single"
                               selection={this.state.selectedUser}
                               onSelectionChange={this.handleSelectUser} >
                        <Column field='username' header='Username'/>
                        <Column field='name' header='Name'/>
                        <Column field='companyName' header='Company'/>
                        <Column field='type' header='User Type' style={{width: '100px'}}/>
                        <Column field='roles' header='Roles'/>
                    </DataTable>
                </div>

            </div>
        );
    }
}

export default TestLogin;

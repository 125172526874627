import React from 'react';
import {EssDocsComponent, EssDocsPage, ErrorMessages, Form} from "@essdocs/commonui";
import {Messages} from "primereact/messages";
import {Fieldset} from "primereact/fieldset";
import './ReferenceDataManagement.css';
import {Dropdown} from "primereact/dropdown";
import {FileUpload} from "primereact/fileupload";
import {read, write, utils} from "xlsx";
import FileSaver from 'file-saver';
import {API} from "aws-amplify";
import LogDisplayer from "./LogDisplayer";
import {Checkbox} from "primereact/checkbox";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import ServiceFactory from "../../services/ServiceFactory";
import {Button} from "primereact/button";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {REF_DATA_LIST} from "./ReferenceData/ReferenceDataUtil";
import {findDuplicates, validateAndBuildData, mandatoryFieldValidation} from "./ReferenceData/BargeIdValidationUtil";

const CHUNK_SIZE = 500;

class ReferenceDataManagement extends EssDocsComponent {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            messages: [],
            fileType: '',
            ignoreValidation: false,
            carriersRefData: [],
            downloadPopupVisible: false,
            duplicates: '',
            jsonHeaders: [],
            showUpload: true,
            excelData: [],
            exportHeaderName: ''
        };

        this.dt = React.createRef();
        this.refDataService = ServiceFactory.instance().createReferenceDataService();

        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.callAPI = this.callAPI.bind(this);
        this.handleDuplicatesPopupConfirmation = this.handleDuplicatesPopupConfirmation.bind(this);
        this.readData = this.readData.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getHeader = this.getHeader.bind(this);
    }

    async componentDidMount() {
        const carriers = await this.refDataService.readAll(ReferenceDataType.Carriers);
        this.setState({
            carriersRefData: carriers
        });
    }

    handleDuplicatesPopupConfirmation() {
        this.addMessage('I', 'Downloading Data. Please wait....');
        this.setState({downloadPopupVisible: false, showUpload: false});
        let refData = REF_DATA_LIST.find(refData => refData.code === this.state.fileType);
        if (refData.query) {
            this.readData(refData.query);
        }
    }

    async readData(findQuery) {
        const fileType = this.state.fileType;
        try {
            this.setState({downloading: true});
            let refData = await this.refDataService.findDuplicateIds(fileType, findQuery);
            let keys = [];
            refData.forEach(data => {
                if (data.carrierCode && Array.isArray(data.carrierCode)) {
                    data.carrierCode = data.carrierCode.join();
                }
                delete data._id;
                if (Object.keys(data).length > 4) {
                    delete data.search;
                }
            });
            if (refData.length > 0) {
                this.setState({jsonHeaders: Object.keys(refData[0]), duplicates: refData, exportHeaderName: fileType});
                this.clearMessages();
            } else {
                this.clearMessages();
                this.addMessage('I', 'No Duplicate Barge Ids at the moment');
            }
        } catch (e) {
            console.error('failed to download data');
            console.error(e);
            this.addMessage('E', 'Error downloading data');
        }
        this.setState({downloading: false});
    }

    handleSelectFile(event) {
        const reader = new FileReader();
        const $this = this;
        //$this.fileUpload.state.files = [];
        $this.fileUpload.clear();
        $this.clearMessages();

        reader.onload = async function () {
            try {
                let fileData = reader.result;
                let wb = read(fileData, {type: 'binary'});
                let data = [];
                for (const sheetName of wb.SheetNames) {
                    if (['ADD', 'UPDATE', 'DELETE'].includes(sheetName) === false) {
                        $this.addMessage('E', `Incorrect SheetName '${sheetName}', expected are 'ADD/UPDATE/DELETE'`);
                        $this.setState({
                            excelData: []
                        });
                        break;
                    }
                    let headerColumns = $this.get_header_row(wb.Sheets[sheetName]);
                    if (['carrierCode', 'bargeId'].every(header => headerColumns.includes(header)) === false) {
                        $this.addMessage('E', `Please check your header columns in '${sheetName}' sheet. Mandatory columns(case sensitive) are 'bargeId' and 'carrierCode'`);
                        $this.setState({
                            excelData: []
                        });
                        break;
                    }

                    let rowObj = utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
                    if (!rowObj || rowObj.length === 0) {
                        $this.addMessage('E', `Sheet '${sheetName}' is empty`);
                        continue;
                    }
                    let validationResult = mandatoryFieldValidation(rowObj, $this.state.carriersRefData);
                    let result = findDuplicates(validationResult.barges);
                    let ignoreValidation = sheetName === 'DELETE' ? true : $this.state.ignoreValidation;
                    let dataReadyToUpload = validateAndBuildData(result.uniqueIds, ignoreValidation);

                    $this.saveFile({
                        duplicates: result.duplicatedIds,
                        validationErrors: validationResult.validationErrors,
                        readyToUpload: dataReadyToUpload
                    }, `${sheetName}_Validation_Result_${new Date().toISOString().slice(0, 10)}`);

                    if (result.duplicatedIds.length > 0 || validationResult.validationErrors.length > 0) {
                        let warningMsg = `Sheet: ${sheetName} - Duplicate/Validation Failed Records '${result.duplicatedIds.length + validationResult.validationErrors.length}' filtered and downloaded for correction!`;
                        if (validationResult.barges.length > 0) {
                            warningMsg += `You can proceed with 'UPLOAD' for remaining '${result.uniqueIds.length}' records`;
                        }
                        $this.addMessage('E', warningMsg);
                    } else {
                        $this.addMessage('I', 'Please review readyToUpload sheet from the downloaded file and proceed with upload.');
                    }

                    if (dataReadyToUpload.length > 0) {
                        data.push({
                            dataToLoad: dataReadyToUpload,
                            action: sheetName
                        })
                        $this.addMessage('I', `You can proceed with upload for '${sheetName}', Records: '${result.uniqueIds.length}'`);
                    } else {
                        $this.addMessage('E', 'There are issues in the selected file. Please correct it and re-upload.');
                    }
                }
                $this.setState({
                    excelData: data
                })
            } catch (e) {
                console.error(e);
                $this.addMessage('E', 'Failed to load file. Make sure you have selected a valid XLSX file.');
            }
        };
        reader.readAsBinaryString(event.files[0]);
    }

    get_header_row(sheet) {
        var headers = [];
        var range = utils.decode_range(sheet['!ref']);
        var C, R = range.s.r; /* start in the first row */
        /* walk every column in the range */
        for (C = range.s.c; C <= range.e.c; ++C) {
            var cell = sheet[utils.encode_cell({c: C, r: R})] /* find the cell in the first row */

            var hdr = "UNKNOWN " + C; // <-- replace with your desired default
            if (cell && cell.t) hdr = utils.format_cell(cell);

            headers.push(hdr);
        }
        return headers;
    }

    async saveFile(dataToWrite, fileName) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let wb;
        let sheets = {};
        if (!Array.isArray(dataToWrite)) {
            for (let index = 0; index < Object.keys(dataToWrite).length; index++) {
                const ws = utils.json_to_sheet(dataToWrite[Object.keys(dataToWrite)[index]]);
                let duplicates = Object.keys(dataToWrite)[index];
                sheets[duplicates] = ws;
            }
            wb = {Sheets: sheets, SheetNames: Object.keys(sheets)};
            /*const ws1 = XLSX.utils.json_to_sheet(dataToWrite.duplicates);
            let duplicates = Object.keys(dataToWrite)[0];
            const ws2 = XLSX.utils.json_to_sheet(dataToWrite.validationErrors);
            let validationErrors = Object.keys(dataToWrite)[1];

            sheets[duplicates] = ws1;
            sheets[validationErrors] = ws2;
            wb = {Sheets: sheets, SheetNames: [duplicates, validationErrors]};*/
        } else {
            const ws = utils.json_to_sheet(dataToWrite);
            sheets[fileName] = ws;
            wb = {Sheets: sheets, SheetNames: [fileName]};
        }
        const excelBuffer = write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    async handleUpload() {
        let excelData = this.state.excelData;
        let ignoreValidation = this.state.ignoreValidation;
        for (const data of excelData) {
            let rowObj = data.dataToLoad;
            let sheetName = data.action;
            switch (sheetName) {
                case 'ADD' :
                    if (rowObj.length > 0) {
                        this.addMessage('I', `Adding Data. Please wait....`);
                        await this.callAPI(rowObj, sheetName);
                    }
                    break;
                case 'UPDATE' :
                    if (rowObj.length > 0) {
                        this.addMessage('I', `Updating Data. Please wait....`);
                        await this.callAPI(rowObj, sheetName);
                    }
                    break;
                case 'DELETE' :
                    if (rowObj.length > 0) {
                        this.addMessage('I', `Removing Data. Please wait....`);
                        await this.callAPI(rowObj, sheetName);
                    }
                    break;
                default:
                    this.addMessage('E', `Unsupported Action ${sheetName}`);
            }
        }
        this.setState({
            excelData: [],
            ignoreValidation: false
        })
    }

    async callAPI(dataToLoad, actionType) {
        const path = `/referencedata/upload`;
        let myInit = {
            body: {
                collection: this.state.fileType,
                indexes: []
            },
            headers: {}
        };
        myInit.body.data = dataToLoad;
        myInit.body.replace = false;

        //ADD or UPDATE or DELETE
        myInit.body.action = actionType;

        if (myInit.body.data.length > CHUNK_SIZE) {
            await this.chunkUpload(myInit, path);
        } else {
            const result = await API.post('referencedata', path, myInit);
            this.clearMessages();
            let msg = result.data;
            if (result.failedEntries && result.failedEntries.length > 0) {
                msg += `Failed Data count '${result.failedEntries.length}' for action : ${actionType}`;
            }
            this.addMessage('I', msg);
            if (result.failedEntries && result.failedEntries.length > 0) {
                this.saveFile(result.failedEntries, `${actionType}_ERRORS_${new Date().toISOString().slice(0, 10)}`);
            }
        }
    }

    async chunkUpload(init, path) {
        const records = init.body.data;
        let chunk = [];
        let failedRecords = [];
        this.clearMessages();
        try {
            for (let idx = 0; idx < records.length; idx++) {
                chunk.push(records[idx]);
                if (chunk.length === CHUNK_SIZE) {
                    this.addMessage('I', `${init.body.action} : Uploading Chunk ${idx - CHUNK_SIZE}-${idx + 1}`);

                    init.body.data = chunk;
                    const result = await API.post('referencedata', path, init);

                    let msg = result.data;
                    if (result.failedEntries && result.failedEntries.length > 0) {
                        msg += `Failed Data count '${result.failedEntries.length}' for action : ${init.body.action}`;
                    }
                    this.addMessage('I', msg);
                    if (result.failedEntries && result.failedEntries.length > 0) {
                        failedRecords = [...failedRecords, ...result.failedEntries];
                    }
                    chunk = [];
                    init.body.replace = false;
                }
            }
            if (chunk.length > 0) {
                this.addMessage('I', `${init.body.action} : Uploading Last Chunk`);

                init.body.data = chunk;
                const result = await API.post('referencedata', path, init);

                let msg = result.data;
                if (result.failedEntries && result.failedEntries.length > 0) {
                    msg += `Failed Data count '${result.failedEntries.length}' for action : ${init.body.action}`;
                }
                this.addMessage('I', msg);
                if (result.failedEntries && result.failedEntries.length > 0) {
                    failedRecords = [...failedRecords, ...result.failedEntries];
                }

                if (failedRecords.length > 0) {
                    this.saveFile(failedRecords, `${init.body.action}_ERRORS_${new Date().toISOString().slice(0, 10)}`);
                }
            }
        } catch (e) {
            console.error(e);
            this.addMessage('E', 'Error loading chunk');
        }
    }


    clearMessages() {
        this.setState({messages: []});
    }

    addMessage(type, msg) {
        this.setState({messages: [...this.state.messages, {type: type, msg: msg}]});
    }

    addMessages(msgs) {
        this.setState({messages: [...this.state.messages, ...msgs]});
    }

    exportCSV() {
        this.dt.current.exportCSV();
    }

    exportExcel() {
        const worksheet = utils.json_to_sheet(this.state.duplicates);
        let sheets = {};
        sheets[this.state.exportHeaderName] = worksheet;
        const workbook = {Sheets: sheets, SheetNames: [this.state.exportHeaderName]};
        const excelBuffer = write(workbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, this.state.exportHeaderName);
    }

    saveAsExcelFile(buffer, fileName) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });

        FileSaver.saveAs(data, fileName + '_export_' + new Date().toISOString().slice(0, 10) + EXCEL_EXTENSION);
    }

    getHeader() {
        return <div style={{textAlign: 'left'}} className='grid'>
            <Button type="button" icon="pi pi-file-excel" onClick={this.exportExcel} className="p-button-success col-1"
                    data-pr-tooltip="XLS" label={'Export'}/>
            <div className='col-4'>{this.state.exportHeaderName.toUpperCase()} </div>
        </div>;
    }

    render() {
        const {errors, jsonHeaders, showUpload, duplicates, exportHeaderName, fileType} = this.state;
        const collection = fileType ? fileType : '';

        const refDataMsg = (
            <span>Are you sure you want to read <span style={{fontWeight: 'bold'}}>{collection}</span>?</span>
        );
        return (
            <EssDocsPage title={'Referencedata Management'}>
                <ErrorMessages errors={errors}/>
                <Messages className='col' ref={(el) => this.messages = el}/>
                <LogDisplayer messages={this.state.messages}/>
                <div style={jsonHeaders.length > 0 ? {width: '1100px'} : {width: '500px'}}>
                    <Form>
                        <Fieldset legend="Read / Upload Data">
                            <div className="EssDocsFormField">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td style={{marginRight: '10px'}}>
                                            <Dropdown optionLabel="name"
                                                      optionValue="code"
                                                      value={this.state.fileType}
                                                      options={REF_DATA_LIST}
                                                      style={{width: '200px'}}
                                                      onChange={(e) => {
                                                          this.setState({
                                                                  fileType: e.value,
                                                                  jsonHeaders: [], duplicates: []
                                                              }
                                                          )
                                                      }}
                                                      placeholder="Select a ReferenceData Type"/>
                                        </td>
                                        <td>
                                            <Button
                                                disabled={!this.state.fileType}
                                                label={this.state.fileType === 'barges' ? 'Find Duplicates' : 'Read Data'}
                                                style={{marginLeft: '15px'}}
                                                onClick={() => this.setState({downloadPopupVisible: true})}/>
                                        </td>
                                        {jsonHeaders.length > 0 && this.state.fileType === 'barges' &&
                                            <td>
                                                <div className="p-field-checkbox" style={{margin: '2px'}}>
                                                    <Checkbox inputId="upload" value="upload"
                                                              onChange={e => this.setState({showUpload: e.checked})}
                                                              style={{marginLeft: '15px'}}
                                                              checked={this.state.showUpload}></Checkbox>
                                                    <label htmlFor="upload" className="p-checkbox-label"
                                                           style={{marginLeft: '3px'}}>Show Upload Option</label>
                                                </div>
                                            </td>}
                                    </tr>
                                    </tbody>
                                </table>

                                {(jsonHeaders.length === 0 || showUpload) && this.state.fileType === 'barges' &&
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td style={{paddingTop: '5px'}}>
                                                <FileUpload chooseLabel={'Select XLSX File '}
                                                            disabled={!this.state.fileType}
                                                            accept={'.xlsx'}
                                                            ref={el => this.fileUpload = el}
                                                            mode={'basic'}
                                                            auto={true}
                                                            customUpload={true}
                                                            uploadHandler={this.handleSelectFile}/>
                                            </td>
                                            <td>
                                                <div className="p-field-checkbox" style={{margin: '5px'}}>
                                                    <Checkbox inputId="ignoreValidation" value="ignoreValidation"
                                                              onChange={e => this.setState({ignoreValidation: e.checked})}
                                                              checked={this.state.ignoreValidation}/>
                                                    <label htmlFor="ignore" className="p-checkbox-label"
                                                           style={{marginLeft: '3px'}}>Ignore Validation</label>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>}
                                {(jsonHeaders.length === 0 || showUpload) && fileType === 'barges' &&
                                    <div style={{paddingLeft: '3px', paddingTop: '20px'}}>
                                        <Button
                                            disabled={this.state.excelData.length === 0}
                                            label={'UPLOAD'}
                                            onClick={this.handleUpload}/>
                                    </div>}

                                {this.state.downloadPopupVisible && <ConfirmationPopup message={refDataMsg}
                                                                                       onAccept={this.handleDuplicatesPopupConfirmation}
                                                                                       onClose={() => this.setState({downloadPopupVisible: false})}
                                                                                       visible={this.state.downloadPopupVisible}/>}
                                <div style={{marginTop: '20px'}}>
                                    {duplicates.length > 0 && exportHeaderName === fileType &&
                                        <DataTable value={this.state.duplicates}
                                                   header={this.getHeader()}
                                                   ref={this.dt}
                                                   paginator
                                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                   rows={10} rowsPerPageOptions={[10, 20, 50]}
                                                   paginatorLeft={this.paginatorLeft}
                                                   paginatorRight={this.paginatorRight}
                                                   scrollable
                                                   scrollHeight="400px"
                                                   removableSort>
                                            {this.state.jsonHeaders.map(header => <Column key={header}
                                                                                          field={header}
                                                                                          header={header}
                                                                                          sortable
                                                                                          filter
                                                                                          filterPlaceholder="Search"
                                                                                          headerStyle={{width: '100px'}}/>)}
                                        </DataTable>}
                                </div>
                            </div>
                        </Fieldset>
                    </Form>
                </div>
            </EssDocsPage>
        );
    }
}

export default ReferenceDataManagement;

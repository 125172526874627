import React, {Component} from 'react';
import {FileUpload} from "primereact/fileupload";
import './FileDragDrop.css';

class FileDragDrop extends Component {
    static defaultProps = {
        className: ''
    }

    constructor(props) {
        super(props);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.state = {
            filename: null,
            fileSelected: false
        };
        this.handleUpload = this.handleUpload.bind(this);
    }

    handleDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    async handleUpload(evt) {
        const $this = this;
        if(evt.files[0].type !== 'application/pdf') {
            if ($this.props.onUploadFile) {
                $this.props.onUploadFile($this.props.docType, $this.props.subType, $this.props.chooseLabel, null, `Invalid document type. Please upload pdf document.`);
                $this.fileUpload.clear();
                //$this.fileUpload.state.files = [];
            };
            return;
        }
        const filename = evt.files[0].name;
        const result = await $this.getBase64(evt.files[0]);
        if ($this.props.onUploadFile) {
            $this.props.onUploadFile($this.props.docType, $this.props.subType, $this.props.chooseLabel, filename, result);
        }
    }

    async handleDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        let dt = evt.dataTransfer;
        const files = dt.files;


        const result = await this.getBase64(files[0]);
        // console.log('result');
        // console.log(result);
        // const result2 = await this.getBase642(files[0]);
        // console.log('result2');
        // console.log(result2);
        const filename = files[0].name;

        this.setState({filename: filename});

        if (this.props.onUploadFile) {
            this.props.onUploadFile(this.props.docType, filename, result);
        }
    }

    async getBase64(file) {
        return new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result);
                // let blob = reader.result;
                // let b = new Blob([blob], {type: 'application/pdf'});
                // console.log('b=');
                // console.log(b);
                // resolve(window.URL.createObjectURL(b));
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    async getBase642(file) {
        const result = await fetch(file);
        const blob = new Blob([result], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
    }

    render() {
        return (
            <div style={{}} className={'FileDragDropButton ' + this.props.className}>
                <FileUpload ref={el => this.fileUpload=el}
                            name="upload"
                            accept="application/pdf"
                            className={'p-button-success'}
                            url="./upload"
                            multiple={false}
                            mode="basic"
                            style={{width: '100%'}}
                            auto={true}
                            chooseLabel={this.props.chooseLabel}
                            customUpload={true}
                            //onSelect={() => this.setState({fileSelected: true})}
                            uploadHandler={this.handleUpload}/>
                {/*<div style={{paddingTop: '15px', textAlign: 'center', border: 'dotted 1px black', width: '100%', height: '50px'}}*/}
                {/*     onDragOver={this.handleDragOver}*/}
                {/*     onDrop={this.handleDrop}>*/}
                {/*    <span>{this.state.filename ? this.state.filename : 'Drag File to Upload'}</span>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default FileDragDrop;

import React from 'react';
import {serverTimestampToBrowserFormat} from "@essdocs/ngfashared/shared/FormatUtils";

export function Message(props) {

    const msg = props.message;
    let msgClass = 'MessageTypeNote';
    if (msg.type === 'SYSTEM') {
        msgClass = 'MessageTypeActivity'
    } else if (msg.type === 'REJECT') {
        msgClass = 'MessageTypeReject'
    }

    return (
        <div className={'MessagePanel ' + msgClass}>
            <div className={'MessagePanelHeader'}>
                <div className={'grid nogutter'}>
                    <div className={'MessagePanelHeaderCompany col'}>
                        <span>{msg.companyName} </span>
                        <span>{msg.actionMadeByUser} </span>
                    </div>
                    <div className={'MessagePanelHeaderTimestamp col'}>{serverTimestampToBrowserFormat(msg.created)}</div>
                </div>
            </div>
            <div className={'MessagePanelMessageContent'}>
                {msg.specialMsg === true ? <span style={{whiteSpace: 'pre-wrap', color:'darkred'}}>{msg.content}</span> :
                    <span style={{whiteSpace: 'pre-wrap'}}>{msg.content}</span>}
            </div>

        </div>
    );
}

import React, {useState} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";

export function BolCorrectionDialog(props) {
    let msg = '';

    const [correction, setCorrection] = useState('');

    const handleKeyUp = (evt) => {
        if (evt.keyCode === 13 && props.destination)  {
            props.onEnterCorrection(correction);
        }
    }

    return (
        <Dialog header={'REQUEST BOL CORRECTION'}
                visible={props.visible}
                focusOnShow={false}
                contentStyle={{overflow: 'visible'}}
                style={{width: '480px'}} modal={true}
                onHide={props.onHide}>
            <div onKeyUp={handleKeyUp}>
                {props.selectionCount && <div style={{fontWeight: 'bold'}}>{msg}</div>}
                <div className={'DialogLabel'}>Correction Details</div>
                <div>
                    <InputTextarea rows={5}
                                   style={{width: '430px', maxWidth: '430px'}}
                                   value={correction} onChange={(e) => setCorrection(e.target.value)} />
                </div>
                <div style={{textAlign: 'right', marginTop: '10px'}} className={'col-12'}>
                    <Button icon='fa fa-check'
                            disabled={correction.length === 0}
                            className={'p-button-success'}
                            label={'OK'}
                            onClick={() => props.onEnterCorrection(correction)}/>
                    <Button icon={'fa fa-times'}
                            style={{marginLeft: '10px'}}
                            className={'p-button-secondary'}
                            label={'CANCEL'}
                            onClick={props.onHide}/>
                </div>
            </div>
        </Dialog>
    )
}

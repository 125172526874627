
export const findDuplicates = (bargeArray) => {
    const lookup = bargeArray.reduce((arr, e) => {
            arr[e.bargeId] = ++arr[e.bargeId] || 0;
        return arr;
    }, {});

    let uniqueIds = bargeArray.filter(ts => !lookup[ts.bargeId]);
    let duplicatedIds = bargeArray.filter(e => e.bargeId && lookup[e.bargeId]);
    return {
        uniqueIds: uniqueIds,
        duplicatedIds: duplicatedIds
    }
}

function validateBargeIds(barge) {
    barge.bargeId = barge.bargeId.toString().trim();
    let givenBargeId = barge.bargeId;
    if(['R', 'r'].includes(barge.bargeId.slice(-1))) {
        // no R at the end of barge ID
        barge.bargeId = barge.bargeId.slice(0, -1);
    }
    if(['B', 'b'].includes(barge.bargeId.slice(-1))) {
        let slicedLetter = barge.bargeId.slice(-1);
        // No space before 'B' at the end of Barge Number
        // (eg. 'Test 123 B' - 'Test 123B', 'Test 123 B34' - 'Test 123 B34')
        barge.bargeId = barge.bargeId.slice(0, -1).trim() + slicedLetter;
    }

    if((barge.bargeId.match(/\s/g) || []).length > 1){
        // More than one space - load it as such (eg.ABC 005374 567)
        return barge;
    } else {
            // 1. None of Alphabet chracters allowed after Barge Number other than B (eg. ABC005374RTF - Remove 'RTF' and add one space : ABC 005374)
            // 2. Aplha characters & Barge numbers, but no space - Add a space between alphabets and numbers(eg.ABC05374 : ABC 05374)
            // 3. If there is no alphabets - load it as such (eg.05374 : 05374)
            let regexStr = barge.bargeId.match(/[a-z]+|[^a-z]+/gi);
            let bargeId = "";
            let numberReached = false;

            for (let index = 0; index < regexStr.length; index++) {
                if (isNaN(regexStr[index])) {
                    if (numberReached && !['B', 'b'].includes(regexStr[index])) {
                        break;
                    }
                    bargeId += regexStr[index];
                    if (((barge.bargeId.match(/\s/g) || []).length === 0  || regexStr[1].charAt(0) !== " " )
                        && index === 0) {
                            bargeId += " ";
                    }
                } else {
                    bargeId += regexStr[index];
                    numberReached = true;
                }
            }
        barge.givenBargeId = givenBargeId;
        barge.bargeId = bargeId;
    }

    return barge;
}

export const mandatoryFieldValidation = (bargeArray, carriersRefData) => {
    let bargeErrors = [];
    let barges = [];
    for(let barge of bargeArray) {
        if(!barge.bargeId || barge.bargeId.toString().trim() === ''
            || !barge.carrierCode || barge.carrierCode.toString().trim() === '') {
            barge.error = 'BargeId and CarrierCode can not be null or empty';
            bargeErrors.push(barge);
            continue;
        } else {
            //CarrierCode validation
            const carrierRec = carriersRefData.find(c => c.id.trim() === barge.carrierCode.toString().toUpperCase().trim());
            if (!carrierRec) {
                barge.error = `Carrier does not exist '${barge.carrierCode}'`;
                bargeErrors.push(barge);
                continue;
            }
        }
        barges.push(barge);
    }

    return {
        barges : barges,
        validationErrors: bargeErrors
    };

}

export const validateAndBuildData = (bargeArray, ignoreValidation) => {
    const data = bargeArray.reduce(function(result, barge) {
        let bargeData = barge;
        if(!ignoreValidation) {
            bargeData = validateBargeIds(barge);
        }
            result.push({
                givenBargeId: bargeData.givenBargeId ? bargeData.givenBargeId.toString() : barge.bargeId,
                id: bargeData.bargeId.toString().trim(),
                search: bargeData.bargeId.toString().toUpperCase().trim(),
                carrierCode: bargeData.carrierCode.toString().toUpperCase().trim()
            });
        return result;
    }, []);

    return data;
}

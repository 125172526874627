
import {CACHE_REF_DATA} from './Actions';


const initialState = {
};


export function referenceDataReducer(state = initialState, action) {
    switch(action.type) {
        case CACHE_REF_DATA:
            return {
                ...state,
                [action.payload.groupId]: action.payload.records
            };
        default:
            return state;
    }
}

import React, {Component} from 'react';
import {Route} from "react-router-dom";
import Inbox from "./pages/Inbox/Inbox";
import BargeInfo from "./pages/BargeInfo/BargeInfo";
import {connect} from "react-redux";
import AdminDataLoad from "./pages/Admin/AdminDataLoad";
import OnboardUsers from "./pages/Admin/OnboardUsers";
import AdminDashboard from "./pages/Admin/Dashboard";
import UserManagement from "./pages/Admin/UserManagement";
import ReferenceDataManagement from "./pages/Admin/ReferenceDataManagement";
import CompanyManagement from "./pages/Admin/CompanyManagement";

class AppRoutes extends Component {
    render() {
        let isEssDocsAdmin = false;

        if (this.props.user) {
            isEssDocsAdmin = this.props.user.roles && (this.props.user.roles.indexOf('ESSDOCSADMIN') >= 0);
        }
        return (
            <div style={this.props.style} className={this.props.className}>
                <Route exact path="/" component={Inbox}/>
                <Route exact path="/inbox" component={Inbox}/>
                <Route exact path="/bargeinfo/:uuid/:tsuuid" component={BargeInfo}/>
                {isEssDocsAdmin && <Route exact path="/admindataload" component={AdminDataLoad}/>}
                {isEssDocsAdmin && <Route exact path="/onboardusers" component={OnboardUsers}/>}
                {isEssDocsAdmin && <Route exact path="/admindashboard" component={AdminDashboard}/>}
                {isEssDocsAdmin && <Route exact path="/usermanagement" component={UserManagement}/>}
                {isEssDocsAdmin && <Route exact path="/companymanagement" component={CompanyManagement}/>}
                {isEssDocsAdmin && <Route exact path="/refdatamanagement" component={ReferenceDataManagement}/>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.authenticated
    }
};

export default connect(mapStateToProps)(AppRoutes);

import React, {Component} from 'react';
import './Messages.css';
import {Message} from "./Message";


class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessages: true,
            showActivities: true,
            showCreateMessageDialog: false,
            selectedFilterOptionIdx: 0
        };
        this.handleAddMessage = this.handleAddMessage.bind(this);
        this.handleFilterClick = this.handleFilterClick.bind(this);

        this.filterOptions = [
            {id: 0, label: 'All', value: 'ALL', command: this.handleFilterClick},
            {id: 1, label: 'System Records', value: 'SYSTEM', command: this.handleFilterClick},
            {id: 2, label: 'User Messages', value: 'NOTE', command: this.handleFilterClick}
        ];
    }


    handleFilterClick(evt) {
        this.setState({selectedFilterOptionIdx: evt.item.id});
    }


    handleAddMessage(msg) {
        this.setState({showCreateMessageDialog: false});
        this.props.onAddMessage(msg);
    }

    getMessageRenderer(key, msg) {
        const filter = this.filterOptions[this.state.selectedFilterOptionIdx].value;
        if (filter !== 'ALL') {
            if (filter !== msg.type) return null;
        }

        return (
            <Message key={key} message={msg}/>
        )
    }

    render() {
        return (
            <div className={'MessagesComponent'}>
                {/*<Menu appendTo={document.body}*/}
                {/*      model={this.filterOptions}*/}
                {/*      popup={true}*/}
                {/*      ref={el => this.menu=el} />*/}
                {/*{showCreateMessageDialog && <AddMessageDialog*/}
                {/*    visible={showCreateMessageDialog}*/}
                {/*    onHide={() => this.setState({showCreateMessageDialog: false})}*/}
                {/*    onAddMessage={this.handleAddMessage}/>}*/}
                {/*<div className={'p-grid p-nogutter MessagesComponentToolbar'}>*/}
                {/*    <div className={'p-col-fixed'}>*/}
                {/*        <div>*/}
                {/*            <i style={{marginRight: '3px', color: '#444'}} className={'fa fa-filter'}/>*/}
                {/*            <Button label={this.filterOptions[selectedFilterOptionIdx].label}*/}
                {/*                    iconPos={'right'}*/}
                {/*                    icon="fa fa-caret-down"*/}
                {/*                    onClick={(event)=>this.menu.toggle(event)}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div style={{textAlign: 'right'}} className={'p-col'}>*/}
                {/*        <Button onClick={() => this.setState({showCreateMessageDialog: true})} icon='fa fa-plus'*/}
                {/*                label={'Message'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={'MessagesComponentMessageList'}>
                    {this.props.messages.map((m, idx) => this.getMessageRenderer(idx, m))}
                </div>
            </div>
        );
    }
}

export default Messages;

import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import './CreateBargeDialog.css';
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {BROWSER_DATE_FORMAT, dateToServerFormat} from "@essdocs/ngfashared/shared/FormatUtils";
import {FieldLabel, ErrorMessages} from "@essdocs/commonui";
import {AutoComplete} from "primereact/autocomplete";
import ServiceFactory from "../../services/ServiceFactory";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {ProgressSpinner} from "primereact/progressspinner";

class CreateBargeDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shipperSuggestions: [],
            carrierSuggestions: [],
            commoditySuggestions: [],
            selection: {},
            originLocations: [],
            commodities: [],
            carriers: [],
            bargeIdSuggestions: [],
            errors: [],
            showSpinner: false
        };

        this.refDataService = ServiceFactory.instance().createReferenceDataService();

        this.suggestShippers = this.suggestShippers.bind(this);
        this.handleCarrierUpdate = this.handleCarrierUpdate.bind(this);
        this.handleShipperUpdate = this.handleCarrierUpdate.bind(this);
        this.handleCommodityUpdate = this.handleCommodityUpdate.bind(this);
        this.handleQuantityUnitUpdate = this.handleQuantityUnitUpdate.bind(this);
        this.suggestBargeIds = this.suggestBargeIds.bind(this);
        this.handleUpdateBargeId = this.handleUpdateBargeId.bind(this);
        this.handleBlDateUpdate = this.handleBlDateUpdate.bind(this);
        this.validate = this.validate.bind(this);
        this.handleCreateBargeClick = this.handleCreateBargeClick.bind(this);
    }

    componentDidMount() {
        this.loadReferenceData();
        this.bargeCreated = false;
    }

    async loadReferenceData() {
        const originLocations = await this.refDataService.readAll(ReferenceDataType.OriginLocations);
        const commodities = await this.refDataService.readAll(ReferenceDataType.Commodities);
        const carriers = await this.refDataService.readAll(ReferenceDataType.Carriers);

        commodities.forEach(c => c.displayName = c.id + ' ' + c.commodity);

        const uoms = this.refDataService.deriveUnitsOfMeasure(commodities);
        this.setState(
            {
                originLocations: originLocations,
                commodities: commodities,
                quantityUnits: uoms,
                carriers: carriers
            });
    }

    async suggestShippers(e) {
        const results =  await this.refDataService.search(ReferenceDataType.Shippers, e.query);
        this.setState({shipperSuggestions: results});
    }

    async suggestBargeIds(e) {
        const results =  await this.refDataService.search(ReferenceDataType.Barges, e.query);
        this.setState({bargeIdSuggestions: results});
    }

    async handleUpdateBargeId(e) {
        this.setState({errors : []});
         if (e.target.value.id) {
             const bargeId = e.target.value.id;
             const data = {bargeId: bargeId};
             const carrierCode = e.target.value.carrierCode;
             const carrier = this.state.carriers.find(c => c.id.trim() === carrierCode.trim());

             if (carrier) {
                 if(this.props.loggedInUser
                     && this.props.loggedInUser.type === 'CARRIER'
                     && this.props.loggedInUser.company !== carrierCode) {
                     this.props.onUpdateData(data);
                     this.setState({errors: [`BargeId '${bargeId}' does not belong to Carrier '${this.props.loggedInUser.companyName}'`]});
                     this.bargeCreated = false;
                     return;
                 }
                data.carrier = carrierCode;
                data.carrierName = carrier.carrierName;
             } else {
                 console.error('carrier not found:' + carrierCode);
             }
             this.props.onUpdateData(data);
         } else {
             const data = {bargeId: e.target.value};
             this.props.onUpdateData(data);
         }
    }

    handleBlDateUpdate(e) {
        this.setState({errors : []});
        if (e.target.value) {
            this.props.onUpdateData({blDate: e.target.value})
        }
    }

    handleShipperUpdate(e) {
        if (e.target.value.shipperCode) {
            const val = e.target.value;
            this.props.onUpdateData({shipper: val.companyCode, shipperName: val.companyName});
        }
    }

    handleQuantityUnitUpdate(e) {
        if (e.target.value) {
            const id = e.target.value;
            const unit = this.state.quantityUnits.find(c => c.id === id);
            if (unit) {
                this.props.onUpdateData({quantityUnitAbbr: id, quantityUnit: unit.name});
            }
        }
    }

    handleCarrierUpdate(e) {
        if (e.target.value) {
            const id = e.target.value;
            const carrier = this.state.carriers.find(c => c.id === id);
            if (carrier) {
                this.props.onUpdateData({carrier: id, carrierName: carrier.carrierName});
            }
        }
    }

    handleCommodityUpdate(e) {
        if (e.target.value) {
            const id = e.target.value;
            const commodity = this.state.commodities.find(c => c.id === id);
            if (commodity) {
                const data = {commodity: commodity.id, commodityName: commodity.commodity};
                data.quantityUnit = commodity.uom;
                data.quantityUnitAbbr = commodity.uomAbbreviation;
                this.props.onUpdateData(data);
            }
        }
    }

    handleCreateBargeClick() {
        if (this.bargeCreated) return;
        this.bargeCreated = true;
        this.setState({showSpinner: true});
        this.validate();
    }

    async validate() {
        const result =  await this.refDataService.readRecordById(ReferenceDataType.Barges, this.props.data.bargeId);
        if (!result.id) {
            this.setState({errors: ['Invalid Barge ID'], showSpinner: false});
            this.bargeCreated = false;
            return;
        }
        if (parseFloat(this.props.data.quantity.split(',').join('')) <= 0) {
            this.setState({errors: ['Quantity must be greater than 0'], showSpinner: false});
            this.bargeCreated = false;
            return;
        }

        let blDate = dateToServerFormat(new Date(this.props.data.blDate));
        const inboxService = ServiceFactory.instance().createInboxService();
        let existingBarges = await inboxService.findBargeByBargeId(this.props.data.bargeId, blDate);
        if(existingBarges && existingBarges.length > 0) {
            if(this.props.editMode) {
                for(let barge of existingBarges) {
                    if(barge.uuid !== this.props.data.uuid) {
                        this.setState({errors: [`Please note that there is a transaction with this Barge ID and BOL Date in the solution already. Please create a transaction with different Barge ID and/or BOL Date`],
                            showSpinner: false});
                        this.bargeCreated = false;
                        return;
                    }
                }
            } else {
                this.setState({errors: [`Please note that there is a transaction with this Barge ID and BOL Date in the solution already. Please create a transaction with different Barge ID and/or BOL Date`],
                    showSpinner: false});
                this.bargeCreated = false;
                this.showSpinner = false;
                return;
            }
        }
        this.setState({errors: [], showSpinner: false});
        this.props.onCreateBarge();
    }

    render() {
        const allowCreate = this.props.data.bargeId &&
                            this.props.data.commodity &&
                            this.props.data.shipper &&
                            this.props.data.blDate &&
                            this.props.data.blDate instanceof Date &&
                            this.props.data.origin &&
                            this.props.data.quantity &&
                            parseFloat(this.props.data.quantity.split(',').join('')) > 0 &&
                            this.props.data.quantityUnit &&
                            this.props.data.carrier &&
                            this.state.errors.length === 0;

        const {editMode, actionId} = this.props;

        return (

            <Dialog header={editMode ? "EDIT BARGE" : "CREATE BARGE"}
                    visible={this.props.visible}
                    focusOnShow={false}
                    contentStyle={{overflow: 'visible'}}
                    style={{width: '550px'}} modal={true}
                    onHide={this.props.onHide}>
                <div style={{padding: '5px'}} className={'grid'}>
                    <ErrorMessages errors={this.state.errors}/>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Shipper"/>
                        <AutoComplete value={this.props.data.shipperName}
                                      style={{display: 'block', width: '100%'}}
                                      inputStyle={{width: '100%'}}
                                      disabled={true}
                                      minLength={2}
                                      placeholder={'Start typing the name of a shipper'}
                                      field={'companyName'}
                                      onChange={this.handleShipperUpdate}
                                      suggestions={this.state.shipperSuggestions}
                                      completeMethod={this.suggestShippers} />
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Barge ID"/>
                        <AutoComplete value={this.props.data.bargeId}
                                      style={{display: 'block', width: '100%'}}
                                      inputStyle={{width: '100%'}}
                                      autoFocus={true}
                                      minLength={2}
                                      field={'id'}
                                      onChange={this.handleUpdateBargeId}
                                      suggestions={this.state.bargeIdSuggestions}
                                      completeMethod={this.suggestBargeIds} />
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Commodity"/>
                        <Dropdown value={this.props.data.commodity}
                                  disabled={actionId && actionId === 'carrierEditBarge' ? true : false}
                                  style={{width: '100%'}}
                                  options={this.state.commodities}
                                  optionValue={'id'}
                                  filter={true}
                                  optionLabel={'displayName'}
                                  onChange={this.handleCommodityUpdate}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Bill of Lading Date"/>
                        <Calendar showIcon={true}
                                  style={{width: '100%'}}
                                  inputStyle={{width: 'calc(100% - 28px)'}}
                                  showOnFocus={false}
                                  dateFormat="mm/dd/yy"
                                  placeholder={BROWSER_DATE_FORMAT}
                                  value={this.props.data.blDate}
                                  showButtonBar
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2021:2040"
                                  onChange={this.handleBlDateUpdate}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Quantity"/>
                        <InputText value={this.props.data.quantity}
                                   keyfilter={'money'}
                                   tabIndex={5}
                                   style={{textAlign: 'right', width: '100%'}}
                                   onChange={(e) => this.props.onUpdateData({quantity: e.target.value})}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Quantity Unit"/>
                        <Dropdown value={this.props.data.quantityUnitAbbr}
                                  options={this.state.quantityUnits}
                                  optionValue={'id'}
                                  optionLabel={'name'}
                                  style={{width: '100%'}}
                                  onChange={this.handleQuantityUnitUpdate}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Origin"/>
                        <Dropdown value={this.props.data.origin}
                                  style={{width: '100%'}}
                                  scrollHeight={'100px'}
                                  options={this.state.originLocations}
                                  optionLabel={'id'}
                                  optionValue={'id'}
                                  filter={true}
                                  onChange={(e) => this.props.onUpdateData({origin: e.value})}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={true} value="Carrier"/>
                        <Dropdown value={this.props.data.carrier}
                                  disabled = {actionId && actionId === 'carrierEditBarge' ? true : false}
                                  style={{width: '100%'}}
                                  scrollHeight={'100px'}
                                  options={this.state.carriers}
                                  optionValue={'id'}
                                  optionLabel={'carrierName'}
                                  filter={true}
                                  onChange={this.handleCarrierUpdate}/>
                    </div>
                    <div style={{textAlign: 'right', padding: '0.75rem 0.50rem 0.75rem 0'}} className={'col-12'}>
                        {this.bargeCreated && this.state.showSpinner && <ProgressSpinner style={{width: '25px', height: '20px'}}
                                                                                         strokeWidth="8"
                                                                                         fill="#EEEEEE"
                                                                                         animationDuration=".8s"/>}
                        <Button disabled={!allowCreate || this.bargeCreated}
                                icon={editMode ? 'fa fa-save' : 'fa fa-plus'}
                                className={'p-button-success'}
                                label={editMode ? 'SAVE' :'CREATE'}
                                onClick={this.handleCreateBargeClick}/>
                        <Button icon={'fa fa-times'}
                                style={{marginLeft: '10px'}}
                                className={'p-button-secondary'}
                                label={'CANCEL'}
                                onClick={this.props.onHide}/>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default CreateBargeDialog;


import React, {Fragment} from 'react';
import {EssDocsPage, EssDocsComponent} from "@essdocs/commonui";
import ServiceFactory from "../../services/ServiceFactory";
import {withRouter} from "react-router-dom";
import {createDisplayer} from "../../displayers/BargeDisplayer";
import BargeDetails from "../../components/BargeDetails/BargeDetails";
import './BargeInfo.css';
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import {connect} from "react-redux";
import TradeString from "../../components/TradeString/TradeString";
import {BargeDetailsPanel} from "./BargeDetailsPanel";
import DocumentList from "../../components/DocumentList/DocumentList";
import {
    dateToServerFormat,
    formatNumber,
    serverDateToBrowserFormat,
    serverDateToDate
} from "@essdocs/ngfashared/shared/FormatUtils";
import WorkflowService from "@essdocs/ngfashared/workflow/WorkflowService";
import {defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import OwnerSelectDialog from "../../components/OwnerSelectorDialog/OwnerSelectDialog";
import ProposeDestinationDialog from "../../components/ProposeDestinationDialog/ProposeDestinationDialog";
import UploadDialog from "../../components/UploadDialog/UploadDialog";
import Messages from "../../components/Messages/Messages";
import AddBolDialog from "../../components/AddBolDialog/AddBolDialog";
import {getTradeStringEntryFromUuid} from "@essdocs/ngfashared/shared/BargeUtil";
import DocumentViewer from "../../components/Documents/DocumentViewer";
import {store} from "../../redux/Store";
import {invalidateSearchResults, searching} from "../../redux/Actions";
import {
    getDocumentTypeByDocTypeAndSubType
} from "@essdocs/ngfashared/shared/ReferenceData/DocumentTypes";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {createCompleteTripMessage} from "../../shared/MessageUtil";
import {ProposeFeeDialog} from "../../components/ProposeFeeDialog/ProposeFeeDialog";
import {BolCorrectionDialog} from "../../components/BolCorrectionDialog/BolCorrectionDialog";
import {resetTimeout} from "../../shared/TimeoutUtil";
import {deepCopy} from "@essdocs/ngfashared/shared/CopyUtil";
import CreateBargeDialog from "../../components/CreateBarge/CreateBargeDialog";
import TransferProductDialog from "../../components/TransferProductDialog/TransferProductDialog";

class BargeInfo extends EssDocsComponent {

    constructor(props) {
        super(props);
        this.state = {
            barge: {},
            selectedDoc: {},
            docs: [],
            selectedPdf: null,
            selectedCounterparty: '',
            selectedAction: '',
            showSelectCounterPartyDialog: false,
            confirmationPopupVisible:  false,
            confirmationAcceptAction: null,
            confirmationMessage: '',
            showProposeDestinationDialog: false,
            showUploadDialog: false,
            messages: [],
            showAddBolDialog: false,
            actionsMenu: [],
            showSelectFeeDialogVisible: false,
            okayMessageVisible: false,
            okayMessage: '',
            busy: false,
            documentLoading: false,
            showBolCorrectionDialog: false,
            showEditBargeDialog: false,
            showTransferProductDialog: false,
            editableBargeRecord: {},
            bargeEditMode: false,
            documentDetails: {},
            enableTransferDocument: false,
            transferredBargeFields: {}
        };

        this.wfService = new WorkflowService(defaultWorkflow);
        this.inboxService = ServiceFactory.instance().createInboxService();
        this.busy = false;

        this.renderHeader = this.renderHeader.bind(this);

        this.actionHandler = this.actionHandler.bind(this);
        this.handleSelectDoc = this.handleSelectDoc.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleHideUploadDialog = this.handleHideUploadDialog.bind(this);
        this.handleUploadDocument = this.handleUploadDocument.bind(this);
        this.handleSelectDestination = this.handleSelectDestination.bind(this);
        this.handleSelectCounterparty = this.handleSelectCounterparty.bind(this);
        this.handleApplyOutAccept = this.handleApplyOutAccept.bind(this);
        this.handleApplyOutReject = this.handleApplyOutReject.bind(this);
        this.handleAddMessage = this.handleAddMessage.bind(this);
        this.handleAddBol = this.handleAddBol.bind(this);
        this.handleBolReqAccept = this.handleBolReqAccept.bind(this);
        this.handleBolReqReject = this.handleBolReqReject.bind(this);
        this.handleIssueSignBol = this.handleIssueSignBol.bind(this);
        this.handleEndorseAndTransfer = this.handleEndorseAndTransfer.bind(this);
        this.handleAcceptTransfer = this.handleAcceptTransfer.bind(this);
        this.handleCompleteTrip = this.handleCompleteTrip.bind(this);
        this.handleCarrierCompleteTrip = this.handleCarrierCompleteTrip.bind(this);
        this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
        this.handleTransferDocumentDelete = this.handleTransferDocumentDelete.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleBustConfirm = this.handleBustConfirm.bind(this);
        this.handleTransferConfirm = this.handleTransferConfirm.bind(this);
        this.handleTransferProduct = this.handleTransferProduct.bind(this);
        this.handleTransferBargeDocumentUploadConfirm = this.handleTransferBargeDocumentUploadConfirm.bind(this);
        this.upload = this.upload.bind(this);
        this.handleBust = this.handleBust.bind(this);
        this.reconsignmentTransfer = this.reconsignmentTransfer.bind(this);
        this.handleReconsignmentTransfer = this.handleReconsignmentTransfer.bind(this);
        this.requestRecon = this.requestRecon.bind(this);
        this.handleRequestRecon = this.handleRequestRecon.bind(this);
        this.reconReqAccept = this.reconReqAccept.bind(this);
        this.handleReconReqAccept = this.handleReconReqAccept.bind(this);
        this.reconReqReject = this.reconReqReject.bind(this);
        this.handleReconReqReject = this.handleReconReqReject.bind(this);
        this.reconTransferAccept = this.reconTransferAccept.bind(this);
        this.handleReconTransferAccept = this.handleReconTransferAccept.bind(this);
        this.proposeFee = this.proposeFee.bind(this);
        this.handleProposeFee = this.handleProposeFee.bind(this);
        this.handleAcceptFee = this.handleAcceptFee.bind(this);
        this.acceptFee = this.acceptFee.bind(this);
        this.handleRejectFee = this.handleRejectFee.bind(this);
        this.rejectFee = this.rejectFee.bind(this);
        this.handleEnterCorrection = this.handleEnterCorrection.bind(this);
        this.handleCorrectionReviewed = this.handleCorrectionReviewed.bind(this);
        this.handleBolReturn = this.handleBolReturn.bind(this);
        this.handleBolCorrectionAccept = this.handleBolCorrectionAccept.bind(this);
        this.handleBolCorrectionReject = this.handleBolCorrectionReject.bind(this);
        this.handleBolVoidRequest = this.handleBolVoidRequest.bind(this);
        this.handleBolVoidAccept = this.handleBolVoidAccept.bind(this);
        this.handleRemoveBarge = this.handleRemoveBarge.bind(this);
        this.handleUpdateNewBargeData = this.handleUpdateNewBargeData.bind(this);
        this.updateBarge = this.updateBarge.bind(this);
        this.hideEditBargeDialog = this.hideEditBargeDialog.bind(this);
        this.editBargeAction = this.editBargeAction.bind(this);
    }

    async componentDidMount() {
        const uuid = this.props.match.params.uuid;
        const tsuuid = this.props.match.params.tsuuid;
        if (uuid && tsuuid) {
            this.loadBarge(uuid, tsuuid);
        }
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        this.destinations = await refDataService.readAll(ReferenceDataType.Destinations);
        resetTimeout();
    }


    handleBustConfirm() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBust,
            confirmationMessage: 'Are you sure you want to bust this barge?'});
    }

    handleTransferConfirm() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleTransferProduct,
            confirmationMessage: 'Are you sure you want to transfer this barge?'});
    }

    handleBust() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bust', {});
    }

    async handleTransferProduct() {
        await this.reloadBarge();
        this.setState({selectedAction: 'transferProduct', confirmationPopupVisible: false, busy: false});
        await this.editBargeAction('', 'transferProduct');
    }

    filterMessagesForReconsignment(barge, messages, reconsignment) {
        return messages.filter(msg => {
            const ts = barge.tradeString.find(ts => ts.uuid === msg.tradeStringUuid);
            return ts && ts.reconsignment === reconsignment;
        });
    }


    async loadBarge(uuid, tradeStringUuid, selectedDocId) {
        const inboxService = ServiceFactory.instance().createInboxService();
        const {user} = this.props;
        const response = await inboxService.findBarge(uuid, true, user);
        const barge = response.barge;

        const ts = tradeStringUuid !== '0' ? getTradeStringEntryFromUuid(barge.tradeString, tradeStringUuid) : '';
        if (ts === undefined) {
            throw new Error('Failed to find trade string with uuid: ' + tradeStringUuid + '. Barge: ' + uuid);
        }

        const messages = this.filterMessagesForReconsignment(response.barge, response.messages, ts.reconsignment)

        if (ts.owner !== user.company && user.type !== 'CARRIER') return;
        if (barge != null) {
            const actionsMenu = [];
            const canBust = this.wfService.canBust(user, barge, tradeStringUuid);
            if (canBust) {
                actionsMenu.push( {label: 'Bust', icon: 'fa fa-bomb' ,command:()=>{this.handleBustConfirm()}});
            }

            const canTransferProduct = this.wfService.canTransferProduct(user, barge, tradeStringUuid);
            if(canTransferProduct) {
                actionsMenu.push({
                    label: 'Transfer Product', icon: 'fa fa-exchange', command: () => {
                        this.handleTransferConfirm()
                    }
                });
            }

            const bargeDisplayer = createDisplayer(this.props.user, barge, this.actionHandler,
                tradeStringUuid, false, this.state.busy);
            const canOtherActions = canBust || canTransferProduct; //this.wfService.canUploadDocument(this.props.user, barge);
            this.setState({barge: bargeDisplayer, canOtherActions: canOtherActions,
                actionsMenu: actionsMenu, messages: messages});
            this.loadDocList(barge, tradeStringUuid, selectedDocId, ts.reconsignment);
            this.setState({busy: false});
            this.busy = false;
        }
        resetTimeout();
    }

    //---actions start

    actionHandler(id, selection) {
        switch (id) {
            case 'editBarge':
            case 'carrierEditBarge':
                this.editBargeAction(selection, id);
                break;
            case 'removeBarge':
                this.removeBarge(selection);
                break;
            case 'applyOut':
            case 'bustedApplyOut':
            case 'updatedApplyOut':
                this.applyOutAction(id);
                break;
            case 'requestBol':
                this.requestBolAction();
                break;
            case 'appliedOutAccept':
                this.applyOutAccept();
                break;
            case 'appliedOutReject':
                this.applyOutReject();
                break;
            case 'proposeDest':
            case 'bustedProposeDest':
                this.proposeDestination(id);
                break;
            case 'upload':
                this.upload();
                break;
            case 'bolReqAccept':
                this.bolReqAccept();
                break;
            case 'bolReqReject':
                this.bolReqReject();
                break;
            case 'issueSignBol':
                this.issueSignBol();
                break;
            case 'endorseTransferBol':
                this.endorseAndTransfer();
                break;
            case 'acceptTransfer':
                this.acceptTransfer();
                break;
            case 'completeTrip':
                this.completeTrip();
                break;
            case 'carrierCompleteTrip':
                this.carrierCompleteTrip();
                break;
            case 'reconTransfer':
                this.reconsignmentTransfer();
                break;
            case 'requestRecon':
                this.requestRecon();
                break;
            case 'reconReqAccept':
                this.reconReqAccept();
                break;
            case 'reconReqReject':
                this.reconReqReject();
                break;
            case 'reconTransferAccept':
                this.reconTransferAccept();
                break;
            case 'proposeFee':
                this.proposeFee();
                break;
            case 'rejectFee':
                this.rejectFee();
                break;
            case 'acceptFee':
                this.acceptFee();
                break;
            case 'bolCorrection':
                this.bolCorrectionRequest();
                break;
            case 'bolCorrectionReviewed':
                this.bolCorrectionReviewed();
                break;
            case 'bustedReturnBol':
                this.bolReturn();
                break;
            case 'bolCorrectionAccept':
                this.bolCorrectionAccept();
                break;
            case 'bolCorrectionReject':
                this.bolCorrectionReject();
                break;
            case 'bolVoidRequest':
                this.bolVoidRequest(selection);
                break;
            case 'bolVoidAccept':
                this.bolVoidAccept(selection);
                break;
            default:
                console.log('Action Handler:' + id);
        }
    }

    bolReturn() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBolReturn,
            confirmationMessage: 'Are you sure you want to return this BOL to the previous party in the trade string?'
        });
    }

    bolCorrectionAccept() {

        const barge = this.state.barge;
        const canAttach = this.wfService.canAttachDocument(this.props.user, barge.record,
            'bolcorrection', 'bolcorrection', barge.tradeStringUuid);
        if (canAttach) {
            this.setState(
                {
                    okayMessageVisible: true,
                    okayMessage: 'A BOL correction document must be uploaded before the BOL correction request can be accepted. Please note to upload BOL Correction after Busting please click \'Upload\' and pick \'BOL Correction Requested\' type'
                });
            return;
        }

        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBolCorrectionAccept,
            confirmationMessage: 'Are you sure you want to accept this BOL correction?'
        });
    }

    bolCorrectionReject() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBolCorrectionReject,
            confirmationMessage: 'Are you sure you want to reject this BOL correction?'
        });
    }

    bolVoidRequest() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBolVoidRequest,
            confirmationMessage: 'Are you sure you want to submit a request to void this BOL?'
        });
    }

    bolVoidAccept() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBolVoidAccept,
            confirmationMessage: 'Are you sure you want to accept this BOL void request?'
        });
    }

    async handleBolReturn() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        await this.executeAction('bustedReturnBol', {}, false);
        store.dispatch(searching(true));
        this.nav('/inbox');
    }

    handleBolCorrectionAccept() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolCorrectionAccept', {});
    }

    handleBolCorrectionReject() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolCorrectionReject', {});
    }

    bolCorrectionReviewed() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleCorrectionReviewed,
            confirmationMessage: 'Are you sure you want to mark this BOL correction request as reviewed?'
        });
    }

    handleCorrectionReviewed() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolCorrectionReviewed', {});
    }

    bolCorrectionRequest() {
        this.setState({showBolCorrectionDialog: true});
    }

    handleEnterCorrection(correctionText) {
        if (this.busy) return;
        this.setState({showBolCorrectionDialog: false});
        this.executeAction('bolCorrection', {correction: correctionText});
    }

    handleBolVoidRequest() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolVoidRequest', {});
    }

    handleBolVoidAccept() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolVoidAccept', {});
        store.dispatch(searching(true));
        this.nav('/inbox');
    }

    reconsignmentTransfer() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleReconsignmentTransfer,
            confirmationMessage: 'Are you sure you want to transfer this reconsignment?'
        });
    }

    handleReconsignmentTransfer() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconTransfer', {});
    }

    requestRecon() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleRequestRecon,
            confirmationMessage: 'Are you sure you want to submit a reconsignment request?'
        });
    }

    handleRequestRecon() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('requestRecon', {});
    }

    reconTransferAccept() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleReconTransferAccept,
            confirmationMessage: 'Are you sure you want to accept the transfer of this reconsignment?'
        });
    }


    proposeFee() {
        if (this.busy) return;
        this.setState({showSelectFeeDialogVisible: true,});
    }

    handleProposeFee(fee) {
        this.setState({showSelectFeeDialogVisible: false});
        this.executeAction('proposeFee', {fee: fee});
    }

    acceptFee() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleAcceptFee,
            confirmationMessage: 'Are you sure you want to accept the reconsignment fee?'
        });
    }

    handleAcceptFee() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: false});
        this.executeAction('acceptFee', {});
    }


    rejectFee() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleRejectFee,
            confirmationMessage: 'Are you sure you want to reject the reconsignment fee?'
        });
    }

    handleRejectFee() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('rejectFee', {});
    }

    handleReconTransferAccept() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconTransferAccept', {});
    }

    reconReqAccept() {
        const barge = this.state.barge;
        /*const canAttach = this.wfService.canAttachDocument(this.props.user, barge.record,
            'reconsignment', 'reconignment', barge.tradeStringUuid);
        */
        const ts = getTradeStringEntryFromUuid(barge.tradeString, barge.tradeStringUuid);
        const existingDocument = barge.record.documents && barge.record.documents.find(d => d.docType === 'reconsignment' && d.reconsignment === ts.reconsignment);
        if (!existingDocument) {
            this.setState(
                {
                    okayMessageVisible: true,
                    okayMessage: 'A reconsignment document must be uploaded before the destination can be accepted'
                });
            return;
        }

        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleReconReqAccept,
            confirmationMessage: 'Are you sure you want to accept the destination for this reconsignment?'
        });
    }

    handleReconReqAccept() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconReqAccept', {});
    }

    reconReqReject() {
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleReconReqReject,
            confirmationMessage: 'Are you sure you want to reject the destination for this reconsignment?'
        });
    }

    handleReconReqReject() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('reconReqReject', {});
    }


    applyOutAction(actionId) {
        if (this.busy) return;
        this.setState({showSelectCounterPartyDialog: true, selectedAction: actionId});
    }

    async editBargeAction(selection, actionId) {
        this.setState({busy : true});
        const response = await this.inboxService.findBarge(this.state.barge.record.uuid, this.state.barge.tradeStringUuid, this.props.user);
        const barge = response.barge;

        if (this.busy) return;
        const rec = deepCopy(barge);
        rec.blDate = serverDateToDate(rec.blDate);
        rec.quantity = rec.quantity && rec.quantity !== 0 ? formatNumber(rec.quantity, 3) : '';
        this.setState({busy: false, editableBargeRecord: rec, bargeEditMode: true, selectedAction: actionId});
        await this.reloadBarge();
        if(actionId === 'transferProduct') {
            this.setState({showTransferProductDialog: true});
        } else {
            this.setState({showEditBargeDialog: true});
        }
    }

    handleUpdateNewBargeData(update) {
        this.setState({editableBargeRecord: {...this.state.editableBargeRecord, ...update}});
    }

    async hideEditBargeDialog() {
        await this.reloadBarge();
        this.setState({busy: false, showEditBargeDialog: false});
    }

    async updateBarge() {
        let barge = this.state.editableBargeRecord;
        const quantityUnformatted = barge.quantity ? barge.quantity.split(',').join('') : '0';
        let quantity = parseFloat(quantityUnformatted);

        if (isNaN(quantity)) {
            quantity = 0;
        }
        const bargeFields = {
            blDate: dateToServerFormat(barge.blDate),
            bargeId: barge.bargeId,
            shipper: barge.shipper,
            shipperName: barge.shipperName,
            carrier: barge.carrier,
            carrierName: barge.carrierName,
            commodity: barge.commodity,
            commodityName: barge.commodityName,
            origin: barge.origin
        };
        bargeFields.quantity = quantity;
        bargeFields.quantityUnit = barge.quantityUnit;
        bargeFields.quantityUnitAbbr = barge.quantityUnitAbbr;

        this.setState({showEditBargeDialog: false, busy: false});

        this.setState({confirmationPopupVisible: false});
        if(this.state.selectedAction === 'transferProduct') {
            this.setState({transferredBargeFields: bargeFields, showTransferProductDialog: false});
            this.handleTransferBargeDocumentUploadConfirm();
        } else {
            await this.executeAction(this.state.selectedAction ? this.state.selectedAction : 'editBarge', bargeFields, true);
        }
    }

    handleTransferBargeDocumentUploadConfirm() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: true,
            confirmationAcceptAction: this.upload,
            enableTransferDocument : true,
            confirmationMessage: (<span>To complete the barge transfer, please upload the <strong> Notification of Transfer </strong> document.</span>)});
    }



    removeBarge(selection) {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            selection: selection,
            confirmationAcceptAction: this.handleRemoveBarge,
            confirmationMessage: (<span>Are you sure you want to <strong style={{color: 'red'}}>DISCARD</strong> this Barge?</span>)
        });
    }

    async handleRemoveBarge() {
        this.setState({confirmationPopupVisible: false});
        await this.executeAction('removeBarge', {}, false);
        store.dispatch(searching(true));
        this.nav('/inbox');
    }

    upload() {
        this.setState({confirmationPopupVisible: false, showUploadDialog: true});
    }

    async handleAddMessage(message) {
        const barge = this.state.barge.record;
        const messageServce = ServiceFactory.instance().createMessageService();
        await messageServce.addMessage(barge.uuid,  message, this.props.user.companyName);
        this.loadMessages(barge.uuid);
    }

    reloadBarge() {
        this.loadBarge(this.state.barge.record.uuid, this.state.barge.tradeStringUuid, 'BARGE');
    }

    async handleHideUploadDialog() {
        //await this.reloadBarge();
        this.setState({busy: false, showUploadDialog: false, enableTransferDocument: false});
    }

    async handleUploadFile(docType, subType, docName, filename, fileb64) {
        const barge = this.state.barge;
        this.setState({showUploadDialog: false});
        let ts = getTradeStringEntryFromUuid(barge.tradeString, barge.tradeStringUuid);
        if(docType === 'reconsignment' && barge.record.documents
            && barge.record.documents.some(d => d.docType === 'reconsignment' && d.reconsignment === ts.reconsignment)) {
            this.setState({
                documentDetails: {
                    docType: docType,
                    subType:subType,
                    docName: docName,
                    filename: filename,
                    fileb64: fileb64
                },
                confirmationPopupVisible: true,
                confirmationAcceptAction: this.handleUploadDocument,
                confirmationMessage: 'Are you sure you want to overwrite existing Reconsignment document?'
            });
        } else {
            await this.handleUploadDocument(docType, subType, docName, filename, fileb64);
        }
    }

    async handleUploadDocument(docType, subType, docName, filename, fileb64) {
        this.setState({showUploadDialog: false, busy: true, confirmationPopupVisible: false, enableTransferDocument: false});
        const barge = this.state.barge;
        const documentService = ServiceFactory.instance().createDocumentService();
        if(!docType || !subType || !fileb64) {
            docType = this.state.documentDetails.docType;
            subType = this.state.documentDetails.subType;
            fileb64 = this.state.documentDetails.fileb64;
        }

        if(docType === 'transferNotification' && !this.state.selectedAction) {
            await documentService.uploadDocument(barge.record.uuid, barge.tradeStringUuid, docType, subType, fileb64, this.documentId);
            this.setState({enableTransferDocument: false, busy: false});
            await this.loadBarge(barge.record.uuid, barge.tradeStringUuid, this.documentId);
            resetTimeout();
        } else if(this.state.selectedAction === 'transferProduct') {
            let actionRes = await this.executeAction(this.state.selectedAction, this.state.transferredBargeFields, false, false);
            let docUuid;
            if(actionRes && actionRes.success) {
                docUuid = await documentService.uploadDocument(barge.record.uuid, barge.tradeStringUuid, docType, subType, fileb64);
            }
            this.setState({busy: false});
            this.busy = false;
            await this.loadBarge(barge.record.uuid, barge.tradeStringUuid, docUuid);
            this.setState({selectedAction: '', enableTransferDocument: false});
            resetTimeout();
        } else {
            const docUuid = await documentService.uploadDocument(barge.record.uuid, barge.tradeStringUuid, docType, subType, fileb64);
            this.setState({busy: false});
            this.busy = false;
            await this.loadBarge(barge.record.uuid, barge.tradeStringUuid, docUuid);
            store.dispatch(invalidateSearchResults());
            resetTimeout();
        }
    }

    proposeDestination(actionId) {
        if (this.busy) return;
        this.setState({showProposeDestinationDialog: true, selectedAction: actionId});
    }

    handleSelectDestination(actionParams) {
        this.setState({showProposeDestinationDialog: false});
        this.executeAction(this.state.selectedAction, actionParams, this.props.user);
    }

    applyOutAccept() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleApplyOutAccept,
            confirmationMessage: 'Are you sure you want to Accept?'});
    }

    applyOutReject() {
        if (this.busy) return;
        this.setState({confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleApplyOutReject,
            confirmationMessage: 'Are you sure you want to Reject?'});
    }

    bolReqAccept() {
        const barge = this.state.barge;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBolReqAccept,
            confirmationMessage: (<span>Are you sure you want to <strong style={{color: 'green'}}>Accept
            </strong> the proposed destination of <strong>{barge.destination}</strong>?</span>)
        });
    }

    bolReqReject() {
        if (this.busy) return;
        const barge = this.state.barge;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleBolReqReject,
            confirmationMessage: (
                <span>Are you sure you want to <strong style={{color: 'red'}}>Reject
                </strong> the proposed destination of <strong>{barge.destination}</strong>?</span>)
        });
    }

    issueSignBol() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleIssueSignBol,
            confirmationMessage: 'Are you sure you want to issue and sign the BOL?'
        });
    }

    endorseAndTransfer() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleEndorseAndTransfer,
            confirmationMessage: 'Are you sure you want to endorse and transfer this BOL?'
        });
    }


    acceptTransfer() {
        if (this.busy) return;
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleAcceptTransfer,
            confirmationMessage: 'Are you sure you want to accept the transfer of this BOL?'
        });
    }

    completeTrip() {
        if (this.busy) return;
        const msg = createCompleteTripMessage(this.state.barge.record);
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleCompleteTrip,
            confirmationMessage: msg
        });
    }

    carrierCompleteTrip() {
        if (this.busy) return;
        const msg = createCompleteTripMessage(this.state.barge.record);
        this.setState({
            confirmationPopupVisible: true,
            confirmationAcceptAction: this.handleCarrierCompleteTrip,
            confirmationMessage: msg
        });
    }

    async requestBolAction() {
        if (this.busy) return;
        this.setState({showAddBolDialog: true});
        await this.reloadBarge();
    }

    async executeAction(action, actionParams = {}, reloadBarge = true, resetBusy = true) {
        if (this.busy) return;
        this.busy = true;
        this.setState({busy: true});
        const barge = this.state.barge;
        const bargeIdList =  [{uuid: barge.record.uuid, tradeStringUuid: barge.tradeStringUuid}];
        let response = await this.inboxService.executeActionAndUpdateBarges(bargeIdList, action, actionParams);
        if (reloadBarge) this.reloadBarge();
        if(resetBusy) {
            this.setState({selectedPdf: null, busy: false});
            this.busy = false;
        }
        store.dispatch(invalidateSearchResults());
        resetTimeout();
        return response;
    }

    handleApplyOutAccept() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('appliedOutAccept', {});
    }

    async handleApplyOutReject() {
        this.setState({confirmationPopupVisible: false});
        await this.executeAction('appliedOutReject', {}, false);
        store.dispatch(searching(true));
        this.nav('/inbox');
    }

    handleSelectCounterparty(shipper) {
        this.setState({showSelectCounterPartyDialog: false});
        this.executeAction(this.state.selectedAction, {shipperCode: shipper.id, shipperName: shipper.shipperName});
    }


    handleAddBol(formFields) {
        this.setState({showAddBolDialog: false});
        this.executeAction('requestBol', formFields);
    }

    handleBolReqAccept() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolReqAccept', {}, this.props.user);
    }

    handleBolReqReject() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('bolReqReject', {}, this.props.user);
    }

    handleIssueSignBol() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('issueSignBol', {}, this.props.user);
    }

    handleEndorseAndTransfer() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('endorseTransferBol', {}, this.props.user);
    }

    handleAcceptTransfer() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('acceptTransfer', {}, this.props.user);
    }

    handleCompleteTrip() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('completeTrip', {}, this.props.user);
    }

    handleCarrierCompleteTrip() {
        this.setState({confirmationPopupVisible: false});
        this.executeAction('carrierCompleteTrip', {}, this.props.user);
    }

    //----Actions End


    handleSelectDoc(doc) {
        this.setState({selectedDoc: doc});
        if (doc.id !== 'BARGE') {
            this.loadPdf(doc.id, doc.docType);
        }
    }

    async loadPdf(docUuid, docType) {
        const documentService = ServiceFactory.instance().createDocumentService();
        const barge = this.state.barge;
        this.setState({documentLoading: true});
        const pdf = await documentService.getDocument(barge.record.uuid, docUuid, docType, barge.tradeStringUuid,barge.record.archived ? true : false);
        this.setState({selectedPdf: pdf, documentLoading: false});
    }

    async handleDeleteConfirm(document) {
        this.documentId = document.id;
        if(this.state.selectedDoc.id !== document.id) {
            this.setState({selectedDoc: document});
            await this.loadPdf(document.id, document.docType);
        }
        if(document.docType === 'transferNotification') {
            this.setState({confirmationPopupVisible: true,
                confirmationAcceptAction: this.handleTransferDocumentDelete,
                confirmationMessage: (<span><span>Are you sure you want overwrite existing document: </span>
                <strong>{document.title}</strong></span>)});
        } else {
            this.setState({
                confirmationPopupVisible: true,
                confirmationAcceptAction: this.handleDeleteDocument,
                confirmationMessage: (<span><span>Are you sure you want to delete document: </span>
                <strong>{document.title}</strong></span>)
            });
        }
    }

    async handleTransferDocumentDelete() {
        this.setState({confirmationPopupVisible: false, busy: true, enableTransferDocument: true});
        await this.upload();
    }

    async handleDeleteDocument() {
        this.setState({confirmationPopupVisible: false, busy: true});
        const {barge} = this.state;
        const documentService = ServiceFactory.instance().createDocumentService();
        await documentService.deleteDocument(barge.record.uuid, barge.tradeStringUuid, this.documentId);
        store.dispatch(invalidateSearchResults());
        this.setState({busy: false});
        await this.loadBarge(barge.record.uuid, barge.tradeStringUuid);
        this.documentId = null;
    }

    loadDocList(barge, tradeStringUuid, selectedDocId, reconsignment) {
        const {user} = this.props;
        const docs = [
            {title: 'Shipment Ref: ' + barge.bargeId + ' / ' + serverDateToBrowserFormat(barge.blDate),
                id: 'BARGE', style: {fontWeight: 'bold'}, allowDelete: false}
        ];

        let selectedDoc  = docs[0];
        const canViewCopy = this.wfService.canViewBolCopy(user, barge, tradeStringUuid);
        const canViewOriginal = this.wfService.canViewBolOriginal(user, barge, tradeStringUuid);
        if (barge.documents) {
            let docId = selectedDocId ? selectedDocId : this.getDocId();
            barge.documents.forEach(d => {
                if (d.uuid === null || d.uuid === undefined) return;
                let doc = null;
                if(barge.archived && !['BOL', 'transferNotification'].includes(d.docType)){
                    return;
                }
                if (d.docType === 'BOL') {
                    /*if(barge.archived && barge.statusCode !== 'BOLVOIDACCEPTED'){
                        return;
                    }*/
                    if(barge.archived) {
                        const ts = getTradeStringEntryFromUuid(barge.tradeString, tradeStringUuid);
                        if(!ts.docHubTaskId) {
                            return;
                        }
                    }
                    if (canViewCopy || canViewOriginal) {
                        doc = {
                            title: 'Bill of Lading',
                            id: d.uuid,
                            icon: 'fa fa-file-pdf-o',
                            allowDelete: false,
                            docType: d.docType
                        };
                    } else {
                        return;
                    }
                } else if (d.docType === 'reconsignment') {

                    //If a later user reconsignment they must have BOL access to view
                    if (reconsignment !== 0) {
                        //If the document is in a future reconsigment don't allow user to see until they select that
                        //trade stringrecord. If document is in a previous reconsigment they can only view if they can view BOL
                        if ((reconsignment > d.reconsignment && !canViewCopy && !canViewOriginal) || (reconsignment < d.reconsignment)) return;
                        doc = {title: getDocumentTypeByDocTypeAndSubType(d.docType, d.subType).label, id: d.uuid, icon: 'fa fa-file-pdf-o',
                            allowDelete: true, docType: d.docType};
                    } else {
                        return;
                    }
                } else if (d.docType === 'weight') {
                    let docTypeDetails = getDocumentTypeByDocTypeAndSubType(d.docType, d.subType);
                    //let title = d.currWeightCertCount ? docTypeDetails.displayName : docTypeDetails.label;
                    doc = {title: d.docName ? d.docName : docTypeDetails.displayName,
                        id: d.uuid, icon: 'fa fa-file-pdf-o',
                        allowDelete: true, docType: d.docType};
                } else if (d.docType === 'transferNotification') {
                    if(barge.archived && d.transferId !== barge.bargeTransferId) {
                        return;
                    }
                    doc = {title: d.docName,
                        id: d.uuid,
                        icon: 'fa fa-file-pdf-o',
                        docType: d.docType,
                        allowReplace: this.wfService.canAttachDocument(user, barge, d.docType, d.docSubType, tradeStringUuid)};
                }
                else {
                    doc = {title: getDocumentTypeByDocTypeAndSubType(d.docType, d.subType).label, id: d.uuid, icon: 'fa fa-file-pdf-o',
                        allowDelete: true, docType: d.docType};
                }
                if (d.docType !== 'transferNotification') {
                    doc.allowDelete = this.wfService.canRemoveAttachment(user, barge, d.uuid, tradeStringUuid);
                }
                docs.push(doc);
                if (d.uuid === docId) {
                    selectedDoc = doc;
                    this.loadPdf(docId, doc.docType);
                }

                //Temporary code in demo to select BOL docid passed in URL from inbox
                if (d.docType === 'BOL' && docId === d.docType) {
                    selectedDoc = doc;
                    this.loadPdf(doc.id, doc.docType);
                }
            });
        }

        this.setState({docs: docs, selectedDoc: selectedDoc});
    }

    getDocId() {
        const search = this.props.location.search;
        if (search.startsWith('?docid=')) {
            return search.substring(7);
        }
        return null;
    }

    renderHeader() {
        const {showSelectCounterPartyDialog, selectedCounterparty, showProposeDestinationDialog,
            showUploadDialog, showAddBolDialog, actionsMenu, showSelectFeeDialogVisible, confirmationPopupVisible,
            okayMessageVisible, okayMessage, showBolCorrectionDialog, showEditBargeDialog, bargeEditMode,
            editableBargeRecord, selectedAction, showTransferProductDialog} = this.state;

        const tsuuid = this.props.match.params.tsuuid;
        const userHasRoles = this.props.user.roles && this.props.user.roles.length > 0;

        return (
            <div className='EssDocsPageTitleContainer grid'>
                <div style={{fontWeight: 'normal'}}>
                    {okayMessageVisible && <ConfirmationPopup message={okayMessage}
                                                              OkConfirm={true}
                                                              onClose={() => this.setState({okayMessageVisible: false})}
                                                              visible={okayMessageVisible}/>}
                    {confirmationPopupVisible && <ConfirmationPopup message={this.state.confirmationMessage}
                                                                    continueCancelFooter={this.state.enableTransferDocument}
                                                                    onAccept={this.state.confirmationAcceptAction}
                                                                    onClose={() => this.setState({confirmationPopupVisible: false, enableTransferDocument : false, busy: false})}
                                                                    visible={this.state.confirmationPopupVisible}/>}
                    {showAddBolDialog && <AddBolDialog visible={showAddBolDialog}
                                                       barge={this.state.barge}
                                                       onAddBol={this.handleAddBol}
                                                       onHide={() => this.setState({showAddBolDialog: false})}/>}
                    {showTransferProductDialog && <TransferProductDialog visible={showTransferProductDialog}
                                                                         barge={editableBargeRecord}
                                                                         loggedInUser={this.props.user}
                                                                         onUpdateData={this.handleUpdateNewBargeData}
                                                                         onTransferProduct={this.updateBarge}
                                                                         onHide={() => this.setState({showTransferProductDialog: false, enableTransferDocument: false})}/>}
                    {showEditBargeDialog &&
                    <CreateBargeDialog visible={showEditBargeDialog}
                                       data={editableBargeRecord}
                                       onUpdateData={this.handleUpdateNewBargeData}
                                       editMode={bargeEditMode}
                                       actionId={selectedAction}
                                       onCreateBarge={this.updateBarge}
                                       loggedInUser={this.props.user}
                                       onHide={this.hideEditBargeDialog}/>}

                    {showSelectCounterPartyDialog  &&
                    <OwnerSelectDialog visible={showSelectCounterPartyDialog}
                                       counterparty={selectedCounterparty}
                                       onSelectCounterparty={this.handleSelectCounterparty}
                                       onHide={() => this.setState({showSelectCounterPartyDialog: false})}
                                       onChange={(val) => this.setState({selectedCounterparty: val})}/>}

                    {showProposeDestinationDialog &&
                    <ProposeDestinationDialog visible={showProposeDestinationDialog}
                                              barge={this.state.barge.record}
                                              user={this.props.user}
                                              tradeStringUuid={tsuuid}
                                              destinations={this.destinations}
                                              onSelectDestination={this.handleSelectDestination}
                                              onHide={() => this.setState({showProposeDestinationDialog: false})}/>}
                    {showSelectFeeDialogVisible &&
                    <ProposeFeeDialog visible={showSelectFeeDialogVisible}
                                      onSelectFee={this.handleProposeFee}
                                      onHide={() => this.setState({showSelectFeeDialogVisible: false})}/>}
                    {showBolCorrectionDialog &&
                    <BolCorrectionDialog
                        visible={showBolCorrectionDialog}
                        onHide={() => this.setState({showBolCorrectionDialog: false})}
                        onEnterCorrection={this.handleEnterCorrection}/>}
                    {showUploadDialog &&
                    <UploadDialog visible={showUploadDialog}
                                  enableTransferDocument={this.state.enableTransferDocument}
                                  user={this.props.user}
                                  barge={this.state.barge.record}
                                  tradeStringUuid={tsuuid}
                                  onUploadFile={this.handleUploadFile}
                                  onHide={this.handleHideUploadDialog}/>}

                </div>

                <div className="col-fixed" style={{ width: '400px'}}>
                    <div className={'EssDocsPageTitle'}>Barge Info</div>
                    <div className={'EssDocsPageSubTitle BargeInfoBargeId'}>{this.state.barge.bargeId}</div>
                </div>
                <div style={{textAlign: 'right', marginTop: '10px'}} className={'col'}>
                    {this.renderBargeActions()}
                    <Menu appendTo={document.body} style= {{width: '150px'}} model={actionsMenu} popup={true} ref={el => this.menu=el} />
                    {actionsMenu?.length > 0 && this.state.canOtherActions && userHasRoles && <Button className={'p-button-primary'}
                                                                                                      label="OTHER ACTIONS" icon="fa fa-caret-down"
                                                                                                      onClick={(event)=>this.menu.toggle(event)}/>}
                </div>
            </div>
        );
    }

    renderBargeActions() {
        return (
            <Fragment>
                {this.state.busy && <span className={"fa fa-refresh fa-spin fa-1x fa-fw BargeInfoWaitSpinner"}/>}
                {this.state.barge.actions.filter(a => a.id !== 'BARGEINFO').map(a =>
                    <Button icon={a.icon}
                            key={a.id}
                            label={a.label}
                            disabled={a.disabled !== undefined ? a.disabled: false}
                            className={a.className}
                            onClick={() => a.handler(a.id, this.state.barge.record)}
                            style={{marginRight:'10px', ...a.style}}/>
                )}
            </Fragment>
        );
    }

    render() {
        const {barge, selectedDoc, docs, selectedPdf, documentLoading} = this.state;
        const tsuuid = this.props.match.params.tsuuid;
        if (barge === undefined || barge === null || barge.record === undefined) return null;
        return (
            <EssDocsPage onRenderHeader={this.renderHeader}>
                <div style={{position: 'absolute', bottom: '10px', top: '80px', right: '0', left: '0'}} className={'grid align-stretch nogutter'}>
                    <BargeDetailsPanel className='col-3' >
                        <DocumentList onSelectDoc={this.handleSelectDoc}
                                      onDeleteDoc={this.handleDeleteConfirm}
                                      docs={docs}
                                      selected={this.state.selectedDoc}/>
                        <div style={{height: '10px', backgroundColor: 'white'}}/>
                        <Messages onAddMessage={this.handleAddMessage} messages={this.state.messages}/>
                    </BargeDetailsPanel>
                    <BargeDetailsPanel title={selectedDoc.id === 'BARGE' ? 'Shipment Summary' : null } className={'col-7'}>
                        {selectedDoc.id === 'BARGE' ? <BargeDetails  barge={barge} tradeStringUuid={tsuuid}/> :
                            <DocumentViewer documentLoading={documentLoading} pdf={selectedPdf}/> }
                    </BargeDetailsPanel>
                    <BargeDetailsPanel title={'Trade String'} className={'col-2'}>
                        <TradeString  isAppliedOut={barge.record.statusCode === 'APPLIEDOUT'} tradeString={barge.tradeString}/>
                    </BargeDetailsPanel>
                </div>
            </EssDocsPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.authenticated
    }
};

export default connect(mapStateToProps)(withRouter(BargeInfo));
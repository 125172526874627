import {store} from "../../redux/Store";
import {saveDocument, updateTestDataRecord} from "../../redux/Actions";
import { v4 as uuidv4 } from 'uuid';
import WorkflowService from "@essdocs/ngfashared/workflow/WorkflowService";
import {defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import ServiceFactory from "../ServiceFactory";

export default class LocalDocumentService {

    constructor() {
        this.wfService = new WorkflowService(defaultWorkflow);
    }

    async uploadDocument(bargeUuid, tradeStringUuid, docType, subType, documentb64) {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(300);
        const barges = store.getState().inbox.testData;
        const user = store.getState().user.authenticated;
        const barge = barges.find(b => b.uuid === bargeUuid);

        if (!this.wfService.canAttachDocument(user, barge, docType, subType, tradeStringUuid)) {
            throw new Error('Not allowed to upload document: ' + docType);
        }

        const docUuid = uuidv4();
        const result = this.wfService.attachDocument(user, barge, docType, subType, docUuid, tradeStringUuid);
        const messageService = ServiceFactory.instance().createMessageService();
        await messageService.addActivity(barge.uuid, tradeStringUuid, result.notificationMsg, user);

        store.dispatch(updateTestDataRecord(barge));
        store.dispatch(saveDocument(docUuid, documentb64));
        return docUuid;
    }

    ///documents/{bargeUuid}/{docUuid}
    async getDocument(bargeUuid, docUuid, docType, tradeStringUuid) {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(300);
        const data =  store.getState().documents.documents;
        if (!data) return null;

        if (docType === 'BOL') {
            return this.loadBol(bargeUuid, tradeStringUuid);
        }

        const pdfBase64Encoded = data[docUuid];
        if (pdfBase64Encoded) {
            return pdfBase64Encoded;
        }

        return null;
    }

    async loadBol(bargeUuid, tradeStringUuid) {
        const barges = store.getState().inbox.testData;
        const user = store.getState().user.authenticated;
        const barge = barges.find(b => b.uuid === bargeUuid);
        const canViewCopy = this.wfService.canViewBolCopy(user, barge, tradeStringUuid);
        const canViewOriginal = this.wfService.canViewBolOriginal(user, barge, tradeStringUuid);
        if (!canViewCopy && !canViewOriginal) return null;
        if (barge.statusCode === 'BOLVOIDACCEPTED') return'/data/bolvoided.pdf';
        return canViewOriginal ? '/data/bolorig.pdf' : '/data/bolcopy.pdf';
    }

    async deleteDocument(bargeUuid, tradeStringUuid, docUuid) {
        const barges = store.getState().inbox.testData;
        const user = store.getState().user.authenticated;
        const barge = barges.find(b => b.uuid === bargeUuid);
        if (!this.wfService.canRemoveAttachment(user, barge, docUuid, tradeStringUuid)) {
            throw new Error('Not allowed to delete document: ' + docUuid);
        }
        const result = this.wfService.removeDocumentAttachment(user, barge, docUuid, tradeStringUuid);
        const messageService = ServiceFactory.instance().createMessageService();
        await messageService.addActivity(barge.uuid, tradeStringUuid, result.notificationMsg, user);
        store.dispatch(updateTestDataRecord(barge));
    }
}

import {API} from "aws-amplify";
import {store} from "../redux/Store";
import {encodeString} from "../shared/StringUtil";
const PAGE_SIZE = 100;

export default class InboxService {

    async search(statusArray, pageNumber, searchArg, options, documentTypes) {

        const searchArgEncoded = encodeString(searchArg);

        if (!options) options = {};
        if (!documentTypes) documentTypes = [];

        const user = store.getState().user.authenticated;
        if (user.type === 'CARRIER') {
            return this.carrierSearch(statusArray, pageNumber, searchArgEncoded, options, documentTypes)
        }

        let myInit = {
            body: {
                pageSize: PAGE_SIZE,
                pageNumber: pageNumber,
                statusCodes: statusArray,
                options: options,
                documentTypes: documentTypes,
                searchKey: searchArgEncoded
            },
            headers: {}
        };
        let path = `/barges/search/shipper`;

        const result = await API.post('barges', path, myInit);
        return result;
    }

    async carrierSearch(statusArray, pageNumber, searchArg, options, documentTypes) {
        let myInit = {
            body: {
                pageSize: PAGE_SIZE,
                pageNumber: pageNumber,
                statusCodes: statusArray,
                options: options,
                documentTypes: documentTypes,
                searchKey: searchArg
            },
            headers: {}
        };

        let path = `/barges/search/carrier`;
        const result = await API.post('barges', path, myInit);
        return result;
    }

    async findBarge(uuid) {
        let path = `/barges/search/${uuid}`;
        const result = await API.get('barges', path);
        return result.data;
    }


    async createBarge(fields) {
        await this.executeActionAndUpdateBarges([], 'createBarge', fields);
    }

    async updateBarge(actionId, bargeUuid, tradeStringUuid, fields) {
        await this.executeActionAndUpdateBarges([{uuid: bargeUuid, tradeStringUuid: tradeStringUuid ? tradeStringUuid : ''}],
            actionId ? actionId : 'editBarge', fields);
    }

    async executeActionAndUpdateBarges(bargeIdList, workflowAction, actionParams) {
        //barges/execute/workflow
        const path=`/barges/execute/workflow`;
        let myInit = {
            body: {
                action: workflowAction,
                actionParams: actionParams,
                barges: bargeIdList
            },
            headers: {}
        };
        try {
            await API.post('barges', path, myInit);
            return {'success' : true};
        } catch(e) {
            const ids  = bargeIdList.map(b => b.uuid).join(', ');
            alert('An unexpected system error occurred while updating. Please report this to support: ' + ids);
            console.error('failed to execute workflow action');
            console.error(e);
        }
    }

    async aggregateQuery(query, options) {
        //barges/execute/workflow
        const path=`/barges/admin/aggregate`;
        let myInit = {
            body: {
                query: query,
                options: options,
                collectionName: 'inbox'
            },
            headers: {}
        };
        try {
            const result = await API.post('barges', path, myInit);
            return result;
        } catch(e) {
            console.error('failed to execute workflow action');
            console.error(e);
        }
    }

    async findBargeByBargeId(bargeId, billOfLadingDate) {
        let path = `/barges/search?bargeId=${bargeId}&bolDate=${billOfLadingDate}`;
        const result = await API.get('barges', path);
        return result.data;
    }
}

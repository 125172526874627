import React, {Component, Fragment} from 'react';
import './TradeString.css';
import {serverTimestampToBrowserFormat} from "@essdocs/ngfashared/shared/FormatUtils";

class TradeString extends Component {
    static defaultProps = {
        className: ''
    };

    renderFragmentBreak() {
        return (
                <tr>
                    <td><i className={'fa fa-circle'}/></td>
                    <td className={'TradeStringOwner'}>...</td>
                </tr>
        )
    }

    renderSeparator() {
        return (
            <tr><td> {<span className={'TradeStringSeparator'}/>}</td></tr>
        );
    }

    render() {
        const {tradeString, isAppliedOut} = this.props;

        return (
                <div className={'TradeString'}>
                    <table>
                        <thead>
                            <tr><th style={{width: '20px'}}/><th/></tr>
                        </thead>
                        <tbody>
                        {tradeString.map((ts, idx) => {
                                const style = idx === tradeString.length - 1 && isAppliedOut ? {color: 'coral'} : {};
                                const endorsedDate = ts.endorsedDate ? serverTimestampToBrowserFormat(ts.endorsedDate) : null;
                                return (
                                    <Fragment key={idx}>
                                        {ts.fragmented && idx < (tradeString.length -1)  && (
                                            <Fragment>
                                                {this.renderFragmentBreak()}
                                                {this.renderSeparator()}
                                            </Fragment>
                                        )}
                                        <tr>
                                            <td><i className={'fa fa-circle'} style={style}/></td>
                                            <td className={'TradeStringOwner'}>{ts.ownerName}</td>
                                        </tr>
                                        <tr>
                                            <td> { (idx < tradeString.length - 1 || ts.fragmented) && <span className={'TradeStringSeparator'}/>}</td>
                                            <td className={'TradeStringInfo'}>
                                                {idx !== 0 && <Fragment>
                                                    <span>Applied: </span>
                                                    <span className={'TradeStringValue'}>{ts.applyDate} </span>
                                                    <br/>
                                                </Fragment>}
                                                {endorsedDate && <Fragment>
                                                    <span>Transferred: </span>
                                                    <span className={'TradeStringValue'}>{endorsedDate}</span>
                                                </Fragment>}
                                            </td>
                                        </tr>
                                        {ts.fragmented && idx === (tradeString.length -1)  && (
                                            <Fragment>
                                                {this.renderFragmentBreak()}
                                            </Fragment>
                                        )}
                                    </Fragment>
                                );
                        }
                        )}
                        </tbody>
                    </table>
                </div>
        );
    }
}

export default TradeString;

import {store} from "../redux/Store";
import {userTimeout} from "../redux/Actions";


const timeoutMinutes = 60; //Change this variable to set sign out timeout

const timeoutPeriod = timeoutMinutes * 60 * 1000;

let curTimeout = null;

export function resetTimeout() {
    if (curTimeout) {
        clearTimeout(curTimeout);
    }

    curTimeout = setTimeout(() => {
        store.dispatch(userTimeout());
    }, timeoutPeriod);
}

import {store} from "../redux/Store";
import {cacheRefData} from "../redux/Actions";
import ReferenceDataCommon from "./ReferenceDataCommon";
import {API} from "aws-amplify";



export default class ReferenceDataService extends ReferenceDataCommon{
    async cacheReferenceData() {
    }

    async readAll(groupId, ignoreCache) {
        if(!ignoreCache) {
            const recs = store.getState().referenceData[groupId];
            if (recs) {
                return recs;
            }
        }
        let path = `/referencedata/${groupId}`;

        try {
            const result = await API.get('referencedata', path);
            store.dispatch(cacheRefData(groupId, result.data));
            if (!result.data || result.data.length < 1) {
                return [];
            }
            return result.data;
        } catch (e) {
            console.log('Failed to load reference data: ' + groupId);
            console.log(e);
        }
    }

    async readSpecificFields(groupId, fields) {
        const recs = store.getState().referenceData[groupId];
        if (recs) {
            return this.convertToObjArr(recs);
        }
        let path = `/referencedata/${groupId}?fieldsToProject=${fields}`;

        try {
            const result = await API.get('referencedata', path);
            if (!result.data || result.data.length < 1) {
                return [];
            }
            console.log(this.convertToObjArr(result.data));
            return await this.convertToObjArr(result.data);
        } catch (e) {
            console.log('Failed to load reference data: ' + groupId);
            console.log(e);
        }
    }

    convertToObjArr(array) {
        return Array.from(array, element => {
            let object = {};
            object.label = element.id + " " + element.commodity;
            object.value = element.id;
            return object;
        })
    }

    fixedEncodeURIComponent(str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
    }

    async readRecordById(groupId, recordId) {
        recordId = this.fixedEncodeURIComponent(recordId.trim());
        let path = `/referencedata/${groupId}/${recordId}`;
        const result = await API.get('referencedata', path);
        return result.data;
    }

    async updateRecordById(groupId, recordId, settingsToUpdate) {
        let path = `/referencedata/${groupId}/${recordId}`;
        let init = {
            body: {
                'settingsToUpdate': settingsToUpdate
            }
        }
        const result = await API.put('referencedata', path, init);
        return result.data;
    }

    async search(groupId, searchArg) {
        let path = `/referencedata/admin/advanced/search/${groupId}?searchKey=${searchArg}`;
        const result = await API.get('referencedata', path);
        return result.data;
    }

    async findDuplicateIds(groupId, query) {
        let path = `/referencedata/${groupId}/find/duplicates`;
        let init = {
            body: {
                'aggregateQuery': query
            }
        }
        const result = await API.post('referencedata', path, init);
        return result.data;
    }
}

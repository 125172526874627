import React from 'react';
import {EssDocsComponent, EssDocsPage, FieldLabel, ErrorMessages, Form} from "@essdocs/commonui";
import {Messages} from "primereact/messages";
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
import ServiceFactory from "../../services/ServiceFactory";
import './UserManagement.css';
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import {ProgressSpinner} from "primereact/progressspinner";

class CompanyManagement extends EssDocsComponent {
    constructor(props) {
           super(props);
           this.state = {
               errors: [],
               confirmationPopupVisible: false,
               userSettingsMsg: 'Are you sure you want to update company settings',
               companyPanelCollapsed: true,
               selectedDestinations: [],
               selectedCompanyType: '',
               selectedCompanyId: '',
               selectedCompany: null,
               showProgressSpinner: false,
               companies: [],
               companyTypes: [
                   {type: 'Carrier',
                               name: 'carriers'},
                   {type: 'Shipper',
                               name: 'shippers'}]
           };

        this.refDataService = ServiceFactory.instance().createReferenceDataService();

        this.handleSave = this.handleSave.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSelectCompany = this.handleSelectCompany.bind(this);
        this.handleSelectCompanyType = this.handleSelectCompanyType.bind(this);
        this.renderCompanyDetails = this.renderCompanyDetails.bind(this);
        this.convertToObjArr = this.convertToObjArr.bind(this);
        this.handleDestinationsUpdate = this.handleDestinationsUpdate.bind(this);
    }

    async componentDidMount() {
        this.destinations = await this.refDataService.readAll(ReferenceDataType.Destinations);
        this.destinationsArray = this.convertToObjArr(this.destinations);
    }

    async handleSave() {
        this.setState({confirmationPopupVisible: false, showProgressSpinner: true})
        const selectedCompanyId = this.state.selectedCompanyId;
        const selectedCompanyDetails = {...this.state.selectedCompany};

        let companyDetails = await this.refDataService.readRecordById(ReferenceDataType.Shippers, selectedCompanyId);

        let companySettings = companyDetails.customSettings || {};
        companySettings['preferredDestinations'] = this.state.selectedDestinations;
        selectedCompanyDetails['customSettings'] = companySettings;
        this.setState({selectedCompany: selectedCompanyDetails});

        try {
            await this.refDataService.updateRecordById(ReferenceDataType.Shippers, selectedCompanyId, companySettings);
            this.messages.show({severity: 'success', detail: 'Company Settings has been updated'});
        } catch (e) {
            console.error(e);
            this.setState({errors: ['Failed to Update Company']});
        } finally {
            this.setState({
                showProgressSpinner: false,
                userSettingsMsg: 'Are you sure you want to update Company settings'
            });
        }
    }

    async handleUpdate() {
        let msg = 'Are you sure you want to update Company settings';
        this.setState({
            userSettingsMsg: msg,
            confirmationPopupVisible: true
        })
    }

    renderCompanyDetails(company) {
        return (
            <Fieldset legend="Company Details">
                <div className={'grid'}>
                    <div className={'col-4 UserRolesLabel'}>Company Name</div>
                    <div className={'col-8'}>{this.state.selectedCompanyType.type === 'Shipper' ? company.shipperName : company.carrierName}</div>
                    <div className={'col-4 UserRolesLabel'}>Company Uuid</div>
                    <div className={'col-8'}>{company.id}</div>
                    <div className={'col-4 UserRolesLabel'}>Company Type</div>
                    <div className={'col-8'}>{this.state.selectedCompanyType.type.toUpperCase()}</div>
                </div>
            </Fieldset>
        )
    }

    convertToObjArr(destinationArray) {
        let array = [];
        for (var i = 0; i < destinationArray.length; i++) {
            array.push(destinationArray[i]['destination']);
        }
        return array;
    }

    handleDestinationsUpdate(e) {
        this.setState({selectedDestinations: e.value})
    }

    renderCompanyForm() {
        let selectedDestinations = this.state.selectedDestinations ||
            this.state.selectedCompany.customSettings && this.state.selectedCompany.customSettings.preferredDestinations;
        return (
            <Fieldset legend="Custom Settings" toggleable={false} collapsed={this.state.companyPanelCollapsed}
                      onToggle={(e) => this.setState({companyPanelCollapsed: e.value})}>
                                <h4>Choose Preferred Destinations</h4>
                                <MultiSelect value={selectedDestinations}
                                             options={this.destinationsArray}
                                             onChange={this.handleDestinationsUpdate}
                                             maxSelectedLabels={1}
                                             selectedItemsLabel="{0} Filters selected"
                                             placeholder="Choose Filters"
                                             filter
                                             filterPlaceholder="Search"
                                             className="multiselect-custom"
                                             scrollHeight={'250px'}
                                             style={{width: '285px'}}/>
            </Fieldset>)
    }

    async handleSelectCompany(evt) {
        let companyDetails = await this.refDataService.readRecordById(this.state.selectedCompanyType.name, evt.value);
        this.setState({selectedCompanyId: evt.value, selectedCompany: companyDetails,
            selectedDestinations: companyDetails.customSettings ? companyDetails.customSettings.preferredDestinations : []});
    }

    async handleSelectCompanyType(evt) {
        //console.log(evt);
        let companyList= await this.refDataService.readAll(evt.value.name, true);
        this.setState({selectedCompanyType: evt.value, companies: companyList, selectedCompanyId: '', selectedCompany: ''});
    }

    equals = (array1, array2) =>
        array1.length === array2.length &&
        array1.every((v) => array2.includes(v));

    async handleCancel() {
        let companyDetails = await this.refDataService.readRecordById(this.state.selectedCompanyType.name, this.state.selectedCompanyId);
        this.setState({selectedCompanyId: this.state.selectedCompanyId, selectedCompany: companyDetails,
            selectedDestinations: companyDetails.customSettings ? companyDetails.customSettings.preferredDestinations : []});
    }

    render() {
        const {selectedCompany, errors, selectedDestinations, selectedCompanyId, selectedCompanyType} = this.state;
        let disableSaveButton = true;
        let difference = selectedCompany && selectedCompany.customSettings && selectedCompany.customSettings.preferredDestinations
            .filter(x => !selectedDestinations.includes(x))
            .concat(selectedDestinations.filter(x => !selectedCompany.customSettings.preferredDestinations.includes(x)));

        if(!difference || difference.length !== 0) {
            disableSaveButton = false;
        }
        return (
            <EssDocsPage title={'Company Management'}>
                <ErrorMessages errors={errors}/>
                <Messages className='col-12' ref={(el) => this.messages = el}/>
                <div style={{width: '600px'}}>
                    <div>
                        <Form>
                            <Fieldset legend="Choose Company">
                                <div>
                                    <FieldLabel required={true} value="Company Type"/>
                                    <Dropdown value={selectedCompanyType}
                                              placeholder={'Choose Company Type'}
                                              style={{width: '50%'}}
                                              options={this.state.companyTypes}
                                              optionLabel={'type'}
                                              onChange={this.handleSelectCompanyType}/>
                                </div>
                                {selectedCompanyType && <div>
                                    <FieldLabel required={true} value= {selectedCompanyType.type +" Company"}/>
                                    <Dropdown value={selectedCompanyId}
                                              placeholder={'Choose Company'}
                                              style={{width: '50%'}}
                                              scrollHeight={'280px'}
                                              options={this.state.companies}
                                              optionValue={'id'}
                                              optionLabel={selectedCompanyType.type === 'Shipper' ? 'shipperName' : 'carrierName'}
                                              filter={true}
                                              onChange={this.handleSelectCompany}/>
                                </div>
                                }
                            </Fieldset>

                        {selectedCompany && this.renderCompanyDetails(selectedCompany)}
                        {selectedCompanyId && selectedCompanyType.type === 'Shipper' && this.renderCompanyForm(selectedCompany)}
                        {selectedCompany && this.state.confirmationPopupVisible && <ConfirmationPopup message={this.state.userSettingsMsg}
                                                                        onAccept={this.handleSave}
                                                                        onClose={() => this.setState({confirmationPopupVisible: false,
                                                                            userSettingsMsg: 'Are you sure you want to update company settings'})}
                                                                        visible={this.state.confirmationPopupVisible}/>}
                        <div style={{textAlign: 'right', marginRight: '2px'}}>
                            {this.state.showProgressSpinner && <ProgressSpinner style={{width: '25px', height: '20px'}}
                                                                                strokeWidth="8"
                                                                                fill="#EEEEEE"
                                                                                animationDuration=".8s"/>}
                            {selectedCompanyId && selectedCompanyType.type === 'Shipper' &&
                                <Button disabled={disableSaveButton}
                                             style={{marginRight: '10px'}}
                                             className={"p-button-raised p-button-success"}
                                             onClick={this.handleUpdate}
                                             label={'UPDATE'}
                                             iconPos="right" />}
                            {selectedCompanyId && selectedCompanyType.type === 'Shipper' &&
                                <Button icon={'fa fa-times'}
                                    style={{marginLeft: '10px'}}
                                    className={'p-button-secondary'}
                                    label={'CANCEL'}
                                    onClick={this.handleCancel}/>}
                        </div>
                    </Form>
                    </div>
                </div>
            </EssDocsPage>
        );
    }
}

export default CompanyManagement;

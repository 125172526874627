export const SIGNON = "SIGNON";
export const SIGNOFF = "SIGNOFF";
export const SET_SEARCH_RESULTS = 'SETSEARCHRESULTS';
export const APPEND_SEARCH_RESULTS = 'APPENDSEARCHRESULTS';
export const SET_SEARCH_FILTER = 'SETSEARCHFILTER';
export const SET_TEST_DATA = 'SETTESTDATA';
export const ADD_TEST_DATA_RECORD = 'ADDTESTDATARECORD';
export const UPDATE_TEST_DATA_RECORD = 'UPDATETESTDATARECORD';
export const UPDATE_TEST_DATA_RECORDS = 'UPDATETESTDATARECORDS';
export const SAVE_DOCUMENT = 'SAVEDOCUMENT';
export const SAVE_MESSAGE = 'SAVEMESSAGE';
export const SET_MESSAGE_TESTDATA = 'SETMESSAGETESTDATA';
export const UPDATE_SEARCH_ARG = 'UPDATE_SEARCH_ARG';
export const UPDATE_INBOX_SELECTED_BARGE = 'UPDATE_INBOX_SELECTED_BARGE';
export const UPDATE_BACK_TO_INBOX = 'UPDATE_BACK_TO_INBOX';
export const COLLAPSE_SIDE_BAR = 'COLLAPSE_SIDE_BAR';
export const UPDATE_SORT_FIELDS = 'UPDATE_SORT_FIELDS';
export const CACHE_REF_DATA = 'CACHE_REF_DATA';
export const INVALIDATE_SEARCH_RESULTS = 'INVALIDATE_SEARCH_RESULTS';
export const ARCHIVE_BARGE = 'ARCHIVE_BARGE';
export const SEARCHING = "SEARCHING";
export const USER_TIMEOUT = 'USER_TIMEOUT';
export const USER_COMPANY_CHANGE = 'USER_COMPANY_CHANGE';
export const UPDATE_USER_SEARCH_SETTINGS = 'UPDATE_USER_SEARCH_SETTINGS';

export const signOn = user => ({
    type: SIGNON,
    payload: { user: user }
});

export const signOff = () => ({
    type: SIGNOFF,
    payload: { user: null }
});

export const setSearchResults = (searchResults, searchArg, loadMoreData, pageNumber)  => ({
    type: SET_SEARCH_RESULTS,
    payload: { searchResults: searchResults, searchArg: searchArg,
        loadMoreData: loadMoreData, pageNumber: pageNumber ? pageNumber : 1 }
});


export const appendSearchResults = (searchResults, loadMoreData, pageNumber)  => ({
    type: APPEND_SEARCH_RESULTS,
    payload: { searchResults: searchResults, loadMoreData: loadMoreData, pageNumber: pageNumber ? pageNumber : 1 }
});

export const setSearchFilter = (filter, options,  documentTypes) => ({
    type: SET_SEARCH_FILTER,
    payload: { filter: filter, options: options, documentTypes: documentTypes}
});

export const searching = (searching, searchMessage = '') => ({
    type: SEARCHING,
    payload: { searching: searching, searchMessage: searchMessage}
});


export const setTestData = testData => ({
    type: SET_TEST_DATA,
    payload: { testData: testData }
});

export const addTestDataRecord = record => ({
    type: ADD_TEST_DATA_RECORD,
    payload: { record: record }
});


export const updateTestDataRecord = record => ({
    type: UPDATE_TEST_DATA_RECORD,
    payload: { record: record }
});

export const archiveBarge = record => ({
    type: ARCHIVE_BARGE,
    payload: { record: record }
});

export const updateTestDataRecords = records => ({
    type: UPDATE_TEST_DATA_RECORDS,
    payload: { records: records }
});

export const saveDocument = (uuid, documentb64) => ({
    type: SAVE_DOCUMENT,
    payload: { uuid: uuid, documentb64: documentb64}
});


export const saveMessage = (bargeId, message) => ({
    type: SAVE_MESSAGE,
    payload: { bargeId: bargeId, message: message}
});

export const setMessageTestData = ( messages) => ({
    type: SET_MESSAGE_TESTDATA,
    payload: { messages: messages}
});


export const updateSearchArg = ( searchArg) => ({
    type: UPDATE_SEARCH_ARG,
    payload: { searchArg: searchArg}
});

export const updateInboxBargeSelection = ( barge) => ({
    type: UPDATE_INBOX_SELECTED_BARGE,
    payload: { bargeData: barge}
});
export const updateBackToInbox = (backToInboxFromBargeInfo) => ({
    type: UPDATE_BACK_TO_INBOX,
    payload: { backToInbox: backToInboxFromBargeInfo}
});
export const collapseSideBar = (collapse) => ({
    type: COLLAPSE_SIDE_BAR,
    payload: { collapseSidebar: collapse}
});

export const updateSortFields = ( sortFields) => ({
    type: UPDATE_SORT_FIELDS,
    payload: { sortFields: sortFields}
});

export const cacheRefData = (groupId, records) => ({
    type: CACHE_REF_DATA,
    payload: { groupId: groupId, records: records}
});

export const invalidateSearchResults = () => ({
    type: INVALIDATE_SEARCH_RESULTS,
    payload: {}
});

export const userTimeout = (timeout = true) => ({
    type: USER_TIMEOUT,
    payload: {timeout: timeout}
});

export const userChangeOfCompany = (updatedCompany) => ({
    type: USER_COMPANY_CHANGE,
    payload: { companyData: updatedCompany }
});

export const updateUserSearchSettings = (userSearchSettings) => ({
    type: UPDATE_USER_SEARCH_SETTINGS,
    payload: { userSearchSettings: userSearchSettings }
});









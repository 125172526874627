import WorkflowService from "@essdocs/ngfashared/workflow/WorkflowService";
import {defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";
import React from 'react';

export function createCompleteTripMessage(barge) {
    const msg = (<span>Are you sure you want to complete the trip?</span>);

    if(barge.statusCode === 'COMPLETE') {
        return msg;
    }

    const wfService = new WorkflowService(defaultWorkflow);
    const weightCertUploaded = wfService.hasWeightCertificateBeenUploaded(barge);
    const gradeCertUploaded = wfService.hasGradeCertificateBeenUploaded(barge);

    if (!weightCertUploaded && !gradeCertUploaded) {
        return (<span>Are you sure you want to complete the trip without a <strong>weight and grade</strong> certificate?</span>);
    }

    if (!weightCertUploaded ) {
        return (<span>Are you sure you want to complete the trip without a <strong>weight</strong> certificate?</span>);
    }

    if (!gradeCertUploaded ) {
        return (<span>Are you sure you want to complete the trip without a <strong>grade</strong> certificate?</span>);
    }

    return msg;
}

import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {ErrorMessages} from "@essdocs/commonui";
import ServiceFactory from "../../services/ServiceFactory";
import {ReferenceDataType} from "../../services/ReferenceDataType";

class ProposeDestinationDialog extends Component {
        constructor(props) {
            super(props);
            this.state = {
                careOfArray: [],
                errors: [],
                selectedDestination: '',
                selectedCareOf: '',
                destinationList: []
            };
            this.refDataService = ServiceFactory.instance().createReferenceDataService();
            this.handleKeyUp = this.handleKeyUp.bind(this);
            this.handleOnChange = this.handleOnChange.bind(this);
            this.handleCareOfUpdate = this.handleCareOfUpdate.bind(this);
            this.handleProposeDestinationClick = this.handleProposeDestinationClick.bind(this);
        }

        async componentDidMount() {
            if(this.props.barge || this.props.selectionCount > 1) {
                if(this.props.user) {
                    let userCompany = await this.refDataService.readRecordById(ReferenceDataType.Shippers, this.props.user.company);
                    let groupedDestination = [];
                    if(userCompany && userCompany.customSettings
                        && userCompany.customSettings.preferredDestinations && userCompany.customSettings.preferredDestinations.length > 0) {
                        let userPrefDests = userCompany.customSettings.preferredDestinations;
                        //console.log(userPrefDests);
                        let items = this.props.destinations.filter(dest => userPrefDests.includes(dest.id)) || [];
                        groupedDestination.push({
                            label: 'Preferred Destinations', code: 'PREFERRED',
                            items: items
                        })
                        groupedDestination.push({
                            label: 'Other Destinations', code: 'OTHER',
                            items: this.props.destinations.filter(dest => !userPrefDests.includes(dest.id)) || []
                        })
                    } else {
                        groupedDestination.push({
                            label: '', code: 'ALL',
                            items: this.props.destinations
                        });
                    }
                    this.setState({destinationList : groupedDestination});
                    if(this.props.barge) {
                        const ts = this.props.barge.tradeString.find(ts => ts.uuid === this.props.tradeStringUuid);
                        const reconsignmentNbr = ts.reconsignment;
                        if (this.props.barge.reconsignments[reconsignmentNbr].destination) {
                            const destinationObj = this.props.destinations.find(c => c.id.trim() === this.props.barge.reconsignments[reconsignmentNbr].destination.trim());
                            this.setState({
                                careOfArray: this.convertToObjArr(destinationObj.careOf),
                                selectedDestination: destinationObj.destination,
                                selectedCareOf: this.props.barge.reconsignments[reconsignmentNbr].careOf ? this.props.barge.reconsignments[reconsignmentNbr].careOf : '',
                            });
                        }
                    }

                }
            }
        }

        convertToObjArr(careOfArray) {
            return Array.from(careOfArray, careOf => {
                let careOfObject = {};
                careOfObject.name = careOf;
                careOfObject.value = careOf;
                return careOfObject;})
        }

        async handleKeyUp(evt) {
                if (evt.keyCode === 13 && this.state.selectedDestination) {
                    this.props.onSelectDestination({
                        destination : this.state.selectedDestination,
                        careOf: this.state.selectedCareOf
                    });
                }
        }

        async handleOnChange(e) {
            const destination = this.props.destinations.find(c => c.id.trim() === e.value.trim());
            this.setState({
                careOfArray: this.convertToObjArr(destination.careOf)
            });
            this.setState({
                selectedDestination: e.value
            });
        }

        async handleCareOfUpdate(e) {
            this.setState({
                selectedCareOf: !e.target.value || e.target.value === null ? '' : e.target.value
            });
        }

        handleProposeDestinationClick() {
            this.setState({errors: []});
            this.props.onSelectDestination({
                destination : this.state.selectedDestination,
                careOf: this.state.selectedCareOf.trim()
            });
        }

        groupedItemTemplate(option) {
            return (
                <div className="p-d-flex p-ai-center">
                    {option.code === 'PREFERRED' && <i className="fa fa-cog"></i>}
                    <div style={{fontWeight: 'bold', marginLeft: '3px'}}>{option.label}</div>
                </div>
            );
        }

    render() {
            let msg = '';
            if (this.props.selectionCount) {
                msg  = `Are you sure you want to Propose Destination for ${this.props.selectionCount} barge`;
                if (this.props.selectionCount > 1) msg += 's';
                msg  += '?';
            }
            return (
                <Dialog header={'SELECT DESTINATION'}
                        visible={this.props.visible}
                        focusOnShow={false}
                        contentStyle={{overflow: 'visible'}}
                        style={{width: '400px'}} modal={true}
                        onHide={this.props.onHide}>
                        <div onKeyUp={this.handleKeyUp}>
                        <ErrorMessages errors={this.state.errors}/>
                        <div className={'DialogLabel'}>Destination</div>
                        <div>
                            <Dropdown value={this.state.selectedDestination}
                                      options={this.state.destinationList}
                                      onChange={this.handleOnChange}
                                      optionValue={'destination'}
                                      optionLabel={'id'}
                                      optionGroupLabel="label"
                                      optionGroupChildren="items"
                                      autoFocus={true}
                                      style={{width: '100%'}}
                                      filter={true}
                                      optionGroupTemplate={this.groupedItemTemplate}/>
                        </div>
                        </div>

                        <div className={'DialogLabel'}>CareOf</div>
                        <div>
                            <Dropdown value={this.state.selectedCareOf}
                                      style={{width: '100%'}}
                                      optionValue={'name'}
                                      optionLabel={'value'}
                                      options={this.state.careOfArray}
                                      editable={true}
                                      showClear={this.state.selectedCareOf ? true : false}
                                      filter={true}
                                      onChange={this.handleCareOfUpdate}/>
                        </div>
                        <div style={{textAlign: 'right', padding: '0.75rem 0 0.75rem 0'}}>
                            <Button icon='fa fa-check'
                                    disabled={!this.state.selectedDestination}
                                    className={'p-button-success'}
                                    label={'OK'}
                                    onClick={this.handleProposeDestinationClick}/>
                            <Button icon={'fa fa-times'}
                                    style={{marginLeft: '10px'}}
                                    className={'p-button-secondary'}
                                    label={'CANCEL'}
                                    onClick={this.props.onHide}/>
                        </div>
                </Dialog>
            )
        }
    }

export default ProposeDestinationDialog;

import {
    ADD_TEST_DATA_RECORD, APPEND_SEARCH_RESULTS, ARCHIVE_BARGE, COLLAPSE_SIDE_BAR, INVALIDATE_SEARCH_RESULTS, SEARCHING,
    SET_SEARCH_FILTER,
    SET_SEARCH_RESULTS,
    SET_TEST_DATA, UPDATE_BACK_TO_INBOX, UPDATE_INBOX_SELECTED_BARGE, UPDATE_SEARCH_ARG, UPDATE_SORT_FIELDS,
    UPDATE_TEST_DATA_RECORD, UPDATE_TEST_DATA_RECORDS
} from "./Actions";


const initialState = {
    searchResults: null,
    loadMoreData: false,
    pageNumber: 1,
    filter: null,
    options: null,
    documentTypes: null,
    testData: null,
    archiveTestData: [],
    searching: false,
    searchMessage: '',
    searchArg: '',
    sortFields: [],
    searchResultsInvalidated: false,
    selectedBarge: null,
    backToInbox: false,
};


function updateBargeRecord(data, record) {
    const newData = [];
    data.forEach(d => {
        if (d.uuid === record.uuid) {
            newData.push(record);
        } else {
            newData.push(d)
        }
    });
    return newData;
}

function findRecord(recs, uuid) {
    return recs.find(d => d.uuid === uuid);
}

function updateBargeRecords(data, records) {
    const newData = [];

    data.forEach(d => {
        const existing = findRecord(records, d.uuid);
        if (existing !== undefined) {
            newData.push(existing);
        }
        else {
            newData.push(d);
        }
    });
    return newData;
}

export function inboxReducer(state = initialState, action) {
    switch(action.type) {
        case SET_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.payload.searchResults,
                loadMoreData: action.payload.loadMoreData,
                searching: false,
                pageNumber: action.payload.pageNumber,
                searchArg: action.payload.searchArg,
                searchResultsInvalidated: false
            };
        case UPDATE_SEARCH_ARG: {
            return {
                ...state,
                searchArg: action.payload.searchArg
            }
        }
        case UPDATE_INBOX_SELECTED_BARGE: {
            return {
                ...state,
                selectedBarge: action.payload.bargeData
            }
        }
        case UPDATE_BACK_TO_INBOX: {
            return {
                ...state,
                backToInbox: action.payload.backToInbox
            }
        }
        case COLLAPSE_SIDE_BAR: {
            return {
                ...state,
                collapseSidebar: action.payload.collapseSidebar
            }
        }
        case UPDATE_SORT_FIELDS: {
            return {
                ...state,
                sortFields: action.payload.sortFields
            }
        }
        case APPEND_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: [...state.searchResults, ...action.payload.searchResults],
                loadMoreData: action.payload.loadMoreData,
                searching: false,
                searchMessage: '',
                pageNumber: action.payload.pageNumber,
                searchResultsInvalidated: false
            };
        case SET_SEARCH_FILTER:
            return {
                ...state,
                filter: action.payload.filter,
                options: action.payload.options,
                documentTypes: action.payload.documentTypes
            };
        case SEARCHING:
            return {
                ...state,
                searching: action.payload.searching,
                searchMessage: action.payload.searchMessage
            };
        case SET_TEST_DATA:
            return {
                ...state,
                testData: action.payload.testData
            };
        case ADD_TEST_DATA_RECORD:
            return {
                ...state,
                    testData: [...state.testData, action.payload.record]
            };
        case UPDATE_TEST_DATA_RECORD:
            return {
                ...state,
                testData: [...updateBargeRecord(state.testData, action.payload.record)]
            };
        case UPDATE_TEST_DATA_RECORDS:
            return {
                ...state,
                testData: updateBargeRecords(state.testData, action.payload.records)
            };
        case INVALIDATE_SEARCH_RESULTS:
            return {
                ...state,
                searchResultsInvalidated: true
            };
        case ARCHIVE_BARGE:
            return {
                ...state,
                archiveTestData: [...state.archiveTestData, action.payload.record]
            };
        default:
            return state;
    }
}


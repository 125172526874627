import {API} from "aws-amplify";

export default class LocalUserService {
    async getCurrentlyAuthenticatedUser() {
        //users/authenticatedUser/info
        const result = await API.get('ngfausers', '/users/authenticatedUser/info');
        return result.data;
    }

    async getUser(username, checkIfCognitoUserConfirmed) {
        let path = `/users/${username}`;
        if(checkIfCognitoUserConfirmed !== undefined) {
            path += `?checkIfCognitoUserConfirmed=true`
        }
        const result = await API.get('ngfausers', path);
        return result.data;
    }

    async updateUser(user) {
        let myInit = {
            body: user,
            headers: {}
        };
        const result = await API.put('ngfausers', '/users', myInit);
        return result;
    }

    async updateUserProperties(properties) {
        let myInit = {
            body: properties,
            headers: {}
        };
        const result = await API.put('ngfausers', '/users/preferences', myInit);
        return result;
    }

    async deleteUser(username) {
        const result = await API.del('ngfausers', `/users/delete/user/${username}`);
        return result;
    }

    async updateUserStatus() {
        let myInit = {
        };
        const result = await API.put('ngfausers', `/users/update/status`, myInit);
        return result;
    }

    async readUsersWithStatus() {
        const result = await API.get('ngfausers', `/users/read/status`);
        return result;
    }
}

import React, {useState} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

export function ProposeFeeDialog(props) {
    let msg = '';
    const [fee, setFee] = useState('');

    const handleKeyUp = (evt) => {
        if (evt.keyCode === 13 && props.destination)  {
            props.onSelectFree(getFeeAsNumber());
        }
    }

    function getFeeAsNumber() {
        return parseFloat(fee);
    }

    function canSave() {
        try {
            const value = parseFloat(fee);
            return value > 0
        } catch(e) {
            return false;
        }
    }

    return (
        <Dialog header={'PROPOSE RECONSIGNMENT FEE'}
                visible={props.visible}
                focusOnShow={false}
                contentStyle={{overflow: 'visible'}}
                style={{width: '250px'}} modal={true}
                onHide={props.onHide}>
            <div onKeyUp={handleKeyUp}>
                {props.selectionCount && <div style={{fontWeight: 'bold'}}>{msg}</div>}
                <div className={'DialogLabel'}>Fee</div>
                <div>
                    <InputText style={{width: '100%'}}
                               keyfilter={'money'}
                               value={fee}
                               onChange={e => setFee(e.target.value)}/>
                </div>
                <div style={{textAlign: 'right', marginTop: '10px'}}>
                    <Button icon='fa fa-check'
                            disabled={!canSave()}
                            className={'p-button-success'}
                            label={'OK'}
                            onClick={() => props.onSelectFee(getFeeAsNumber())}/>
                    <Button icon={'fa fa-times'}
                            style={{marginLeft: '10px'}}
                            className={'p-button-secondary'}
                            label={'CANCEL'}
                            onClick={props.onHide}/>
                </div>
            </div>
        </Dialog>
    )
}

import {store} from "../../redux/Store";
import axios from "axios";

export default class LocalUserService {
    async getCurrentlyAuthenticatedUser() {
        return store.getState().user.authenticated;
    }

    async getUser(username) {
        const users = await axios.get('/data/users.json');
        return users.data.find(u => u.username === username);
    }
}


import React from 'react';
import {EssDocsComponent} from "@essdocs/commonui";
import {EssDocsPage, DashboardWorkspace, Dashboard} from "@essdocs/commonui";
import './AdminDataLoad.css';
import ServiceFactory from "../../services/ServiceFactory";
import {Chart} from "primereact/chart";
import {defaultWorkflow} from "@essdocs/ngfashared/workflow/DefaultWorkflow";

const COLORS = [
    "#FF6384",
    "#36A2EB",
    "#56ffa2",
    "#5507d6",
    "#ffce56",
    "#ff56c1",
    "#2a5134"
];

const HOVER_COLORS = [
    "#eaeaea",
    "#eaeaea",
    "#eaeaea",
    "#eaeaea",
    "#eaeaea",
    "#eaeaea",
    "#eaeaea"
];

class AdminDashboard extends EssDocsComponent {
    constructor(props) {
        super(props);
        this.state = {
            dashboardModel: {
                dashboard1: {label: 'Barge Status', size: Dashboard.Size.LARGE, visible: true, order: 1},
                dashboard2: {label: 'Action Owners', size: Dashboard.Size.LARGE, visible: true, order: 2}
            }};
        this.handleReorder = this.handleReorder.bind(this);
        this.handleHideShowDashboard = this.handleHideShowDashboard.bind(this);
        this.handleSetSize = this.handleSetSize.bind(this);
        this.readData = this.readData.bind(this);
    }

    async componentDidMount() {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        this.shippers = await refDataService.readAll('shippers');
        this.carriers = await refDataService.readAll('carriers');
        this.readData();
    }

    handleHideShowDashboard(id, visible) {
        let updated = {...this.state.dashboardModel};
        updated[id].visible = visible;
        this.setState({dashboardModel: updated})
    }

    handleSetSize(id, value) {
        let updated = {...this.state.dashboardModel};
        updated[id].size = value;
        this.setState({dashboardModel: updated})
    }

    handleReorder(newState) {
        this.setState({dashboardModel: newState});
    }

    readData() {
        this.readBargeStatus();
        this.readActionOwners();
    }

    async readBargeStatus() {
        const inboxService = ServiceFactory.instance().createInboxService();
        const query = [
            {"$group":{_id:"$statusCode",counter:{$sum:1}}}
        ]
        try {
            const result = await inboxService.aggregateQuery(query, {});
            const data = result.data;
            data.forEach(d => d.label = defaultWorkflow[d._id].statusDesc);
            const chartData = {
                labels: data.map(d => d.label),
                datasets: [
                    {
                        data: data.map(d => d.counter),
                        backgroundColor: COLORS,
                        hoverBackgroundColor: HOVER_COLORS
                    }]
            };
            this.setState({bargeStatusData: chartData})
        } catch(e) {
            console.error(e);

        }
    }

    async readActionOwners() {
        const inboxService = ServiceFactory.instance().createInboxService();
        const query = [
            // Filter out documents with empty curOwners arrays
            {
                $match: {
                    curOwners: { $ne: [] }
                }
            },
            // Unwind the curOwners array to create one document per owner
            { $unwind: "$curOwners" },
            // Filter out documents where curOwners is an empty string
            {
                $match: {
                    curOwners: { $ne: "" }
                }
            },
            // Group by curOwners and count the documents in each group
            {
                $group: {
                    _id: "$curOwners",
                    counter: { $sum: 1 }
                }
            },
            {
                $project: {
                    counter: 1,
                }
            }
        ]
        try {
            const result = await inboxService.aggregateQuery(query, {});
            let data = result.data;
            data.forEach(d => {
                let shipper = this.shippers.find(s => s.id === d._id);
                let carrier;
                if(!shipper) {
                    carrier = this.carriers.find(s => s.id === d._id);
                }
                d.label = shipper ? shipper.shipperName : carrier ? carrier.carrierName : "";
            });
            const chartData = {
                labels: data.map(d => d.label),
                datasets: [
                    {
                        data: data.map(d => d.counter),
                        backgroundColor: COLORS,
                        hoverBackgroundColor: HOVER_COLORS
                    }]
            };
            this.setState({actionOwnerData: chartData})
        } catch(e) {
            console.error(e);

        }
    }

    render() {
        return (
            <EssDocsPage>
                <DashboardWorkspace title='Admin Dashboard' model={this.state.dashboardModel} configurable={false}
                                    onHideShow={this.handleHideShowDashboard}
                                    onSetSize={this.handleSetSize}
                                    onSave={() => console.log('save')}
                                    onReorder={this.handleReorder}>
                    <Dashboard OnRefresh={this.readData} id={'dashboard1'} >
                        <Chart type="pie" data={this.state.bargeStatusData} />
                    </Dashboard>
                    <Dashboard OnRefresh={this.readData} id={'dashboard2'} >
                        <Chart type="pie" data={this.state.actionOwnerData} />
                    </Dashboard>
                </DashboardWorkspace>
            </EssDocsPage>
        );
    }
}

export default AdminDashboard;

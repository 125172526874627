import {API} from "aws-amplify";

const DATA_URL_PREFIX = 'data:application/pdf;base64,';

export default class DocumentService {

    /********************************************************
     * Method to upload a document                           *
     * Body : {                                              *
     *         bargeUuid : {bargeUuid},                      *
     *         docType : {document type},                    *
     *         tradeStringId: {tsid}                         *
     *         document : {base 64 encoded pdf document}     *
     *        }                                              *
     * Endpoint : /documents/upload                          *
     *********************************************************/
    uploadDocument = async (bargeUuid, tradeStringUuid, docType, subType, documentb64, documentUuid) => {
        const path=`/documents/upload`;
        if (documentb64.startsWith(DATA_URL_PREFIX)) {
            documentb64 = documentb64.slice(DATA_URL_PREFIX.length);
        }
        let myInit = {
            body: {
                bargeUuid: bargeUuid,
                docType: docType,
                subType: subType,
                tradeStringId: tradeStringUuid,
                document: documentb64,
                documentUuid: documentUuid
            },
            headers: {}
        };
        try {
            const result = await API.post('documents', path, myInit);
            //console.log(result);
            return result.data.docUuid;
        } catch(e) {
            alert('An unexpected system error occurred while uploading the document. Please report this to support: ' + bargeUuid);
            console.error('failed to upload document');
            console.error(e);
        }
        throw new Error('Failed to upload document');
    };

    ///documents/{bargeUuid}/{docUuid}
    async getDocument(bargeUuid, docUuid, docType, tradeStringUuid, loadFromArchive) {
        let path = `/documents/${bargeUuid}/${docUuid}?docType=${docType}&tradeStringUuid=${tradeStringUuid}&loadFromArchive=${loadFromArchive}`;
        const result = await API.get('documents', path);
        return DATA_URL_PREFIX + result.data;
    }

    /********************************************************
     * Method to upload a document                           *
     * Body : {                                              *
     *         bargeUuid : {bargeUuid},                      *
     *         docType : {document type},                    *
     *         tradeStringId: {tsid}                         *
     *         document : {base 64 encoded pdf document}     *
     *        }                                              *
     * Endpoint : /documents/upload                          *
     *********************************************************/
    async deleteDocument(bargeUuid, tradeStringUuid, docUuid) {
        let path = `/documents/${bargeUuid}/${tradeStringUuid}/${docUuid}`;
        let myInit = {
            body: {},
            headers: {}
        };
        try {
            await API.del('documents', path, myInit);
            return true;
        } catch(e) {
            console.error('failed to delete document');
            console.error(e);
        }
        return false;
    }


}

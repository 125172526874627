import React, {Component} from 'react';
import './DocumentViewer.css';
import ServiceFactory from "../../services/ServiceFactory";

class DocumentViewer extends Component {
    static defaultProps = {
        className: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedDoc: 'grade',
            lastUpdated: ''
        };
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    async handleUploadFile(filename, file64Data) {
        const inboxService = ServiceFactory.instance().createInboxService();
        await inboxService.addDocument(this.props.barge, filename, this.state.selectedDoc, file64Data);
        this.setState({lasteUpdated: new Date()});
    }

    render() {
        return (
            <div className={'NgfaDocuments'}>
                {this.props.documentLoading && <div className={'NgfaDocumentsWaitSpinner'}><span className={"fa fa-refresh fa-spin fa-1x fa-fw"}/> Loading</div>}
                <iframe title={'File'} src={this.props.pdf} width={'100%'} height={'100%'} frameBorder="0"/>
            </div>
        );
    }
}

export default DocumentViewer;

import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {MultiSelect} from 'primereact/multiselect';
import './SearchOptionsDialog.css';
import {Button} from "primereact/button";
import ServiceFactory from "../../services/ServiceFactory";
import {ErrorMessages, FieldLabel} from "@essdocs/commonui";
import {ProgressSpinner} from "primereact/progressspinner";
import {AutoComplete} from "primereact/autocomplete";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {Calendar} from "primereact/calendar";
import {BROWSER_DATE_FORMAT, dateToServerFormat, serverDateToDate} from "@essdocs/ngfashared/shared/FormatUtils";
import {Dropdown} from "primereact/dropdown";
import {store} from "../../redux/Store";
import {updateUserSearchSettings} from "../../redux/Actions";

class SearchOptionsDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCommodities: [],
            commodityOptions: [],
            disableSaveButton: true,
            showProgressSpinner: false,
            bargeIdSuggestions: [],
            selectedBargeId: '',
            errors: [],
            bolStartDate: null,
            bolEndDate: null,
        };
        this.calendarRef = React.createRef();

        this.refDataService = ServiceFactory.instance().createReferenceDataService();
        this.userService = ServiceFactory.instance().createUserService();
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.suggestBargeIds = this.suggestBargeIds.bind(this);
        this.handleUpdateBargeId = this.handleUpdateBargeId.bind(this);
        this.handleUpdateBolStartDate = this.handleUpdateBolStartDate.bind(this);
        this.handleUpdateBolEndDate = this.handleUpdateBolEndDate.bind(this);
        this.convertDateFields = this.convertDateFields.bind(this);
    }

    async componentDidMount() {
        this.setState({
            commodityOptions: this.props.commodityFilterOptions,
            selectedCommodities: this.props.searchOptionFilters.commodityFilters,
            selectedBargeId: this.props.searchOptionFilters.bargeIdFilter
        });
        if (this.props.searchOptionFilters.bolDateFilter) {
            let bolDateRange = this.props.searchOptionFilters.bolDateFilter;
            this.setState({
                bolStartDate: bolDateRange.fromBolDate ? serverDateToDate(bolDateRange.fromBolDate) : '',
                bolEndDate: bolDateRange.toBolDate ? serverDateToDate(bolDateRange.toBolDate) : ''
            });
        }
    }

    async handleSaveClick() {
        this.setState({disableSaveButton: true, showProgressSpinner: true})
        let inboxSearchFilters = {};
        inboxSearchFilters.commodityFilters = this.state.selectedCommodities;
        if (this.state.selectedBargeId) {
            const result = await this.refDataService.readRecordById(ReferenceDataType.Barges, this.state.selectedBargeId);
            if (!result.id) {
                this.setState({errors: ['Invalid Barge ID'], disableSaveButton: false, showProgressSpinner: false});
                return;
            }
        }
        if (this.state.bolEndDate && !this.state.bolStartDate) {
            this.setState({
                errors: ['Bill of Lading End Date can not be saved without a start Date'],
                disableSaveButton: false,
                showProgressSpinner: false
            });
            return;
        }
        let bolDateFilter = this.convertDateFields();
        if (this.state.bolStartDate && this.state.bolEndDate) {
            if (bolDateFilter.fromBolDate > bolDateFilter.toBolDate) {
                this.setState({
                    errors: ['Bill of Lading End Date can not be earlier than start Date'],
                    disableSaveButton: false,
                    showProgressSpinner: false
                });
                return;
            }
        }
        inboxSearchFilters.bolDateFilter = bolDateFilter;
        inboxSearchFilters.bargeIdFilter = this.state.selectedBargeId.trim();

        await this.userService.updateUserProperties(inboxSearchFilters);
        store.dispatch(updateUserSearchSettings(inboxSearchFilters));
        this.props.onUpdateSearchoptions(inboxSearchFilters);
    }

    handleOptionChange(option) {
        this.setState({selectedCommodities: option.value});
    }

    async suggestBargeIds(e) {
        const results = await this.refDataService.search(ReferenceDataType.Barges, e.query);
        this.setState({bargeIdSuggestions: results});
    }

    async handleUpdateBargeId(e) {
        if (e.target.value.id) {
            this.setState({errors: [], selectedBargeId: e.target.value.id})
        } else {
            this.setState({errors: [], selectedBargeId: e.target.value})
        }
    }

    handleUpdateBolStartDate(e) {
        if (e.value) {
            this.setState({errors: [], bolStartDate: e.value});
        } else {
            this.setState({errors: [], bolStartDate: null})
        }
    }

    handleUpdateBolEndDate(e) {
        if (e.value) {
            this.setState({errors: [], bolEndDate: e.value});
        } else {
            this.setState({errors: [], bolEndDate: null})
        }
    }

    monthNavigatorTemplate(e) {
        return <Dropdown value={e.value}
                         options={e.options}
                         onChange={(event) => e.onChange(event.originalEvent, event.value)}
                         style={{lineHeight: 1}}/>;
    }

    yearNavigatorTemplate(e) {
        return <Dropdown value={e.value}
                         options={e.options}
                         onChange={(event) => e.onChange(event.originalEvent, event.value)}
                         className="p-ml-2"
                         style={{lineHeight: 1}}/>;
    }

    convertDateFields() {
        const fromBlDate = this.state.bolStartDate ? dateToServerFormat(this.state.bolStartDate) : null;
        const toBlDate = this.state.bolEndDate ? dateToServerFormat(this.state.bolEndDate) : null;
        let bolDateFilter = {
            fromBolDate: fromBlDate,
            toBolDate: toBlDate ? toBlDate : fromBlDate
        };
        return bolDateFilter;
    }

    isBolDateRangeEqual(object1, object2) {
        return object1.fromBolDate === object2.fromBolDate
            && object1.toBolDate === object2.toBolDate;
    }

    render() {
        let {selectedCommodities, selectedBargeId, errors, commodityOptions, selectedBlDateRange} = this.state;
        let disableSaveButton;

        let difference = this.props.searchOptionFilters?.commodityFilters
            .filter(x => !selectedCommodities.includes(x))
            .concat(selectedCommodities.filter(x => !this.props.searchOptionFilters.commodityFilters.includes(x)));

        if (difference.length === 0 && selectedBargeId === this.props.searchOptionFilters.bargeIdFilter &&
            this.props.searchOptionFilters.bolDateFilter &&
            this.isBolDateRangeEqual(this.props.searchOptionFilters.bolDateFilter, this.convertDateFields())) {
            disableSaveButton = true;
        } else {
            disableSaveButton = false;
        }

        return (
            <Dialog header={"Inbox Search Criteria"}
                    style={{width: '25%'}}
                    visible={this.props.visible}
                    focusOnShow={false}
                    contentStyle={{overflow: 'visible'}}
                    modal={true}
                    onHide={this.props.onHide}>
                <div style={{padding: '5px'}} className={'grid'}>
                    <ErrorMessages errors={errors}/>
                    <div className={'col-12'}>
                        <FieldLabel value="Commodity"/>
                        <MultiSelect value={selectedCommodities}
                                     options={commodityOptions}
                                     style={{width: '100%'}}
                                     onChange={this.handleOptionChange}
                                     maxSelectedLabels={1}
                                     selectedItemsLabel="{0} Filters selected"
                                     placeholder="Choose Commodities"
                                     filter
                                     filterPlaceholder="Search"
                                     className="multiselect-custom"
                                     scrollHeight={'250px'}/>
                    </div>
                    <div className={'col-12'}>
                        <FieldLabel value="Barge ID"/>
                        <AutoComplete value={selectedBargeId}
                                      style={{display: 'block'}}
                                      inputStyle={{width: '100%'}}
                                      minLength={2}
                                      field={'id'}
                                      onChange={this.handleUpdateBargeId}
                                      placeholder={'Barge Id'}
                                      suggestions={this.state.bargeIdSuggestions}
                                      completeMethod={this.suggestBargeIds}/>
                    </div>

                    <div className={'col-6'}>
                        <FieldLabel value="Bill of Lading Start Date"/>
                        <Calendar showIcon={true}
                                  style={{width: '100%'}}
                                  showOnFocus={false}
                                  dateFormat="mm/dd/yy"
                                  placeholder={BROWSER_DATE_FORMAT}
                                  value={this.state.bolStartDate}
                                  showButtonBar
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2020:2040"
                                  onChange={this.handleUpdateBolStartDate}/>
                    </div>
                    <div className={'col-6'}>
                        <FieldLabel required={false} value="Bill of Lading End Date"/>
                        <Calendar showIcon={true}
                                  style={{width: '100%'}}
                                  inputStyle={{width: 'calc(100% - 28px)'}}
                                  showOnFocus={false}
                                  dateFormat="mm/dd/yy"
                                  placeholder={BROWSER_DATE_FORMAT}
                                  value={this.state.bolEndDate}
                                  showButtonBar
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2020:2040"
                                  onChange={this.handleUpdateBolEndDate}/>
                    </div>
                </div>
                <div style={{textAlign: 'right', padding: '0.75rem 0.3rem 0.75rem 0'}} className={'col-12'}>
                    {this.state.showProgressSpinner && <ProgressSpinner style={{width: '25px', height: '20px'}}
                                                                        strokeWidth="8"
                                                                        fill="#EEEEEE"
                                                                        animationDuration=".8s"/>}
                    <Button icon={'fa fa-save'}
                            className={'p-button-success'}
                            label={'SAVE'}
                            disabled={disableSaveButton || errors.length > 0}
                            onClick={this.handleSaveClick}/>
                    <Button icon={'fa fa-times'}
                            style={{marginLeft: '10px'}}
                            className={'p-button-secondary'}
                            label={'CANCEL'}
                            onClick={this.props.onHide}/>
                </div>
            </Dialog>
        );
    }
}

export default SearchOptionsDialog;

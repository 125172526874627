import React, {Component} from 'react';
import './LogDisplayer.css';

class LogDisplayer extends Component {


    renderMessageTypeCol(type) {
        return (
            <td style={{width: '30px', textAlign: 'center'}}>
                {type === 'I' ? <i style={{color: 'blue'}} className="fa fa-info-circle"/> : null}
                {type === 'E' ? <i style={{color: 'red'}} className="fa fa-exclamation-circle"/> : null}
            </td>
        );
    }

    render() {
        return (
            <div className={'LogDisplayer'}>
                <table>
                    <tbody>
                        {this.props.messages.map((m, idx) => <tr key={idx}>{this.renderMessageTypeCol(m.type)}<td>{m.msg}</td></tr>)}
                    </tbody>
                 </table>
            </div>
        );
    }
}

export default LogDisplayer;

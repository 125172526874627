import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import ServiceFactory from "../../services/ServiceFactory";
import {ReferenceDataType} from "../../services/ReferenceDataType";
import {Dropdown} from "primereact/dropdown";

export default class OwnerSelectDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipper: null,
            shippers: []
        };
        this.suggestShippers = this.suggestShippers.bind(this);
        this.handleShipperUpdate = this.handleShipperUpdate.bind(this);
        this.handleSelectCounterParty = this.handleSelectCounterParty.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    handleKeyUp(evt) {
        if (evt.keyCode === 13 && this.state.shipper)  {
            this.handleSelectCounterParty();
        }
    }

    handleSelectCounterParty() {
        const shipper = this.state.shippers.find(s => s.id === this.state.shipper);
        this.props.onSelectCounterparty(shipper);
    }


    async componentDidMount() {
        const refDataService = ServiceFactory.instance().createReferenceDataService();
        const shippers = await refDataService.readAll(ReferenceDataType.Shippers);
        this.setState({shippers: shippers});
    }

    handleShipperUpdate(e) {
        if (e.target.value) {
            this.setState({shipper: e.target.value})
        }
    }

    async suggestShippers(e) {
        const refData = ServiceFactory.instance().createReferenceDataService();
        const results =  await refData.search(ReferenceDataType.Shippers, e.query);
        this.setState({shipperSuggestions: results});
    }

    render() {
        let msg = '';
        if (this.props.selectionCount) {
            msg = `Are you sure you want to Apply Out ${this.props.selectionCount} barge`;
            if (this.props.selectionCount > 1) msg += 's';
            msg += '?';
        }

        return (
            <Dialog header={'APPLY OUT CONFIRMATION'}
                    visible={this.props.visible}
                    focusOnShow={false}
                    contentStyle={{overflow: 'visible'}}
                    style={{width: '400px'}} modal={true}
                    onHide={this.props.onHide}>
                <div onKeyUp={this.handleKeyUp}>
                    {this.props.selectionCount && <div style={{fontWeight: 'bold'}}>{msg}</div>}
                    <div className={'DialogLabel'}>Counterparty</div>
                    <div>
                        <Dropdown value={this.state.shipper}
                                  style={{width: '100%'}}
                                  options={this.state.shippers}
                                  optionValue={'id'}
                                  autoFocus={true}
                                  filter={true}
                                  optionLabel={'shipperName'}
                                  onChange={this.handleShipperUpdate}/>
                        {/*<AutoComplete value={this.state.shipper.companyName}*/}
                        {/*              style={{display: 'block', width: '100%'}}*/}
                        {/*              inputStyle={{width: '100%'}}*/}
                        {/*              minLength={2}*/}
                        {/*              //placeholder={'Start typing the name of a counterparty'}*/}
                        {/*              field={'companyName'}*/}
                        {/*              onChange={this.handleShipperUpdate}*/}
                        {/*              suggestions={this.state.shipperSuggestions}*/}
                        {/*              completeMethod={this.suggestShippers} />*/}
                    </div>
                    <div style={{textAlign: 'right', padding: '0.75rem 0 0.75rem 0'}}>
                        <Button icon='fa fa-check'
                                className={'p-button-success'}
                                disabled={!this.state.shipper}
                                label={'OK'}
                                onClick={this.handleSelectCounterParty}/>
                        <Button icon={'fa fa-times'}
                                style={{marginLeft: '10px'}}
                                className={'p-button-secondary'}
                                label={'CANCEL'}
                                onClick={this.props.onHide}/>
                    </div>
                </div>
            </Dialog>
        )
    }
}

import {combineReducers} from 'redux';
import {inboxReducer} from "./InboxReducer";
import  {userReducer} from './UserReducer';
import {documentReducer} from "./DocumentReducer";
import {messagesReducer} from "./MessagesReducer";
import {referenceDataReducer} from "./ReferenceDataReducer";

export const rootReducer = combineReducers({
    inbox: inboxReducer,
    user: userReducer,
    documents: documentReducer,
    messages: messagesReducer,
    referenceData: referenceDataReducer
});
